
import '../components/web/compnents/App.css';
import 'aos/dist/aos.css';
// import '../components/admin/components/AppAdmin.css';


import React, { Suspense, lazy } from 'react';
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";

// web
import Home from '../components/web/compnents/pages/Home';
import About from '../components/web/compnents/pages/About';
import Gallery from '../components/web/compnents/pages/Gallery';
import ContactUs from '../components/web/compnents/pages/ContactUs';
import Blog from '../components/web/compnents/pages/Blog';
import Services from '../components/web/compnents/pages/Services';
import SubServices from '../components/web/compnents/pages/SubServices';
import SingleBlog from '../components/web/compnents/pages/SingleBlog';

// admin
import Login from '../components/admin/components/Login/Login';
import Dashboard from './admin/components/Pages/Dashboard';
import Frontend from './web/compnents/Frontend';
import Banner from './admin/components/Pages/Banner';
import ServicesAdmin from './admin/components/Pages/ServicesAdmin';
import Booking from './admin/components/Pages/Booking';
import Enquiries from '../components/admin/components/Pages/Enquiries'
import Profile from './admin/components/Pages/Profile';
import Client from './admin/components/Pages/Client';
import MyAccount from './web/compnents/Header/MyAccount';
import PrivateRoute from './web/compnents/Header/PrivateRoute';
import ProjectsAdmin from './admin/components/Pages/ProjectsAdmin';
import AboutAdmin from './admin/components/Pages/AboutAdmin';
import GalleryAdmin from './admin/components/Pages/GalleryAdmin';
import BlogAdmin from './admin/components/Pages/BlogAdmin';
import AddSubService from './admin/components/Pages/AddSubService';
import UpdateBlog from './admin/components/Pages/UpdateBlog';
import AddBlog from './admin/components/Pages/AddBlog';
import SubServiceAdmin from './admin/components/Pages/SubServiceAdmin';
import UpdateSubService from './admin/components/Pages/UpdateSubService';
import AdminBookingSummey from './admin/components/Pages/AdminBookingSummary';
import SubServiceItem from './admin/components/Pages/SubServiceItem';
import Seo from './admin/components/Pages/Seo';
import UpdateServiceAdmin from './admin/components/Pages/UpdateServiceAdmin';
import UpdateSeo from './admin/components/Pages/UpdateSeo';
import Settings from './admin/components/Pages/Settings';
import SubAdmins from './admin/components/Pages/SubAdmins';
import Customers from './admin/components/Pages/Customers';
import Groups from './admin/components/Pages/Groups';
import CanceledBooking from './admin/components/Pages/CanceledBooking';
import Success from './web/compnents/common/staps/Success';
import BookingDetails from './web/compnents/Header/BookingDetails';
import MyBookings from './web/compnents/Header/MyBookings';
import UpcomingBookings from './admin/components/Pages/UpcomingBookings';
import DynamicFormFields from './admin/components/Pages/DynamicForm';
const AdminRoutes = lazy(() => import('./admin/components/AdminRoutes'));


function App() {

  let auth = localStorage.getItem("adminEmail");
  let auth1 = localStorage.getItem('auth1');
  let adminAccess = localStorage.getItem("adminType");


  return (


    <React.Fragment>
      <HashRouter>
        <Routes>
          <Route path='/' element={<Frontend />}>
            <Route index path='/' element={<Home />} />
            <Route path='/about' exact element={<About />} />
            <Route path='/gallery' exact element={<Gallery />} />
            <Route path='/contactus' exact element={<ContactUs />} />
            <Route path='/blog' exact element={<Blog />} />
            <Route path='/blog/:id/:name' exact element={<SingleBlog />} />
            <Route path='/services/:id/:name' exact element={<Services />} />
            <Route path='/services/:subname/:sid/:id' exact element={<SubServices />} />
            <Route path='/profile' exact element={localStorage.length > 0 && auth1 ? <MyAccount /> : <Home />} />
            <Route path='/my-bookings' exact element={localStorage.length > 0 && auth1 ? <MyBookings /> : <Home />} />
            <Route path='/success/:id' exact element={<Success />} />
            <Route path='/booking/:id' exact element={localStorage.length > 0 && auth1 ? <BookingDetails /> : <Home />} />
            {/* <Route path='/booking' exact element={<BookingDetails />} /> */}

          </Route>



          {auth ?
            <Route path='/admin' element={<Suspense><AdminRoutes /></Suspense>}>

              <Route path='/admin' element={<Dashboard />} />
              <Route path='/admin/banner' element={<Banner />} />
              <Route path='/admin/bookings' element={<Booking />} />
              <Route path='/admin/upcoming-bookings' element={<UpcomingBookings />} />
              <Route path='/admin/enquiries' element={<Enquiries />} />
              <Route path='/admin/service' element={<ServicesAdmin />} />
              <Route path='/admin/subservice' element={<SubServiceAdmin />} />
              <Route path='/admin/project' element={<ProjectsAdmin />} />
              <Route path='/admin/about' element={<AboutAdmin />} />
              <Route path='/admin/gallery' element={<GalleryAdmin />} />
              <Route path='/admin/blog' element={<BlogAdmin />} />
              <Route path='/admin/profile' element={<Profile />} />
              <Route path='/admin/client' element={<Client />} />
              <Route path='/admin/blog/updateblog/:id' element={<UpdateBlog />} />
              <Route path='/admin/blog/addblog' element={<AddBlog />} />
              <Route path='/admin/subservice/addsubservice' element={<AddSubService />} />
              <Route path='/admin/subservice/updatesubservice/:id' element={<UpdateSubService />} />
              <Route path="/admin/bookings/bookingsummary/:id" element={<AdminBookingSummey />} />
              <Route path="/admin/canceledbooking" element={<CanceledBooking />} />
              <Route path="/admin/subserviceitem" element={<SubServiceItem />} />
              <Route path="/admin/updateservice/:id" element={<UpdateServiceAdmin />} />
              <Route path='/admin/seo' element={<Seo />} />
              <Route path='/admin/seo/updateseo/:id' element={<UpdateSeo />} />
              <Route path='/admin/setting' element={adminAccess == 0 ? <Settings /> : <Dashboard />} />
              <Route path='/admin/subadmin' element={<SubAdmins />} />
              <Route path='/admin/groups' element={<Groups />} />
              <Route path='/admin/customers' element={<Customers />} />
              <Route path='/admin/dynamicform' element={<DynamicFormFields />} />

            </Route>
            :

            <Route path='/admin' element={<Login />} />

          }
        </Routes>
      </HashRouter>

    </React.Fragment>
  );
}

export default App;

