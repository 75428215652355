import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  // <React.StrictMode>
    // <BrowserRouter basename="/index.html">
      <ProSidebarProvider>
        <App />
      </ProSidebarProvider>
    // </BrowserRouter>
  // </React.StrictMode>
);
