import React, { useState, useEffect } from 'react';
import 'react-step-progress/dist/index.css';
import Steppers from '../common/Stepper';


const ResidencialProcess = ({ ids, extra, initialPrice, initialHourPrice, minPrice, minHour, addHourPrice, type, advanceType, advanceAmount, regularClean, propertyType, gst, specialService }) => {

        return (
                <>
                        <div className=''>
                                <div className='container our_profationals'>

                                        <Steppers ids={ids} extra={extra} initialPrice={initialPrice} minPrice={minPrice}
                                                minHour={minHour} addHourPrice={addHourPrice} type={type} initialHourPrice={initialHourPrice}
                                                advanceType={advanceType} advanceAmount={advanceAmount} isRegularClean={regularClean} isPropertyType={propertyType}
                                                gstPer={gst} specialService={specialService}
                                        />
                                </div>
                        </div>
                </>
        )
}

export default ResidencialProcess