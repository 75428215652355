import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';
import Paypal from '../common/Paypal/Paypal';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from 'react-router-dom'

const BookingDetails = () => {

    const { id } = useParams();

    const [bookingData, setBookingData] = useState([]);
    const [myspinner, setMyspinner] = useState(false);
    const [paypalAuth, setPaypalAuth] = useState('');
    const [transactionId, setTransactionId] = useState('');




    const search = useLocation().search
    const searchParams = new URLSearchParams(search)




    const getUser = () => {
        axios.post('/getSuccessData', { booking_id: id }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            setBookingData(response.data.data);

            setMyspinner(false);

        }).catch(err => {
            console.log(err)
        });

        axios.get("/allSetting").then((response) => {
            let paypal_client_id = response.data.data[0].paypal_client_id
            setPaypalAuth(paypal_client_id);

        }).catch((err) => {
            console.log(err);
        })

    }

    // console.log(remaining)

    const onPay = (paidAmount, tid) => {
        setMyspinner(true);
        axios.post('/remainingPayment', {

            booking_id: id,
            transaction_id2: tid,
            paid_amount: paidAmount,

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                // setActiveStep(activeStep + 1);
                setMyspinner(false);
                // executeScroll();
                // navigate(`/success/${response.data.booking_id}`);
                // setTimeout(() => navigate('/'), 3000);
            }
        }).catch((err) => {
            console.log(err);
        })

    }



    useEffect(() => {
        getUser();
        window.scrollTo(0, 0);
        setMyspinner(true);
    }, [id]);

    return (
        <>
            <div className={myspinner ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section className="fix_width mt-5 mb-5 container-fluid" style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>

                <div role="presentation" className='pb-4'>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to='/my-bookings'>
                            My Booking
                        </Link>
                        <span>Booking Details</span>

                        {searchParams.get('tutorial')}
                    </Breadcrumbs>
                </div>

                <h3 className='pb-4'>Booking Details</h3>
                <div className="_success_d m-auto">
                    {bookingData?.map((Booking) => {
                        return (
                            <>
                                <section className="card">
                                    <div className='card-body'>
                                        <h5>Personal Details</h5>
                                        <Table borderless >

                                            <tbody>

                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Name</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Email</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.email}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Phone</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.phone}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Address</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.address}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Postcode</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.postcode}
                                                    </td>
                                                </tr>
                                                {/* postcode */}
                                            </tbody>

                                        </Table>
                                    </div>
                                </section>
                                <section className="card">
                                    <div className='card-body'>
                                        <h5>Booking Details</h5>
                                        <Table borderless >
                                            {/* {bookingData?.map((Booking) => {
                                                        return ( */}
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Booking Id</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.booking_id}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Booking Status</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.booking_status == 0 ? "Pending" : Booking.booking_status == 1 ? "Confirm" : Booking.booking_status == 2 ? "Complete" : "Canceled"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {/* )
                                                    })} */}
                                        </Table>
                                    </div>
                                </section>
                                <section className="card">
                                    <div className='card-body'>
                                        <h5>Service Details</h5>
                                        <Table borderless >
                                            {/* {bookingData?.map((Booking) => {
                                                        return ( */}
                                            <tbody>
                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Service Name</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.sub_service_id}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Service Type</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.sub_service_type}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Services</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.sub_service_list_id}
                                                    </td>
                                                </tr>

                                                {Booking.all_rooms_opt ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Rooms Options</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.all_rooms_opt}
                                                        </td>
                                                    </tr> : null}

                                                {Booking.kitchen_opt ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Kitchen Options</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.kitchen_opt}
                                                        </td>
                                                    </tr> : null}

                                                {Booking.living_opt ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Living Options</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.living_opt}
                                                        </td>
                                                    </tr> : null}

                                                {Booking.bathroom_opt ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Laundry Options</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.bathroom_opt}
                                                        </td>
                                                    </tr> : null}

                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Service Date</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.start_date}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Time</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.time}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Bedroom</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.total_bedroom}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Bathroom</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.total_bathroom}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Hour</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.hour}
                                                    </td>
                                                </tr>
                                                {Booking.clean_schedule == 0 ? null : <>
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Regular Cleaning</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.clean_schedule == 1 ? "Weekly or Fortnightly" : Booking.clean_schedule == 2 ? "Aged Care / Disability" : Booking.clean_schedule == 3 ? "Move Out / Spring Clea" : "No"}
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Property Type</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.prop_type == 1 ? "Home" : Booking.prop_type == 2 ? "apartment" : "-"}
                                                        </td>
                                                    </tr> */}
                                                </>}
                                                {Booking.prop_type ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Property Type</span>
                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.prop_type == 1 ? "Home" : Booking.prop_type == 2 ? "apartment" : "-"}
                                                        </td>
                                                    </tr> : null}
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Service Days</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.service_days}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Flexible With Date</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.date_confirm}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Special Instruction</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.special_instruction}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Requirement</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.requirement}
                                                    </td>
                                                </tr>
                                                {Booking.sub_service_type == "Hourly" ?
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <span>Bedroom Information</span>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.total_bedroom}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <span>Bathroom Information</span>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.total_bathroom}
                                                            </td>
                                                        </tr>
                                                    </>
                                                    : null}

                                            </tbody>
                                            {/* )
                                                    })} */}
                                        </Table>
                                    </div>
                                </section>
                                <section className="card">
                                    <div className='card-body'>
                                        <h5>Payment Details</h5>
                                        <Table borderless >
                                            {/* {bookingData?.map((Booking) => {
                                                        return ( */}
                                            <tbody>

                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Sub Total Price</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        ${Booking.total_price - Booking.gst_amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>GST {Booking.gst}%</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        ${Booking.gst_amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Total Price</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        ${Booking.total_price}
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Paid Amount</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        ${Booking.paid_amount}
                                                    </td>
                                                </tr>
                                                {Booking.total_price === Booking.paid_amount ? null :
                                                    <tr>
                                                        <td className='w-50'>
                                                            <div className=''>
                                                                <span>Remaining Amount</span>
                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            ${Booking.total_price - Booking.paid_amount}
                                                            <Paypal amount={Booking.total_price - Booking.paid_amount} paypalAuth={paypalAuth} setTransactionId={setTransactionId} onSubmit={onPay} />
                                                        </td>
                                                    </tr>
                                                } */}

                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Extra Amount</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        ${Booking.extra_amount == "" ? 0 : Booking.extra_amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='w-50'>
                                                        <div className=''>
                                                            <span>Final Price</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        ${Booking.final_price - Booking.paid_amount}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Payment Type</span>

                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.payment_type == 0 ? "Paypal" : "Pay on arrival/Cash"}
                                                    </td>
                                                </tr>
                                                {Booking.payment_type == 0 ?
                                                    <tr>
                                                        <td>
                                                            <div className=''>
                                                                <span>Transaction Id</span>

                                                            </div>
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            {Booking.transaction_id}
                                                        </td>
                                                    </tr> : null}
                                                <tr>
                                                    <td>
                                                        <div className=''>
                                                            <span>Payment Status</span>
                                                        </div>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        {Booking.payment_status == 0 ? "Pending" : "Complete"}
                                                    </td>
                                                </tr>

                                            </tbody>
                                            {/* )
                                                    })} */}
                                        </Table>
                                    </div>
                                </section>
                            </>
                        )
                    })}

                </div>
            </section>


        </>
    )
}

export default BookingDetails