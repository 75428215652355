
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DataTables from "../DataTable/DataTables";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";


const Seo = () => {

  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);

  const getData = () => {

    axios.get('/allSeo').then((response) => {
      setData(response.data.data);
      setPending(false);
      console.log(response.data.data)
    })
  }

  useEffect(() => {
    getData();
  }, [])


  const tableData = {
    columns: [
      {
        name: "#",
        // width: '50px',
        cell: (row, index) => index + 1,
      },
      {
        name: "Page",
        selector: "page"
        // width: '200px',

      },
      {
        name: "Page",
        selector: "seo_title"
        // width: '200px',

      },
      {
        name: "Action",
        cell: (row) => (
          <>
            {
              localStorage.getItem("editAuth") == 0 ?
                <Link to={`/admin/seo/updateseo/${row.id}`}>
                  <Button className="_edit_icons">
                    <FaEdit />
                  </Button>
                </Link> : null
            }
          </>
        ),
        right: true,
      }
    ],
  };


  return (
    <>
      <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
        {/* <ToastContainer /> */}
        <section class="wrapper">
          <div class="row">
            <div class="col-lg-12">
              <span className="p-3">Seo</span>
              <section className="card m-3">
                <div className="addcareer p-2">

                </div>

                <div className="main">
                  <DataTables data={data} columns={tableData.columns} title="Seo" />
                </div>
              </section>
            </div>
          </div>
        </section>
      </section>

    </>
  )
}

export default Seo