import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { HiRefresh } from 'react-icons/hi';
import { FaEye, FaShare } from 'react-icons/fa';
import axios from 'axios';
import { Button, Dropdown } from 'react-bootstrap';
import DataTables from '../DataTable/DataTables';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from "react-toastify";

const Booking = () => {


    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [pending, setPending] = useState(true);
    // booking
    const [showBooking, setShowBooking] = useState(false);
    const [bookingId, setBookingId] = useState('');
    const [bookingStatus, setBookingStatus] = useState('');
    // cancel
    const [show, setShow] = useState(false);
    const [cancelId, setCancelId] = useState('');


    const getData = () => {
        axios.get('/getBookingAddress').then((response) => {
            setData(response.data.data);
            setNewData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, [])


    useEffect(() => {
        setData(newData?.filter(data => data.booking_status == 0));

    }, [newData]);

    const handleBookingClose = () => {
        setShowBooking(false);
        setBookingId('');
        setBookingStatus('');
    }

    const handleShowBooking = (e) => {

        let id = e.target.id;
        let status = e.target.value;
        if (status == 3) {
            setShow(true);
            setCancelId(id);

        } else {
            setShowBooking(true);
            setBookingId(id);
            setBookingStatus(status);
        }

    }

    const handleClose = () => {
        setShow(false);
    };


    const statusSuccess = () =>
        toast.info("Status Update Successfully", { position: "top-center" });

    // bookingStatus
    const handleStatus = () => {
        let id = bookingId;
        let status = bookingStatus;
        axios.post('/bookingStatus', {
            id: id,
            booking_status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                statusSuccess();
                handleBookingClose();
                window.location.reload(false);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const cancelSuccess = () =>
        toast.info("Booking Canceled Successfully", { position: "top-center" });

    // booking cancel 
    const handleCancel = () => {
        axios.post('/bookingCancel', {
            id: cancelId,
            cancel_by: localStorage.getItem("adminType") == 0 ? 1 : 2
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                handleClose();
                cancelSuccess();
                setTimeout(function () {
                    window.location.reload(1);
                }, 1000);
            }

        }).catch(err => {
            console.log(err)
        })
    }

    // const handleCancelByUser = () => {
    //     axios.post('/bookingReqResponse', {
    //         id: id,
    //         cancel_status : status,
    //         cancel_by : 3
    //     }, {
    //         method: 'POST',
    //         headers: {
    //             "Content-Type": "multipart/form-data"
    //         }
    //     }).then((response) => {
    //         if (response.status === 200) {
    //             handleClose();
    //             handleCancelClose();
    //             setTimeout(function () {
    //                 window.location.reload(1);
    //             }, 1000);
    //         }

    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }




    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.booking_status == status));
        }

    }

    const tableData = {
        columns: [
            {
                name: '#',
                width: '80px',
                cell: (row, index) => index + 1
            },
            // {
            //     name: <></>,
            //     width: '100px',
            //     selector: row => <>
            //         {row.cancel_btn === "show" ?
            //             <Button className='btn-danger' onClick={() => handleShow(row.id)}>Cancel</Button>
            //             : null}
            //     </>
            // },

            {
                name: "Action",
                cell: row => <Link to={`/admin/bookings/bookingsummary/${row.book_id}`}><Button className='_view_icons' ><FaEye /></Button></Link>,
                right: true
            },


            {
                name: "Booking Id",
                selector: "booking_id",
                // width: '200px',
                sortable: true
            },
            {
                name: "Service Date",
                selector: "start_date",
                // width: '200px',
                sortable: true
            },
            // {
            //     name: "Email",
            //     selector: "email",
            //     // width: '200px',
            //     sortable: true,
            //     // cell: d => <span>{d.genres.join(", ")}</span>
            // },
            {
                name: "Address",
                selector: "address",
                // width: '200px',
                sortable: true
            },
            // {
            //     name: "Phone",
            //     selector: "phone",
            //     // width: '100px',
            //     sortable: true
            // },
            // {
            //     name: "Requirement",
            //     selector: "requirement",
            //     // width: '100px',
            //     sortable: true
            // },

            // {
            //     name: "Payment Type",
            //     selector: "payment_type",
            //     sortable: true
            // },

            {
                name: "Booking Status",
                width: '250px',
                selector: row => <select className=" col-md-4 select_status" style={row.booking_status == 2 ? { backgroundColor: 'green' } : row.booking_status == 3 ? { backgroundColor: 'gray' } : null} disabled={row.booking_status == 2 || row.booking_status == 3 || row.cancel_status == 2 || localStorage.getItem("editAuth") == 1 ? true : false}
                    value={row.booking_status}
                    id={row.book_id}
                    onChange={(e) => handleShowBooking(e)}
                >
                    <option value="0" disabled={row.booking_status >= 0 ? true : false}>Pending</option>
                    <option value="1" disabled={row.booking_status >= 1 ? true : false}>Confirm</option>
                    <option value="2" disabled={row.booking_status == 2 ? true : false}>Completed</option>
                    <option value="3" disabled={row.booking_status == 3 ? true : false}>Cancelled</option>
                    {/* <option value="4" className={row.cancel_status == 2 ? 'd-block' : 'd-none'} >Canceled</option> */}
                </select>,
                center: true,

            },
            // {
            //     name: "Cancel Status",
            //     width: '250px',
            //     selector: row => <select className=" col-md-4 select_status" disabled={row.cancel_status == 4 ? true : false}
            //         value={row.cancel_status}
            //         id={row.book_id}
            //         onChange={(e) => handleCancelShow(e)}
            //     >
            //         <option value="1" disabled>Pending</option>
            //         <option value="2">Accept</option>
            //         <option value="3">Reject</option>
            //         <option value="4">Cancelled</option>

            //     </select>,
            //     center: true,
            // },


        ],

    };





    return (
        <>

            <Helmet>

                <title>Blue Cleaning Group | Bookings</title>

            </Helmet>

            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Booking</span>
                            <section className="card m-3">

                                <div className="selectoption mt-4">
                                    <strong >Status :-</strong>
                                    <select className="status filter"

                                        onChange={(e) => filterByStatus(e.target.value)}
                                    >
                                        <option value="">All</option>
                                        <option value="0" selected>Pending</option>
                                        <option value="1">Confirmed</option>
                                        <option value="2">Completed</option>
                                        <option value="3" >Cancelled</option>
                                    </select>
                                </div>
                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} title="Booking" />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>

            <Modal show={showBooking} onHide={handleBookingClose}>
                <Modal.Header closeButton className='adminpass'>
                    <Modal.Title>Booking Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                        <h3>Are you sure to {bookingStatus == 2 ? "Complete" : "confirm"} this Booking?</h3>
                    </div>

                    <div className="_model_btn mt-2">
                        <Button
                            variant="secondary"
                            onClick={handleBookingClose}
                            className="_close_btn"
                        >
                            No
                        </Button>
                        <Button variant='danger' onClick={() => handleStatus()}>Yes</Button>
                    </div>
                </Modal.Body>

            </Modal>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className='adminpass'>
                    <Modal.Title>Booking Cancel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                        <h3>Are you sure Do You want to Cancel this Booking?</h3>
                    </div>

                    <div className="_model_btn mt-2">
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            className="_close_btn"
                        >
                            No
                        </Button>
                        <Button variant='danger' onClick={() => handleCancel()}>Yes</Button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Booking