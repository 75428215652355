import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import "react-data-table-component-extensions/dist/index.css";
import Modal from "react-bootstrap/Modal";
import { HiRefresh } from "react-icons/hi";
import { FaShare, FaEdit, FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import { Button, Dropdown } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import DataTables from "../DataTable/DataTables";
import { Helmet } from "react-helmet";

const Client = () => {
    const [data, setData] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [file, setFile] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [star, setStar] = useState("");
    const [pending, setPending] = useState(true);

    const [id, setId] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientMessage, setClientMessage] = useState("");
    const [starReview, setStarReview] = useState("");
    const [image, setImage] = useState("");
    const [updateImage, setUpdateImage] = useState("");
    const [showUpdate, setShowUpdate] = useState(false);
    const handleUpdateShow = () => setShowUpdate(true);

    const addSuccess = () =>
        toast.info("Client Added Successfully", { position: "top-center" });
    const deleteSuccess = () =>
        toast.info("Client Deleted Successfully", { position: "top-center" });

    const getData = () => {
        axios.get("/allclient").then((response) => {
            setData(response.data.data);
            setPending(false);
            console.log(response.data.data);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    const handleClose = () => {
        setShow(false);
        setFile("");
        reset({
            name: "",
            image: "",
            message: "",
            star: "",
        });
    };

    const handleCloseUpdate = (id) => {
        setShowUpdate(false);
        reset1({
            name: "",
            image: "",
            message: "",
            star: "",
        });
    };

    const statusSuccess = () =>
        toast.info("Status Update Successfully", { position: "top-center" });

    const handleStatus = (e) => {
        let id = e.target.id;
        let status = e.target.value;
        axios.post('/clientStatus', {
            id: id,
            status: status
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            if (response.status === 200) {
                statusSuccess();
                window.location.reload(false);
            }

        }).catch((err) => {
            console.log(err);
        })
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        const formData = new FormData();
        formData.append("image", file);

        axios
            .post(
                "/addclient",
                {
                    name: name,
                    message: message,
                    stars: star,
                    image: file,
                },
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    addSuccess();
                    handleClose();
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    //   update

    const handleClient = (id) => {
        setShowUpdate(true);
        setId(id);
        axios.get(`/getSingleClient/${id}`).then((response) => {
            setClientData(response.data.data);
            console.log(response.data.data)
        }).catch((err) => {
            console.log(err);
        })
    };


    useEffect(() => {
        clientData?.map((data) => {
            setClientName(data.name);
            setClientMessage(data.message);
            setImage(data.image);
            setStarReview(data.stars);
        })
    }, [clientData]);

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        reset: reset1,
        formState: { errors: errors1 },
    } = useForm();

    const onUpdate = () => {
        const formData = new FormData();
        formData.append("image", file);

        axios
            .post(
                "/updateclient",
                {
                    id: id,
                    name: clientName,
                    message: clientMessage,
                    stars: starReview,
                    image: updateImage,
                },
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    addSuccess();
                    handleClose();
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    //   delete

    const deleteClient = (id) => {
        axios
            .post(
                "/deleteclient",
                {
                    id: id,
                },
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    deleteSuccess();
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };




    const tableData = {
        columns: [
            {
                name: "#",
                // width: '50px',
                cell: (row, index) => index + 1,
            },
            {
                name: "Name",
                selector: "name",
                // width: '200px',
                sortable: true,
            },
            {
                name: "Image",
                selector: (row) => (
                    <img
                        style={{ width: "50px", height: "50px", padding: "10px" }}
                        src={`assets/happyclient/${row.image}`}
                    />
                ),
                // width: '200px',
                sortable: true,
            },
            {
                name: "Stars",
                selector: "stars",
                // width: '200px',
                sortable: true,
                // cell: d => <span>{d.genres.join(", ")}</span>
            },
            {
                name: "Status",
                cell: row => <select className="select_status" disabled={localStorage.getItem("editAuth") == 0 ? false : true}
                    value={row.status} id={row.id}
                    onChange={(e) => handleStatus(e)}
                >
                    <option value="0">Active</option>
                    <option value="1">In-Active</option>
                </select>
                // selector: (row) => ,
            },
            {
                name: "Action",
                cell: (row) => (
                    <div className="d-flex" style={{ gap: "10px" }}>
                        {localStorage.getItem("editAuth") == 0 ?
                            <Button
                                className="_edit_icons"
                                onClick={() => handleClient(row.id)}
                            >
                                <FaEdit />
                            </Button>
                            : null}

                        {localStorage.getItem("deleteAuth") == 0 ?
                            <Button
                                className="_delete_btn_"
                                onClick={() => deleteClient(row.id)}
                            >
                                <FaTrashAlt />
                            </Button>
                            : null}
                    </div>
                ),
                right: true,
            },
        ],
    };

    return (
        <>
            <Helmet>
                <title>Blue Cleaning Group | Clients</title>
            </Helmet>

            <Spinner
                animation="border"
                className={pending ? "mySpinner" : "d-none"}
            />
            <section
                id="main-content"
                style={pending ? { opacity: "0.5" } : { opacity: "1" }}
            >
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className="p-3">Clients</span>
                            <section className="card m-3">
                                <div className="d-flex justify-content-end align-items-center">
                                    {localStorage.getItem("addAuth") == 0 ?
                                        <Button className="Add_btn_ m-3" onClick={() => handleShow()}>
                                            Add Client
                                        </Button> : null}
                                </div>

                                <div className="main">
                                    <DataTables
                                        data={data}
                                        columns={tableData.columns}
                                        title="Clients"
                                    />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className="adminpass">
                    <Modal.Title>Add Clients</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3">
                            <Form.Label>Client Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                {...register("name", {
                                    required: true,
                                    onChange: (e) => setName(e.target.value),
                                })}
                            />
                            {errors.name && (
                                <div className="errMsg">Please Enter Client Name</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Client Message</Form.Label>
                            <Form.Control
                                as="textarea" rows={5}
                                value={message}
                                {...register("message", {
                                    required: true,
                                    onChange: (e) => setMessage(e.target.value),
                                })}
                            />
                            {errors.message && (
                                <div className="errMsg">Please Enter Client Message</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Rating Star</Form.Label>
                            <Form.Select
                                aria-label="Star"
                                {...register("star", {
                                    required: true,
                                    onChange: (e) => setStar(e.target.value),
                                })}
                            >
                                <option value="">Select Star Rating</option>
                                <option value="1">One Star</option>
                                <option value="2">Two Star </option>
                                <option value="3">Three Star </option>
                                <option value="4">Four Star </option>
                                <option value="5">Five Star </option>
                            </Form.Select>
                            {errors.star && (
                                <div className="errMsg">Please Select Star Review</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Client Image</Form.Label>
                            <Form.Control
                                type="file"
                                {...register("image", {
                                    required: true,
                                    onChange: (e) => setFile(e.target.files[0]),
                                })}
                            />
                            {errors.image && (
                                <div className="errMsg">Please Select Client Image</div>
                            )}
                            <div className="text-center p-3">
                                <img
                                    src={file ? URL.createObjectURL(file) : null}
                                    style={{ maxWidth: "300px", maxHeight: "300px" }}
                                />
                            </div>
                        </Form.Group>

                        <div className="_add_float mt-2">
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                className="_close_btn"
                            >
                                Close
                            </Button>
                            <Button className="Add_btn_" type="submit">
                                Add Client
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showUpdate} onHide={handleCloseUpdate}>
                <Modal.Header closeButton className="adminpass">
                    <Modal.Title>Update Clients</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit1(onUpdate)}>
                        <Form.Group className="mb-3">
                            <Form.Label>Client Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={clientName}
                                {...register1("name", {
                                    required: false,
                                    onChange: (e) => setClientName(e.target.value),
                                })}
                            />
                            {errors1.name && (
                                <div className="errMsg">Please Enter Client Name</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Client Message</Form.Label>
                            <Form.Control
                                as="textarea" rows={5}
                                value={clientMessage}
                                {...register1("message", {
                                    required: false,
                                    onChange: (e) => setClientMessage(e.target.value),
                                })}
                            />
                            {errors1.message && (
                                <div className="errMsg">Please Enter Client Message</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Rating Star</Form.Label>
                            <Form.Select value={starReview}
                                aria-label="Star"
                                {...register1("star", {
                                    required: false,
                                    onChange: (e) => setStarReview(e.target.value),
                                })}
                            >
                                <option value="">Select Star Rating</option>
                                <option value="1">One Star</option>
                                <option value="2">Two Star </option>
                                <option value="3">Three Star </option>
                                <option value="4">Four Star </option>
                                <option value="5">Five Star </option>
                            </Form.Select>
                            {errors1.star && (
                                <div className="errMsg">Please Select Star Review</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Client Image</Form.Label>
                            <Form.Control
                                type="file"
                                {...register1("image", {
                                    required: false,
                                    onChange: (e) => setUpdateImage(e.target.files[0]),
                                })}
                            />
                            {errors1.image && (
                                <div className="errMsg">Please Select Client Image</div>
                            )}
                            <div className="text-center p-3">
                                {updateImage == "" ?
                                    <img src={`assets/happyclient/${image}`} />
                                    :
                                    <img
                                        src={URL.createObjectURL(updateImage)}
                                        style={{ maxWidth: "300px", maxHeight: "300px" }}
                                    />
                                }

                            </div>
                        </Form.Group>

                        <div className="_add_float mt-2">
                            <Button
                                variant="secondary"
                                onClick={handleCloseUpdate}
                                className="_close_btn"
                            >
                                Close
                            </Button>
                            <Button className="Add_btn_" type="submit">Update</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Client;

