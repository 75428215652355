import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Button, Dropdown } from 'react-bootstrap';
import DataTables from '../DataTable/DataTables';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';

const CanceledBooking = () => {

    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [pending, setPending] = useState(true);
    const [show, setShow] = useState(false);
    const [id, setId] = useState('');
    const [cancelBy, setCancelBy] = useState('');
    const [cancelStatus, setCancelStatus] = useState('');

    const getData = () => {
        axios.get('/bookingCancelList').then((response) => {
            setData(response.data.data);
            setNewData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);



    useEffect(() => {
        setData(newData?.filter(data => data.cancel_status == 1));

    }, [newData]);

    
    const handleClose = () => {
        setShow(false);
        setId('');
        
        setCancelStatus('');


    };
    const handleShow = (e) => {
        console.log(e.target)
        setId(e.target.id);
      
        setCancelStatus(e.target.value);
        setShow(true);
    }

    const handleCancel = () => {
        axios.post('/bookingReqResponse', {
            id: id,
            cancel_status: cancelStatus,
            cancel_by: localStorage.getItem("adminType")
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                handleClose();
                setTimeout(function () {
                    window.location.reload(1);
                }, 1000);
            }

        }).catch(err => {
            console.log(err)
        })
    }
    



    const tableData = {
        columns: [
            {
                name: '#',
                width: '80px',
                cell: (row, index) => index + 1
            },
            // {
            //     name: <></>,
            //     width: '100px',
            //     selector: row => <>
            //         {row.cancel_btn === "show" ?
            //             <Button className='btn-danger' onClick={() => handleShow(row.id)}>Cancel</Button>
            //             : null}
            //     </>
            // },
            {
                name: "Booking Id",
                selector: "booking_id",
                // width: '200px',
                sortable: true
            },
            {
                name: "Cancelled By",
                selector: row => <>
                    {row.cancel_status == 1 ? '-' : row.cancel_by == 3 ? "User" : row.cancel_by == 1 ? "Admin" : "Sub-Admin"}
                </>,
                // width: '200px',
                sortable: true
            },
            {
                name: "Email",
                selector: "email",
                // width: '200px',
                sortable: true,
                // cell: d => <span>{d.genres.join(", ")}</span>
            },
          
            {
                name: "Cancel Status",
                width: '250px',
                selector: row => <>
                    <div className='d-flex'>
                        {row.cancel_status == 1 ?
                            <>
                                <Button className='Add_btn_' id={row.id} value={2}
                                  disabled={localStorage.getItem("editAuth") == 0 ? false : true}  onClick={(e) => handleShow(e)}
                                >
                                    Accept
                                </Button>
                                <Button className="_delete_btn_" id={row.id} value={3}
                                  disabled={localStorage.getItem("editAuth") == 0 ? false : true}  onClick={(e) => handleShow(e)}
                                >
                                    Reject
                                </Button>
                            </>
                            :
                            row.cancel_status == 2 ?
                                <Button className='Add_btn_' disabled>
                                    Accepted
                                </Button>

                                :
                                <Button className="_delete_btn_" disabled>
                                    Rejected
                                </Button>
                        }
                    </div>
                </>,
                center: true,
            },

            // {
            //     name: "Action",
            //     cell: row => <Link to={`/admin/bookings/bookingsummary/${row.book_id}`}><Button className='Add_botton' ><FaEye /></Button></Link>,
            //     right: true

            // },

        ],

    };

    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.cancel_status == status));
        }

    }

    return (
        <>
            <Helmet>

                <title>Blue Cleaning Group | Bookings</title>

            </Helmet>

            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Cancelled Request</span>
                            <section className="card m-3">

                                <div className="selectoption mt-4">
                                    <strong >Status :-</strong>
                                    <select className="status filter"

                                        onChange={(e) => filterByStatus(e.target.value)}
                                    >
                                        <option value="">All</option>
                                        <option value="1" selected>Pending</option>
                                        <option value="2">Accepted</option>
                                        <option value="3">Rejected</option>
                                    </select>
                                </div>
                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} title="Cancelled Request" />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className='adminpass'>
                    <Modal.Title>Cancel Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                        <h3>Are you sure to Do you want to {cancelStatus == 2 ? "Accept" : "Reject"} Cancel Request?</h3>
                    </div>

                    <div className="_model_btn mt-2">
                        <Button
                            variant='danger'
                            onClick={handleClose}
                        >
                            No
                        </Button>
                        <Button class="Add_btn_" onClick={() => handleCancel()}>Yes</Button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default CanceledBooking