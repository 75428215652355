import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import TextEditor from '../Editor/TextEditor';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

const UpdateBlog = () => {

    const navigate = useNavigate();

    const { id } = useParams();
    const [blogData, setBlogData] = useState([]);
    const [updatedContent, setUpdatedContent] = useState('');
    const [actualData, setActualData] = useState('');
    const [pending, setPending] = useState(true);
    const [image, setImage] = useState('');
    const [title, setTitle] = useState('');
    const [file, setFile] = useState('');
    const [canonical, setCanonical] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoFollow, setSeoFollow] = useState('');


    console.log(id);

    const getData = () => {
        axios.get(`/getSingleBlog/${id}`).then((response) => {
            setBlogData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }


    useEffect(() => {
        getData();
    }, []);

    const notify = () => toast.info("Blog Edit SuccessFully", { position: "top-center" });

    useEffect(() => {
        blogData?.map((blog) => {
            setTitle(blog.title);
            setImage(blog.image);
            setActualData(blog.content);
            setSeoTitle(blog.seo_title);
            setCanonical(blog.canonical);
            setSeoDescription(blog.seo_description);
            setSeoFollow(blog.seo_follow);
            setSeoKeyword(blog.seo_keywords);
            setSeoScript(blog.seo_script);
        })

    }, [blogData]);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = () => {

        const formData = new FormData();
        formData.append("file", file);

        axios.post(`/updateBlog/${id}`, {
            title: title,
            content: updatedContent,
            image: file,
            seo_title: seoTitle,
            seo_description: seoDescription,
            seo_keywords: seoKeyword,
            seo_follow: seoFollow,
            canonical: canonical,
            seo_script: seoScript

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                navigate('/admin/blog');
            }

        }).catch((err) => {

            console.log(err);
        })

    }

console.log(window.location);

    return (
        <>

<div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className='p-3'>Update Blog</span>
                            <div className='p-3'>

                                <Form className='row' onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="col-lg-7 col-md-7 col-sm-12  mb-3">
                                        <Form.Label>Blog Title</Form.Label>
                                        <Form.Control type="text" placeholder="title" value={title}
                                            {...register('title', {
                                                required: false,
                                                onChange: (e) => setTitle(e.target.value),
                                            })}
                                        />
                                        {errors.title && errors.title.type === "required" && <span className='err_msg'>Please enter title</span>}
                                    </Form.Group>

                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <Form.Label>Blog Image</Form.Label>
                                        <Form.Control type="file"
                                            {...register('image', {
                                                required: false,
                                                onChange: (e) => setFile(e.target.files[0]),
                                            })}

                                        />
                                        {errors.image && <span className='err_msg'>Please select image</span>}
                                    </Form.Group>
                                    <div className='col-lg-6 text-center'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div></div>
                                        {file == "" ?
                                            <img src={`/assets/blog/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                            :
                                            <img src={URL.createObjectURL(file)} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                                        }

                                    </div>
                                    <div className='mt-5'>
                                        <Form.Label>Blog Content</Form.Label>
                                        <TextEditor data={actualData} setUpdatedContent={setUpdatedContent} />
                                    </div>
                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Canonical</Form.Label>
                                        <Form.Control type="text"

                                            value={canonical}
                                            {...register('seoCanonical', {
                                                required: false,
                                                onChange: (e) => setCanonical(e.target.value),
                                            })}

                                        />

                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5" >
                                        <Form.Label>SEO Title</Form.Label>
                                        <Form.Control type="text"
                                            value={seoTitle}
                                            {...register('seoTitle', {
                                                required: false,
                                                onChange: (e) => setSeoTitle(e.target.value),
                                            })}

                                        />
                                        {errors.seoTitle && <span className='err_msg'>Please enter Seo Title </span>}
                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5" >
                                        <Form.Label>SEO Keywords</Form.Label>
                                        <Form.Control type="text"
                                            value={seoKeyword}
                                            {...register('seoSeoKeyword', {
                                                required: false,
                                                onChange: (e) => setSeoKeyword(e.target.value),
                                            })}
                                        // onChange={(e) => setSeoKeyword(e.target.value)}

                                        />
                                        {errors.seoSeoKeyword && <span className='err_msg'>Please enter Seo Keyword </span>}
                                    </Form.Group>
                                    <Form.Group className=" col-md-6 pb-5">
                                        <Form.Label>SEO Follow</Form.Label>
                                        <Form.Select value={seoFollow}
                                            onChange={(e) => setSeoFollow(e.target.value)}>
                                            <option value="1">Index Follow</option>
                                            <option value="2">Index No-follow</option>
                                            <option value="3">No-index Follow</option>
                                            <option value="4">No-index No-follow</option>
                                        </Form.Select>

                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>SEO Script</Form.Label>
                                        <Form.Control type="text"
                                            value={seoScript}
                                            {...register('seoScript', {
                                                required: false,
                                                onChange: (e) => setSeoScript(e.target.value),
                                            })}

                                        />
                                        {errors.seoScript && <span className='err_msg'>Please enter Seo Script </span>}
                                    </Form.Group>

                                    <Form.Group className="col-md-12 pb-5">
                                        <Form.Label>SEO Description</Form.Label>
                                        <Form.Control as="textarea" rows={3}
                                            value={seoDescription}
                                            {...register('seoDes', {
                                                required: false,
                                                onChange: (e) => setSeoDescription(e.target.value),
                                            })}
                                        />
                                        {errors.seoDes && <span className='err_msg'>Please enter Seo Description </span>}
                                    </Form.Group>
                                    <div>
                                        <Button className='Add_btn_ m-3' type='submit'>
                                            Update
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default UpdateBlog;