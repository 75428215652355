import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import "react-data-table-component-extensions/dist/index.css";
import Modal from "react-bootstrap/Modal";
import { HiRefresh } from "react-icons/hi";
import { FaShare, FaEdit, FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button, Dropdown } from "react-bootstrap";
import DataTables from "../DataTable/DataTables";
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";

const ProjectsAdmin = () => {
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);

    const handleShow = () => setShow(true);
    const handleShowUpdate = () => setShowUpdate(true);
    const [pending, setPending] = useState(true);
    const [services, setServices] = useState([]);
    // const [selectedService, setSelectedService] = useState([]);

    // add
    // const [subServicess, setSubServicess] = useState([]);
    const [serviceId, setServiceId] = useState('');
    // const [subService, setSubService] = useState('');
    const [before, setBefore] = useState('');
    const [after, setAfter] = useState('');
    // update
    // const [subServices, setSubServices] = useState([]);
    const [id, setId] = useState('');
    const [updateServiceId, setUpdateServiceId] = useState('');
    // const [subServiceId, setSubServiceId] = useState('');
    const [beforeImage, setBeforeImage] = useState('');
    const [afterImage, setAfterImage] = useState('');
    const [updateBefore, setUpdateBefore] = useState('');
    const [updateAfter, setUpdateAfter] = useState('');



    const addSuccess = () =>
        toast.info("Recent work Added Successfully", { position: "top-center" });
    const updateSuccess = () =>
        toast.info("Recent work updated Successfully", { position: "top-center" });
    const deleteMsg = () =>
        toast.info("Recent work Deleted Successfully", { position: "top-center" });

    const handleClose = () => {
        setBefore('');
        setAfter('');
        reset({
            service: '',
            subService: '',
            before: '',
            after: ''
        })
        setShow(false);
    };


    const handleCloseUpdate = () => {

        reset1({
            service: "",
            subService: "",
            before: "",
            after: ""
        })
        setUpdateServiceId("");
        setUpdateBefore('');
        setUpdateAfter('');
        setShowUpdate(false);
    };


    const getData = () => {
        axios.get("/allRecentWork").then((response) => {
            setData(response.data.data);
            setPending(false);
            console.log(response.data.data);
        });

        axios.get('/getServiceHeader').then((response) => {
            setServices(response.data.data);

        }).catch((err) => {
            console.log(err);
        });
    };


    useEffect(() => {
        getData();
    }, []);


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const onSubmit = () => {
        axios.post('/addRecentWork', {
            service_id: serviceId,
            before_image: before,
            after_image: after
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            if (response.status === 200) {
                handleClose();
                addSuccess();
                window.location.reload(false);
            }

        }).catch((err) => {
            console.log(err);
        })
    }

    // add
    // const handleService = (e) => {
    //     let id = e.target.value;
    //     setService(id);
    //     if (id != "") {

    //         axios.get(`/getSubServiceById/${id}`
    //         ).then((response) => {

    //             setSubServicess(response.data.data);
    //         });
    //     } else {
    //         setSubServicess([]);
    //     }
    // }

    const handleProject = (id) => {
        setId(id)
        handleShowUpdate();
    }

    useEffect(() => {
        data?.filter((data) => data.id == id).map((data) => {
            setId(data.id)
            setUpdateServiceId(data.service_id);
            setBeforeImage(data.before_image);
            setAfterImage(data.after_image);
        })
    }, [id])



    const {
        register: register1,
        handleSubmit: handleSubmit1,
        reset: reset1,
        formState: { errors: errors1 },
    } = useForm();
    const onUpdate = (data) => {
        // updateRecentWork
        axios.post('/updateRecentWork', {
            id: id,
            service_id: updateServiceId,
            before_image: updateBefore,
            after_image: updateAfter
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            if (response.status === 200) {
                handleClose();
                addSuccess();
                window.location.reload(false);
            }

        }).catch((err) => {
            console.log(err);
        })

    }




    // update
    // const handleServices = (e) => {
    //     let id = e.target.value;
    //     setServiceId(id);
    //     setShowUpdate(true);
    //     if (id != "") {
    //         axios.get(`/getSubServiceById/${id}`
    //         ).then((response) => {
    //             setSubServices(response.data.data);
    //         });
    //     } else {
    //         setSubServices([]);
    //     }
    // }




    const handleDelete = (id) => {
        axios.post('/deleteRecentWork', {
            id: id,
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            if (response.status === 200) {
                handleClose();
                deleteMsg();
                window.location.reload(false);
            }

        }).catch((err) => {
            console.log(err);
        })
    }


    const tableData = {
        columns: [
            {
                name: "#",
                width: '80px',
                cell: (row, index) => index + 1,
            },
            {
                name: "Service Name",
                selector: "service_name",
                width: '300px',
                //   sortable: true
            },
            // {
            //     name: "Sub Service Name",
            //     selector: "sub_service_name",
            //     // width: '200px',
            //     //   sortable: true
            // },
            {
                name: "Before Image",
                selector: (row) => (
                    <img
                        width="50px"
                        height="50px"
                        className="p-2"
                        src={`/assets/recentwork/${row.before_image}`}
                    />
                ),
                width: "100px",
                // sortable: true,
                // cell: d => <span>{d.genres.join(", ")}</span>
            },
            {
                name: "After Image",
                selector: (row) => (
                    <img
                        width="50px"
                        height="50px"
                        className="p-2"
                        src={`/assets/recentwork/${row.after_image}`}
                    />
                ),
                width: "100px",
                // sortable: true,
            },

            // {
            //     name: "Status",
            //     // width: '200px',
            //     cell: row => <select className="select_status">
            //         <option selected disabled>Status</option>
            //         <option value="0">Pending</option>
            //         <option value="1">Completed</option>
            //     </select>
            //     // selector: (row) => ,

            // },
            {
                name: "Action",
                cell: (row) => (
                    <div className="d-flex" style={{ gap: "10px" }}>
                        {localStorage.getItem("editAuth") == 0 ?
                            <Button className="_edit_icons" onClick={() => handleProject(row.id)}>
                                <FaEdit />
                            </Button> : null}
                        {localStorage.getItem("deleteAuth") == 0 ?
                            <Button className="_delete_btn_" onClick={() => handleDelete(row.id)}>
                                <FaTrashAlt />
                            </Button>
                            : null}
                    </div>
                ),
                right: true,
            },
        ],
    };



    return (
        <>
            <Helmet>
                <title>Blue Cleaning Group | Projects</title>
            </Helmet>

            <Spinner
                animation="border"
                className={pending ? "mySpinner" : "d-none"}
            />
            <section
                id="main-content"
                style={pending ? { opacity: "0.5" } : { opacity: "1" }}
            >
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className="p-3">Recent Projects</span>
                            <section className="card m-3">
                                <div className="addcareer p-2">
                                    {localStorage.getItem("addAuth") == 0 ?
                                        <Button className="Add_btn_" onClick={handleShow}>
                                            Add Projects
                                        </Button> : null}
                                </div>

                                {/* <div className="selectoption mt-4">
                                    <strong >Status :-</strong>
                                    <select className="status filter"

                                    // onChange={(e) => filterByStatus(e.target.value)}
                                    >
                                        <option value="">All</option>
                                        <option value="0">Pending</option>
                                        <option value="1">Completed</option>
                                    </select>
                                </div> */}
                                <div className="main">
                                    <DataTables
                                        data={data}
                                        columns={tableData.columns}
                                        title="Recent Projects"
                                    />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton className="adminpass">
                                <Modal.Title>Add Project</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group>
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Select aria-label="Select Service Type"
                                            {...register("service", {
                                                onChange: (e) => setServiceId(e.target.value),
                                                required: true,
                                            })}
                                        >
                                            <option value="">Select Service Type</option>
                                            {services?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                        {errors.service && <span className='err_msg'>Please Select Service</span>}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Before Image</Form.Label>
                                        <Form.Control
                                            type="file"
                                            {...register("before", {
                                                required: true,
                                                onChange: (e) => setBefore(e.target.files[0]),
                                            })}
                                        />
                                        {errors.before && (
                                            <div className="errMsg">Please Select the Banner</div>
                                        )}
                                        <div className="text-center p-3">

                                            <img
                                                src={before ? URL.createObjectURL(before) : null}
                                                style={{ maxWidth: "300px", maxHeight: "300px" }}
                                            />

                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>After Image</Form.Label>
                                        <Form.Control
                                            type="file"
                                            {...register("after", {
                                                required: true,
                                                onChange: (e) => setAfter(e.target.files[0]),
                                            })}
                                        />
                                        {errors.after && (
                                            <div className="errMsg">Please Select the Banner</div>
                                        )}
                                        <div className="text-center p-3">

                                            <img
                                                src={after ? URL.createObjectURL(after) : null}
                                                style={{ maxWidth: "300px", maxHeight: "300px" }}
                                            />

                                        </div>
                                    </Form.Group>

                                    <div className="_add_float mt-2">
                                        <Button
                                            variant="secondary"
                                            onClick={handleClose}
                                            className="_close_btn"
                                        >
                                            Close
                                        </Button>
                                        <Button className="Add_btn_" type="submit">Submit</Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>

                        <Modal show={showUpdate} onHide={handleCloseUpdate}>
                            <Modal.Header closeButton className="adminpass">
                                <Modal.Title>Update Project</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit1(onUpdate)}>
                                    <Form.Group>
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Select aria-label="Select Service Type"
                                            value={updateServiceId}
                                            {...register1("service", {
                                                onChange: (e) => setUpdateServiceId(e.target.value),
                                                required: false,
                                            })}
                                        >
                                            <option value="">Select Service Type</option>
                                            {services?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                        {errors1.service && <span className='err_msg'>Please Select Service</span>}
                                    </Form.Group>
                                    {/* <Form.Group>
                                        <Form.Label>Sub Service Name</Form.Label>
                                        <Form.Select aria-label="Select Sub Type"
                                            value={subServiceId}
                                            {...register1("subService", {
                                                onChange: (e) => setSubServiceId(e.target.value),
                                                required: true,
                                            })}
                                        >
                                            <option value="">Select Sub Service Type</option>
                                            {subServices?.map((subservice) => {
                                                return (
                                                    <option key={subservice.id} value={subservice.id}>{subservice.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                        {errors1.subservice && <span className='err_msg'>Please Select Sub Service</span>}
                                    </Form.Group> */}
                                    <Form.Group className="mb-3">
                                        <Form.Label>Before Image</Form.Label>
                                        <Form.Control
                                            type="file"
                                            {...register1("before", {
                                                required: false,
                                                onChange: (e) => setUpdateBefore(e.target.files[0]),
                                            })}
                                        />
                                        {errors1.before && (
                                            <div className="errMsg">Please Select the Banner</div>
                                        )}
                                        <div className="text-center p-3">
                                            {updateBefore == "" ? (
                                                <img
                                                    src={`/assets/recentwork/${beforeImage}`}
                                                    style={{ maxWidth: "300px", maxHeight: "300px" }}
                                                />
                                            ) : (
                                                <img
                                                    src={URL.createObjectURL(updateBefore)}
                                                    style={{ maxWidth: "300px", maxHeight: "300px" }}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>After Image</Form.Label>
                                        <Form.Control
                                            type="file"
                                            {...register1("after", {
                                                required: false,
                                                onChange: (e) => setUpdateAfter(e.target.files[0]),
                                            })}
                                        />
                                        {errors1.after && (
                                            <div className="errMsg">Please Select the Banner</div>
                                        )}
                                        <div className="text-center p-3">
                                            {updateAfter == "" ? (
                                                <img
                                                    src={`/assets/recentwork/${afterImage}`}
                                                    style={{ maxWidth: "300px", maxHeight: "300px" }}
                                                />
                                            ) : (
                                                <img
                                                    src={URL.createObjectURL(updateAfter)}
                                                    style={{ maxWidth: "300px", maxHeight: "300px" }}
                                                />
                                            )}
                                        </div>
                                    </Form.Group>

                                    <div className="_add_float mt-2">
                                        <Button
                                            variant="secondary"
                                            onClick={handleCloseUpdate}
                                            className="_close_btn"
                                        >
                                            Close
                                        </Button>
                                        <Button className="Add_btn_" type="submit">Update</Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </div>
                </section>
            </section>
        </>
    );
};

export default ProjectsAdmin;