import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import {GiSpray} from 'react-icons/gi';

const SingleService = ({ id, handleService, removeService, service, price, countedData }) => {

    let count = countedData?.filter(data => data.id == id).map(data => data.count).toString();
    let countValue = count ? parseInt(count) : 0

    // const [value, setValue] = useState(countValue)    


    return (
        <>
            <li className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center" style={{gap: '10px'}}>
                <div className='detail_content'>
                    <h4 className='m-0 blue_h'><GiSpray className='cool_icon_' />{service}</h4>
                    {/* <span>(&#60;12sqm)</span> */}
                </div>
                <div className='d-flex _select_radio'>

                    {/* <Form.Check
                        type="radio"
                        label="Yes"
                        id={`yes_`}
                        name="radio1"
                        htmlFor="yes_"
                        checked={countValue == 1 ? true : false}
                        onChange={() => handleService(id, service, price)}

                    />

                    <Form.Check
                        type="radio"
                        label="No"
                        id={`no_`}
                        name="radio1"
                        htmlFor="no_"
                        checked={countValue == 0 ? true : false}
                        onChange={() => removeService(id)}
                    /> */}

                    <label class="con1"
                        onClick={() => handleService(id, service, price)}
                    >
                        <span>Yes</span>
                        <input type="radio" name="radio1"
                         style={{width: '0px'}}
                            checked={countValue == 1 ? true : false}
                        //    onChange={() => handleService(id, service, price)}
                        />
                        <span class="checkmark"><FaCheck className='checkedIcon' /></span>
                    </label>
                    <label class="con1"
                        onClick={() => removeService(id)}
                    >
                        <span>No</span>
                        <input type="radio" name="radio1"
                            style={{width: '0px'}}
                            checked={countValue == 0 ? true : false}

                        // onChange={() => removeService(id)}
                        />
                        <span class="checkmark"><FaCheck className='checkedIcon' /></span>
                    </label>


                </div>
            </li>
        </>
    )
}

export default SingleService