import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DataTables from "../DataTable/DataTables";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

const SubAdmins = () => {

    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    // const [permissions, setPermissions] = useState([]);
    const [groupSet, setGroupSet] = useState([]);
    const [pending, setPending] = useState(true);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [showUpdate, setShowUpdate] = useState(false);
    const handleUpdate = () => setShowUpdate(true);
    const [showDelete, setShowDelete] = useState(false)
    const [deleteId, setDeleteId] = useState('');
    const [name, setName] = useState('');
    const [passShow, setPassShow] = useState(false);
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [group, setGroup] = useState('');

    // update
    const [adminData, setAdminData] = useState([]);
    const [id, setId] = useState('');
    const [updateName, setUpdateName] = useState('');
    const [updatePassShow, setUpdatePassShow] = useState(false);
    const [updateEmail, setUpdateEmail] = useState('');
    const [updateAddress, setUpdateAddress] = useState('');
    const [updatePhone, setUpdatePhone] = useState('');
    const [updatePassword, setUpdatePassword] = useState('');
    const [updateGroup, setUpdateGroup] = useState('');



    const getData = () => {

        // allAuth
        axios.get('/subAdminList').then((response) => {
            if (response.status === 200) {
                setData(response.data.data);
                setNewData(response.data.data);
                setPending(false);
            }
        }).catch((err) => {
            console.log(err)
        })

        // axios.get('/allAuth').then((response) => {
        //     if (response.status === 200) {
        //         setPermissions(response.data.subAdmin);
        //         setPending(false);
        //         console.log(response.data.subAdmin)
        //     }

        // }).catch((err) => {
        //     console.log(err)
        // })

        // groupSet
        axios.get('/groupSet').then((response) => {
            if (response.status === 200) {
                setGroupSet(response.data.data);
                setPending(false);
                console.log(response.data.data)
            }

        }).catch((err) => {
            console.log(err)
        })


    }

    useEffect(() => {
        getData();
    }, [])

    const handleProject = (id) => {
        handleUpdate();
        axios.get(`/singleSubAdmin/${id}`).then((response) => {
            if (response.status === 200) {
                setAdminData(response.data.data);
                setPending(false);
                console.log(response.data.data)
            }

        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        adminData?.map((data) => {
            setId(data.id);
            setUpdateName(data.name);
            setUpdateEmail(data.email);
            setUpdatePhone(data.phone);
            setUpdateAddress(data.address);
            setUpdateGroup(data.group_set);
        })
    }, [adminData])

    // useEffect(() => {
    //     permissions?.map((auth) => {
    //         let updateAuth = [auth.add_permission, auth.edit_permission, auth.delete_permission]
    //         setAddAuth(auth.add_permission);
    //         setEditAuth(auth.edit_permission);
    //         setDeleteAuth(auth.delete_permission);
    //         setAuth(updateAuth);
    //     })

    // }, [permissions]);

    useEffect(() => {
        setData(newData?.filter(data => data.status == 0));

    }, [newData]);


    const handleShowDelete = (id) => {
        setShowDelete(true);
        setDeleteId(id)
    };

    const handleClose = () => {
        setShow(false);

        reset({
            name: "",
            email: "",
            phone: "",
            address: "",

        });
    };

    const handleCloseDelete = () => {
        setShowDelete(false);
        setDeleteId('');
    };

    const handleUpdateClose = () => {
        setShowUpdate(false);
        reset1({
            name: "",
            email: "",
            phone: "",
            address: "",
        })

    }

    // const handleAdd = (e) => {
    //     let updateAuth = [...auth];

    //     if (e.target.checked) {
    //         setAddAuth(0);
    //         updateAuth[0] = 0;
    //     } else {
    //         setAddAuth(1);
    //         updateAuth[0] = 1;
    //     }


    //     setAuth(updateAuth)
    // }

    // const handleEdit = (e) => {
    //     let updateAuth = [...auth];

    //     if (e.target.checked) {
    //         setEditAuth(0);
    //         updateAuth[1] = 0;
    //     } else {
    //         setEditAuth(1);
    //         updateAuth[1] = 1;
    //     }

    //     setAuth(updateAuth)

    // }

    // const handleDelete = (e) => {
    //     let updateAuth = [...auth];

    //     if (e.target.checked) {
    //         setDeleteAuth(0);
    //         updateAuth[2] = 0;
    //     } else {
    //         setDeleteAuth(1);
    //         updateAuth[2] = 1;
    //     }

    //     setAuth(updateAuth)

    // }


    const statusSuccess = () =>
        toast.info("Status Update Successfully", { position: "top-center" });
    const authSuccess = () =>
        toast.info("Authentication Update Successfully", { position: "top-center" });

    const handleStatus = (e) => {
        let id = e.target.id;
        let status = e.target.value;
        axios.post('/subAdminStatus', {
            id: id,
            status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                statusSuccess();
                window.location.reload(false);

            }
        }).catch((err) => {

            console.log(err);
        })
    }

    const notify = () => toast.info("Sub Admin added Successfully", { position: "top-center" });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        setPending(true);
        axios.post('/addSubAdmin', {

            name: name,
            email: email,
            password: password,
            phone: phone,
            address: address,
            group_set : group

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false);
                handleClose();
                window.location.reload(false);

            }

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        reset: reset1,
        formState: { errors: errors1 },
    } = useForm();

    const onUpdate = () => {
        setPending(true);
        axios.post('/updateSubAdmin', {
            id : id,
            group_set : updateGroup,
            name: updateName,   
            phone: updatePhone,
            address: updateAddress,
            

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false);
                handleUpdateClose();
                window.location.reload(false);

            }

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.status == status));
        }

    }

    // const handleAuth = () => {
    //     axios.post('/updateAuth', {
    //         auth: auth.toString()
    //     }, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "multipart/form-data"
    //         }
    //     }).then((response) => {
    //         if (response.status === 200) {
    //             authSuccess();
    //             handleManageClose();
    //             window.location.reload(false);

    //         }
    //     }).catch((err) => {
    //         console.log(err);
    //     })

    // }

    const deleteSuccess = () =>
        toast.info("Sub Admin Deleted Successfully", { position: "top-center" });

    const handleDeleteSubAdmin = () => {

        axios.post("/deleteSubAdmin",
            {
                id: deleteId
            },
            {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
            .then((response) => {
                if (response.status === 200) {
                    handleCloseDelete();
                    deleteSuccess();
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const tableData = {
        columns: [
            {
                name: "#",
                width: '80px',
                cell: (row, index) => index + 1,
            },
            {
                name: "Name",
                selector: "name"
                // width: '200px',

            },
            {
                name: "Email",
                selector: "email"
                // width: '200px',

            },
            {
                name: "Phone",
                selector: "phone"
                // width: '200px',

            },
            {
                name: "Address",
                selector: "address"
                // width: '200px',

            },
            {
                name: "Status",
                width: '250px',
                selector: row => <select className=" col-md-4 select_status"
                    value={row.status}
                    id={row.id}
                    onChange={(e) => handleStatus(e)}
                >
                    <option value="0">Active</option>
                    <option value="1">In-Active</option>

                </select>,
                center: true,



            },

            {
                name: "Action",
                cell: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>
                        <Button className='_edit_icons' onClick={() => handleProject(row.id)} >
                            <FaEdit />
                        </Button>
                        <Button className="_delete_btn_" onClick={() => handleShowDelete(row.id)} >
                            <FaTrashAlt />
                        </Button>
                    </div>
                ),
                right: true,
            },
        ],
    };



    return (
        <>
            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <ToastContainer />
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className="p-3">Sub Admins</span>
                            <section className="card m-3">

                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className="selectoption">
                                        <strong >Status :-</strong>
                                        <select className="status filter"

                                            onChange={(e) => filterByStatus(e.target.value)}
                                        >
                                            <option value="">All</option>
                                            <option value="0" selected>Active</option>
                                            <option value="1">In-Active</option>
                                        </select>
                                    </div>

                                    <div className="add_section p-2">
                                        {/* <Button className='_edit_btn'
                                            onClick={() => handleManage()}
                                        >
                                            Manage User
                                        </Button> */}
                                        <Button className="Add_btn_"
                                            onClick={() => handleShow()}
                                        >
                                            Add User
                                        </Button>

                                    </div>
                                </div>

                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} title="Sub Admins" />
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className="adminpass">
                    <Modal.Title>Add Sub Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                        <Form.Group className="pb-4" >
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder='Please enter name'
                                value={name}
                                {...register('name', {
                                    required: true,
                                    onChange: (e) => setName(e.target.value),
                                })}
                            />
                            {errors.name && errors.name.type === "required" && <span className='errMsg'>Please Enter Name</span>}
                        </Form.Group>

                        <Form.Group className="pb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Please enter email" value={email}
                                {...register('email', {
                                    required: true, maxLength: 30,
                                    onChange: (e) => setEmail(e.target.value),
                                })}
                            />
                            {errors.email && errors.email.type === "required" && <span className='errMsg'>Please Enter email Address</span>}
                        </Form.Group>

                        <Form.Group className="pb-4">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="Please enter phone number" maxLength={13}
                                {...register('phone', {
                                    required: true,

                                    onChange: (e) => setPhone(e.target.value),
                                })}
                                value={phone}
                            />
                            {errors.phone && <span className='errMsg'>Please Enter Phone Number</span>}
                        </Form.Group>

                        <Form.Group className='pb-4'>
                            <div style={{ position: 'relative' }}>
                                <label className='form-label'>Password</label>
                                <input type={passShow ? "text" : "password"} style={{ paddingRight: '40px' }} value={password} autoComplete="new-password"
                                    {...register('pass', {
                                        required: true, maxLength: 30,
                                        onChange: (e) => setPassword(e.target.value),
                                    })}
                                    className="form-control" placeholder="Please Create Password" />
                                <Button className='eyeButton' onClick={() => setPassShow(!passShow)}>{passShow ? <AiFillEyeInvisible className='eye_' /> : <AiFillEye className='eye_' />}</Button>
                            </div>
                            {errors.pass && errors.pass.type === "required" && <span className='errMsg'>Please Create Password</span>}

                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Admin Group</Form.Label>
                            <Form.Select value={group}
                                {...register("group", {
                                    onChange: (e) => setGroup(e.target.value),
                                    required: true,
                                })}
                            >
                                <option value="">Select Group</option>
                                {groupSet?.map((item) => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )
                                })}
                            </Form.Select>
                            {errors.group && <span className='errMsg'>Please Select Group</span>}
                        </Form.Group>

                        <Form.Group className="col-md-12 pb-4">
                            <Form.Label>Address</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="address"
                                value={address}
                                {...register('address', {
                                    required: true,
                                    onChange: (e) => setAddress(e.target.value),
                                })}
                            />
                            {errors.address && <span className='errMsg'>Please enter Your Address </span>}
                        </Form.Group>

                        <div className="_add_float mt-2">
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                className="_close_btn"
                            >
                                Close
                            </Button>
                            <Button className="Add_btn_" type="submit" disabled={pending ? true : false}>Submit</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showUpdate} onHide={handleUpdateClose}>
                <Modal.Header closeButton className="adminpass">
                    <Modal.Title>Update Sub Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit1(onUpdate)} autoComplete="off">

                        <Form.Group className="pb-4" >
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder='Please enter name'
                                value={updateName}
                                {...register1('name', {
                                    required: false,
                                    onChange: (e) => setUpdateName(e.target.value),
                                })}
                            />
                            {errors1.name && errors1.name.type === "required" && <span className='errMsg'>Please Enter Name</span>}
                        </Form.Group>

                        <Form.Group className="pb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Please enter email" value={updateEmail} readOnly={true}
                                {...register1('email', {
                                    required: false, maxLength: 30,
                                    onChange: (e) => setUpdateEmail(e.target.value),
                                })}
                            />
                            {errors1.email && errors1.email.type === "required" && <span className='errMsg'>Please Enter email Address</span>}
                        </Form.Group>

                        <Form.Group className="pb-4">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="Please enter phone number" maxLength={13}
                                {...register1('phone', {
                                    required: false,

                                    onChange: (e) => setUpdatePhone(e.target.value),
                                })}
                                value={updatePhone}
                            />
                            {errors1.phone && <span className='errMsg'>Please Enter Phone Number</span>}
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Admin Group</Form.Label>
                            <Form.Select value={updateGroup}
                                {...register1("group", {
                                    onChange: (e) => setUpdateGroup(e.target.value),
                                    required: false,
                                })}
                            >
                                {/* <option value="">Select Group</option> */}
                                {groupSet?.map((item) => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )
                                })}
                            </Form.Select>
                            {errors.group && <span className='errMsg'>Please Select Group</span>}
                        </Form.Group>

                        <Form.Group className="col-md-12 pb-4">
                            <Form.Label>Address</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="address"
                                value={updateAddress}
                                {...register1('address', {
                                    required: false,
                                    onChange: (e) => setUpdateAddress(e.target.value),
                                })}
                            />
                            {errors1.address && <span className='errMsg'>Please enter Your Address </span>}
                        </Form.Group>

                        <div className="_add_float mt-2">
                            <Button
                                variant="secondary"
                                onClick={handleUpdateClose}
                                className="_close_btn"
                            >
                                Close
                            </Button>
                            <Button className="Add_btn_" type="submit" disabled={pending ? true : false}>Submit</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>


            {/* <Modal show={showManage} onHide={handleManageClose}>
                <Modal.Header closeButton className="adminpass">
                    <Modal.Title>Sub Admin Authentication</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group className="manage_admin_">

                            <Form.Check
                                type="checkbox"
                                checked={addAuth == 0 ? true : false}
                                onChange={(e) => handleAdd(e)}
                            // value={auth.add_permission}

                            /><span>Add</span>
                        </Form.Group>
                        <Form.Group className="manage_admin_">

                            <Form.Check
                                type="checkbox"
                                checked={editAuth == 0 ? true : false}
                                onChange={(e) => handleEdit(e)}

                            // value={auth.edit_permission}

                            /><span>Update/Edit</span>
                        </Form.Group>
                        <Form.Group className="manage_admin_">

                            <Form.Check
                                type="checkbox"
                                checked={deleteAuth == 0 ? true : false}
                                onChange={(e) => handleDelete(e)}
                            // value={auth.delete_permission}

                            /><span>Delete</span>
                        </Form.Group>

                        <div className="_add_float mt-2">
                            <Button
                                variant="secondary"
                                onClick={handleManageClose}
                                className="_close_btn"
                            >
                                Close
                            </Button>
                            <Button className="Add_btn_" onClick={handleAuth} >Submit</Button>
                        </div>
                    </Form>

                </Modal.Body>

            </Modal> */}

            <Modal show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton className='adminpass'>
                    <Modal.Title>Delete Sub Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                        <h3>Are you sure to delete this Sub Admin</h3>
                    </div>

                    <div className="_add_float mt-2">
                        <Button
                            variant="secondary"
                            onClick={handleCloseDelete}
                            className="_close_btn"
                        >
                            Close
                        </Button>
                        <Button variant='danger' onClick={() => handleDeleteSubAdmin()}>Delete</Button>
                    </div>

                </Modal.Body>


            </Modal>

        </>
    )
}

export default SubAdmins