import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';

const MyAccount = () => {

    const { register: register1, handleSubmit: handleSubmit1, watch: watch1, reset: reset1, formState: { errors: errors1 } } = useForm();

    const navigate = useNavigate();


    const [profile, setProfile] = useState([]);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [myspinner, setMyspinner] = useState(false);


  

    // logOut
    const logOut = () => {
        localStorage.setItem("auth1", "");
        localStorage.setItem("userId", "");
        navigate('/');
        setTimeout(function () {
            window.location.reload(1);
        }, 1000);
    }

    const getUser = () => {
        axios.post('/getUser', { email: localStorage.getItem("auth1") }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            setProfile(response.data.data);
            setMyspinner(false);
        }).catch(err => {
            setMyspinner(false);
            console.log(err)
        });

    }

    useEffect(() => {
        getUser();
        setMyspinner(true);
    }, []);

    useEffect(() => {
        profile?.map((profile) => {
            setUsername(profile.name);
            setEmail(profile.email);
            setPhone(profile.phone);
            setAddress(profile.address);
            setPostcode(profile.postcode);
        })

    }, [profile]);

    const profileUpdate = () =>
        toast.info("Profile Update Successfully", { position: "top-center" });

    const updateUser = () => {
        axios.post('/updateUser', {
            email: email,
            name: username,
            phone: phone,
            address: address,
            postcode: postcode,

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            profileUpdate();
            setTimeout(function () {
                window.location.reload(1);
            }, 1000);
        }).catch(err => {
            console.log(err)
        })
    }



    return (
        <>

            <Helmet>

                <title>Blue Cleaning Group | Profile</title>

            </Helmet>
            <div className={myspinner ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section className='fix_width' style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>
                <ToastContainer />
                <div className='container-fluid mt-5 mb-5' style={{ minHeight: '400px' }}>

                    <div className='d-flex justify-content-between pb-4'>
                        <h3 className='mb-3'>Profile</h3>
                        <Button onClick={logOut}>
                            Logout
                        </Button>
                    </div>


                    <Form onSubmit={handleSubmit1(updateUser)} className="row">
                        <Form.Group className='col-lg-6 mb-3'>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Please Enter Your Name" value={username}
                                required
                                {...register1('name', {
                                    required: false, maxLength: 30,
                                    onChange: (e) => setUsername(e.target.value),
                                })}
                            />
                            {errors1.name && errors1.name.type === "required" && <span className='err_msg'>Please Enter Your Name</span>}
                        </Form.Group>

                        <Form.Group className='col-lg-6 mb-3' >
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="Please Enter Your Email Address" value={email} disabled={true}
                                {...register1('email', {
                                    required: false, maxLength: 30,
                                    onChange: (e) => setEmail(e.target.value),
                                })}
                            />
                            {errors1.email && errors1.email.type === "required" && <span className='err_msg'>Please Enter Your Email</span>}
                        </Form.Group>
                        <Form.Group className='col-lg-6 mb-3' >
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="number" placeholder="Please Enter Your Phone Number" value={phone}
                                maxLength={13}
                                required
                                {...register1('phone', {
                                    required: false,
                                    maxLength: 15,
                                    minLength: 10,

                                    // pattern: {
                                    //     value: /^[-\s\.]?[0-9]{5}[-\s\.]?[0-9]{5,10}$/,
                                    //     message: "Minimum 10 digit required"
                                    // },
                                    onChange: (e) => setPhone(e.target.value),
                                })}
                            />
                            {errors1.phone && errors1.phone.type === "maxLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                            {errors1.phone && errors1.phone.type === "minLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                            {errors1.phone && errors1.phone.type === "required" && <span className='err_msg'>Please Enter Your Phone Number</span>}

                        </Form.Group>

                        <Form.Group className='col-lg-6 mb-3' >
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control type="number" placeholder="Please Enter Your Postcode" value={postcode} maxLength={6}
                                {...register1('postcode', {
                                    required: false, min: 1000, max: 999999,
                                    onChange: (e) => setPostcode(e.target.value),
                                })}
                            />

                            {errors1.postcode && errors1.postcode.type === "min" && <span className='err_msg'>Please Enter Valid Postcode</span>}
                            {errors1.postcode && errors1.postcode.type === "max" && <span className='err_msg'>Please Enter Valid Postcode</span>}
                            {errors1.postcode && errors1.postcode.type === "required" && <span className='err_msg'>Please Enter Your Postcode</span>}
                        </Form.Group>

                        <Form.Group className='col-lg-12 mb-3'>
                            <Form.Label>Address</Form.Label>
                            <Form.Control as="textarea" rows={3} type="text" placeholder="Please Enter Your Address" value={address}
                                {...register1('address', {
                                    required: false, maxLength: 30,
                                    onChange: (e) => setAddress(e.target.value),
                                })}
                            />
                            {errors1.address && errors1.address.type === "required" && <span className='err_msg'>Please Enter Your Address</span>}
                        </Form.Group>

                        <div style={{ textAlign: 'center', margin: '30px 0px' }}>
                            <Button className='loginBtn' type='submit'>
                                {/* <div className={mySpinnerReg ? "d-none" : "spinner-border spinner-border-sm login_spinner"} style={{ top: '62%', left: '37%' }} role="status"></div> */}
                                Update
                            </Button>
                        </div>
                    </Form>


                    {/* <div className='d-flex justify-content-end m-3'>
                        <Button className='loginBtn' onClick={logOut}>Logout</Button>
                    </div> */}



                </div>
            </section>




        </>
    )
}

export default MyAccount