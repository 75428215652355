import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import { Button } from "react-bootstrap";
import { FaShare, FaEdit, FaTrashAlt } from "react-icons/fa";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import DataTables from '../DataTable/DataTables';
import DynamicFormFields from './DynamicForm';

const SubServiceItem = () => {

  const [data, setData] = useState([]);
  const [service, setService] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [updateServices, setUpdateServices] = useState([]);
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [pending, setPending] = useState(true);
   const[subservice_comb_id ,setSubservicecombid] =useState('');

  // add 
  const [subServiceListType, setSubServiceListType] = useState('');
  // 0 || 1
  const [serviceId, setServiceId] = useState('');
  const [subServiceId, setSubServiceId] = useState('');
  const [price, setPrice] = useState('');
  const [name, setName] = useState('');
  // 2
  const [selectedSubService, setSelectedSubService] = useState('');
  // 3
  const [extraImage, setExtraImage] = useState('');


  // update 
  const [id, setId] = useState('');
  const [singleData, setSingleData] = useState([]);
  const [subServiceList, setSubServiceList] = useState([]);
  const [updateSelectedType, setUpdateSelectedType] = useState('');
  const [updateSelectedServiceId, setUpdateSelectedServiceId] = useState('');
  const [updateSelectedSubId, setUpdateSelectedSubId] = useState('');
  const [updateName, setUpdateName] = useState('');
  const [updatePrice, setUpdatePrice] = useState('');
  const [updateSelectedSub, setUpdateSelectedSub] = useState('');
  const [extraImg, setExtraImg] = useState('');
  const [updatedExtra, setUpdatedExtra] = useState('');

  // delete
  const [deleteId, setDeleteId] = useState('');
  const [combinations, setCombinations] = useState([]);


  const [formDataAdd, setFormDataAdd] = useState({
    id:'',
    sub_service_id: '',
    sub_service_list_type: '',
    name: '',
    price: '',
    selected_sub_service: '',
    comb1_name: '',
    comb2_name: '',
    // extra_icon: '',
    comb1_qty: '',
    comb2_qty: '',
    comb_price: '',
    service_id:'',

  })



  const getData = () => {
    axios.get('/allSubServiceList').then((response) => {
      setData(response.data.data);
      setPending(false);
      console.log(response.data.data);
    });

    axios.get('/getServiceHeader').then((response) => {
      setService(response.data.data);
      console.log(response.data.data);
    });
    
  }

  useEffect(() => {
    getData();

  }, []);
  const handleAddSection = () => {
    // Create a new section with a unique ID
    const newSection = {
      id: Date.now(),
      comb1_qty: '',
      comb2_qty: '',
      comb_price: '',

    };

    // Add the new section to the existing sections
    setCombinations((prevSections) => [...prevSections, newSection]);
  };
  const handleRemoveSection = (idToRemove) => {
    // Remove the section with the provided ID from the sections array
    setCombinations((prevSections) =>
      prevSections.filter((section) => section.id !== idToRemove)
    );
  };

  const handleInputChange1 = (id, field, event) => {
    // Update the state with the new value for the specified field in the section with the provided ID
    const { value } = event.target;
    setCombinations((prevSections) =>
      prevSections.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      )
    );
  };


  useEffect(() => {
    setServiceId("");
    setSubServiceId("");
    setExtraImage("");
    setName("");
    setPrice("");
    setSelectedSubService("");
    reset({
      name: "",
      image: "",
      price: "",
      service: "",
      subservice: "",
      selectedsubservice: ""
    })

  }, [subServiceListType])

  const handleClose = () => {
    setShow(false);
    setSubServiceListType('');
    setExtraImage("");
    reset({
      name: "",
      image: "",
      price: "",
      service: "",
      subservice: "",
      selectedsubservice: ""
    })
  }


  const handleShow = () => {
    setShow(true);
    setCombinations([{
      id: Date.now(),
      comb1_qty: '',
      comb2_qty: '',
      comb_price: '',
    }]);
  }

  const handleUpdateClose = () => {
    setShowUpdate(false);

    reset({
      name: "",
      image: "",
      price: "",
      subservice: "",
      selectedsubservice: ""
    });
    setUpdateSelectedType("");
    setUpdatedExtra("");
  }

  // useEffect(() => {
  //   setUpdateSelectedType("");
  //   setUpdateSelectedSubId("");
  //   setUpdateSelectedServiceId("");
  //   setUpdatePrice("");
  //   setUpdateSelectedSub("");
  //   setUpdateName("");
  //   setUpdatedExtra("");
  //   reset({
  //     name: "",
  //     image: "",
  //     price: "",
  //     service: "",
  //     subservice: "",
  //     selectedsubservice: ""
  //   })

  // }, [updateSelectedType])

  const handleUpdateShow = () => {
    setShowUpdate(true);
    setCombinations([]);

  }

  const handleService = (e) => {
    let id = e.target.value;
    setServiceId(id);
    if (id != "") {
      axios.get(`/getSubServiceById/${id}`
      ).then((response) => {
        setSubServices(response.data.data);
        setSubservicecombid(response.data.data[0].subservice_comb_id);
       
      });
    } else {
      setSubServices([]);
    }

  }



  function handleInputChange(formField, formValue) {
    setFormDataAdd(prevFormData => ({
      ...prevFormData,
      [formField]: formValue
    }));
  }
 
  

 

  const handleServiceListData = () => {

   
    singleData?.map((seo) => {
      console.log(seo.service_id);
      axios.get(`/getSubServiceById/${seo.service_id}`).then((response) => {
        setSubServices(response.data.data);
        console.log(response.data.data);
      });

      setFormDataAdd(prevFormData => ({
        ...prevFormData,
        ['sub_service_id']: seo.sub_service_id,
        ['service_id']: seo.service_id,
        ['sub_service_list_type']: seo.sub_service_list_type,
        ['name']: seo.name,
        ['id']: seo.id,
        ['price']: seo.price,
        ['selected_sub_service']: seo.selected_sub_service,
        ['comb1_name']: seo.comb1_name,
        ['comb2_name']: seo.comb2_name,
        ['comb1_qty']: seo.comb1_qty,
        ['comb2_qty']: seo.comb2_qty,
        ['comb_id']: seo.comb_id,

      }

      ));


      seo.combination?.map((seo1) => {
    
        setCombinations(prevFormData => [
          ...prevFormData,
          {
            ['id']: seo1.id,
            ['comb_price']: seo1.price,
            ['comb1_qty']: seo1.comb1_qty,
            ['comb2_qty']: seo1.comb2_qty,
            // console.log(seo1.id);

          }

        ]);
      });


    })
  }
  
  useEffect(() => {
    handleServiceListData();

  }, [singleData]);

  // const handleUpdateService = (e) => {
  //   let id = e.target.value;
  //   // setUpdateSelectedServiceId(id);
  //   if (id != "") {
  //     axios.get(`/getSubServiceById/${id}`
  //     ).then((response) => {
  //       setUpdateServices(response.data.data);
  //     });
  //   } else {
  //     setUpdateServices([]);
  //   }

  // }


  const handleServiceList = (id) => {
    setShowUpdate(true);
    setCombinations([]);

    axios.get(`/singleSubServiceList/${id}`).then((response) => {
      setSingleData(response.data.data);
    });
    axios.get('/subServiceName').then((response) => {
      setSubServiceList(response.data.data);
      console.log(response.data.data);
    });


  }

  useEffect(() => {
    singleData?.map((data) => {
      setId(data.id);
      setUpdateSelectedType(data.sub_service_list_type);
      setUpdateSelectedSubId(data.sub_service_id);
      // setUpdateSelectedServiceId(data.sub_service_id);
      setUpdatePrice(data.price);
      setUpdateSelectedSub(data.selected_sub_service);
      setUpdateName(data.name);
      setExtraImg(data.extra_icon);
    });

  }, [subServiceList, singleData])

  const handleShowDelete = (id) => {
    setShowDelete(true);
    setDeleteId(id)
  }

  const handleCloseDelete = () => {
    setShowDelete(false);
    setDeleteId('');
  }


  const notifyStatus = () => toast.info("Status Updated SuccessFully", { position: "top-center" });

  const handleStatus = (e) => {
    let id = e.target.id;
    let status = e.target.value;
    axios.post('/subServiceItemStatus', {
      id: id,
      status: status
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyStatus();
        window.location.reload(false);
      }

    }).catch((err) => {
      console.log(err);
    })
  }

  const notify = () => toast.info("Sub Service Item Added SuccessFully", { position: "top-center" });
  const notifyUpdate = () => toast.info("Sub Service Item Updated SuccessFully", { position: "top-center" });
  const notifyDelete = () => toast.info("Sub Service Item Deleted SuccessFully", { position: "top-center" });

  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = () => {
    // Create a new FormData instance and append the necessary data
    const formData = new FormData();
    formData.append("sub_service_id", formDataAdd.sub_service_id);
    formData.append("service_id", formDataAdd.service_id);
    formData.append("service_comb_id", subservice_comb_id);
    formData.append("sub_service_list_type", subServiceListType);
    formData.append("name", formDataAdd.name);
    formData.append("price", formDataAdd.price);
    formData.append("selected_sub_service", formDataAdd.selected_sub_service);
    formData.append("comb1_name", formDataAdd.comb1_name);
    formData.append("comb2_name", formDataAdd.comb2_name);
    formData.append("extra_icon", extraImage);
    formData.append("comb1_qty", combinations?.map((data) => data.comb1_qty).toString());
    formData.append("comb2_qty", combinations?.map((data) => data.comb2_qty).toString());
    formData.append("comb_price", combinations?.map((data) => data.comb_price).toString());


    axios
      .post("/addSubServiceList", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          notify();
          handleClose();
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };



  const { register: register1, handleSubmit: handleSubmit1, reset: reset1, formState: { errors: errors1 } } = useForm();

  const onUpdate = () => {





    // updateSubServiceList

    const formData = new FormData();
    console.log(formDataAdd.id);
    formData.append("id", formDataAdd.id);
    formData.append("sub_service_id", formDataAdd.sub_service_id);
    formData.append("sub_service_list_type", updateSelectedType);
    formData.append("name", formDataAdd.name);
    formData.append("service_comb_id", subservice_comb_id);
    formData.append("price", formDataAdd.price);
    formData.append("selected_sub_service", formDataAdd.selected_sub_service);
    formData.append("comb1_name", formDataAdd.comb1_name);
    formData.append("comb2_name", formDataAdd.comb2_name);
    formData.append("extra_icon", updatedExtra);
    formData.append("comb1_qty", combinations?.map((data) => data.comb1_qty).toString());
    formData.append("comb2_qty", combinations?.map((data) => data.comb2_qty).toString());
    formData.append("comb_price", combinations?.map((data) => data.comb_price).toString());
    // formData.append("id", combinations?.map((data) => data.id).toString());
    formData.append("comb_id", formDataAdd.comb_id);

    axios
      .post("/updateSubServiceList", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          notifyUpdate();
          handleClose();
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // axios.post('updateSubServiceList', {
    //   id: id,
    //   sub_service_id: updateSelectedSubId,
    //   sub_service_list_type: updateSelectedType,
    //   name: updateName,
    //   price: updatePrice,
    //   selected_sub_service: updateSelectedSub,
    //   extra_icon: updatedExtra

    // }, {
    //   method: 'POST',
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   }
    // }

    // ).then((response) => {
    //   if (response.status === 200) {
    //     notifyUpdate();
    //     handleUpdateClose();
    //     window.location.reload(false);
    //   }
    // }).catch((err) => {
    //   console.lgo(err);
    // })

  }

  // delete item

  const handleDelete = () => {

    axios.post("/deleteSubServiceList",
      {
        id: deleteId
      },
      {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        handleCloseDelete();
        notifyDelete();
        window.location.reload(false);
      }
    }).catch((err) => {
      console.log(err);
    });


  }



  const tableData = {
    columns: [
      {
        name: "#",
        width: '80px',
        cell: (row, index) => index + 1,
      },
      {
        name: "Name",
        selector: "name",
        width: '300px',

      },

      {
        name: "Sub Service Name",
        selector: "sub_service_name",
        width: '300px',

      },
      {
        name: "Selected Sub Service",
        selector: "selected_sub_service",
        width: '300px',

      },
      {
        name: "sub_service_list_type",
        selector: "sub_service_list_type",
      },
      {
        name: "Extra",
        selector: (row) => (
          <>
            {row.extra_icon == "" ? "-" :
              <img
                width="50px"
                height="50px"
                className="p-2"
                src={`/assets/extras/${row.extra_icon}`}
              />
            }
          </>
        ),
        width: "100px",
        // sortable: true,
        // cell: d => <span>{d.genres.join(", ")}</span>
      },
      {
        name: "Price",
        selector: "price",
        width: "80px"
      },

      {
        name: "Status",
        cell: row => <select className="select_status" disabled={localStorage.getItem("editAuth") == 0 ? false : true}
          value={row.status} id={row.id}
          onChange={(e) => handleStatus(e)}
        >
          <option value="0">Active</option>
          <option value="1">In-Active</option>
        </select>
        // selector: (row) => ,


      },
      {
        name: "Action",
        cell: (row) => (
          <div className="d-flex" style={{ gap: "10px" }}>
            {localStorage.getItem("editAuth") == 0 ?
              <Button className="_edit_icons" onClick={() => handleServiceList(row.id)}>
                <FaEdit />
              </Button>
              : null}
            {localStorage.getItem("deleteAuth") == 0 ?
              <Button className="_delete_btn_" onClick={() => handleShowDelete(row.id)}>
                <FaTrashAlt />
              </Button>
              : null}
          </div>
        ),
        right: true,
      },
    ],
  };



  function getItemData(type) {
    switch (type) {
      case '0':
      case '1':
        return (
          <>
            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id || ''}
                {...register("service_id", {
                
                  required: true,
                })}
                onChange={(e) => {
                  handleService(e);
                  handleInputChange('service_id', e.target.value);
                }}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''}
                {...register("sub_service_id", {
                  onChange: (e) => handleInputChange('sub_service_id', e.target.value),
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register('name', {
                  required: true,
                  onChange: (e) => handleInputChange('name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Service Item Name</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" placeholder='Please enter price'
                value={formDataAdd.price || ''}
                {...register('price', {
                  required: true,
                  onChange: (e) => handleInputChange('price', e.target.value),
                })}
              />
              {errors.price && <span className='err_msg'>Please enter Price </span>}
            </Form.Group>
          </>
        )
      case '2':
        return (
          <>
            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id || ''}
                {...register("service_id", {
                
                  required: true,
                })}
                onChange={(e) => {
                  handleService(e);
                  handleInputChange('service_id', e.target.value);
                }}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''}
                {...register("sub_service_id", {
                  onChange: (e) => handleInputChange('sub_service_id', e.target.value),
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Selected Sub Service</Form.Label>
              <Form.Select value={formDataAdd.selected_sub_service}
                {...register("selected_sub_service", {
                  onChange: (e) => handleInputChange('selected_sub_service', e.target.value),
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.selected_sub_service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register('name', {
                  required: true,
                  onChange: (e) => handleInputChange('name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Service Item Name</span>}
            </Form.Group>
          </>
        )
      case '3':
        return (
          <>
             <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register('name', {
                  required: true,
                  onChange: (e) => handleInputChange('name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Service Item Name</span>}
            </Form.Group>


            <Form.Group className="col-12 pb-3">
              <Form.Label>Extra Icon</Form.Label>
              <Form.Control type="file"
                {...register('extra_icon', {
                  required: true,
                  onChange: (e) => {setExtraImage(e.target.files[0]) },
                })}

              />
              {errors.extra_icon && <span className='err_msg'>Please select image</span>}
            </Form.Group>
            <div className='col-12 text-center'>
              <Form.Label>Image Preview</Form.Label>
              <div className='p-3'>

                <img src={extraImage ? URL.createObjectURL(extraImage) : null} style={{ maxWidth: "300px", maxHeight: "300px" }} />

              </div>
            </div>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" placeholder='Please enter price'
                value={formDataAdd.price || ''}
                {...register('price', {
                  required: true,
                  onChange: (e) => handleInputChange('price', e.target.value),
                })}
              />
              {errors.price && <span className='err_msg'>Please enter Price </span>}
            </Form.Group>
          </>
        )
      case '4':
        return (
          <>
            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id || ''}
                {...register("service_id", {
                
                  required: true,
                })}
                onChange={(e) => {
                  handleService(e);
                  handleInputChange('service_id', e.target.value);
                }}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''}
                {...register("sub_service_id", {
                  onChange: (e) => handleInputChange('sub_service_id', e.target.value),
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className="col-6 pb-3" >
              <Form.Label>Combination Name One</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb1_name}
                {...register('comb1_name', {
                  required: true,
                  onChange: (e) => handleInputChange('comb1_name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Combination Name One</span>}
            </Form.Group>

            <Form.Group className="col-6 pb-3" >
              <Form.Label>Combination Name Two</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb2_name}
                {...register('comb2_name', {
                  required: true,
                  onChange: (e) => handleInputChange('comb2_name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Combination Name Two</span>}
            </Form.Group>

            <hr></hr>
            <div className='col-12 text-end'>
              <button className="add_btn btn btn-primary" type='button' onClick={handleAddSection}>
                Add
              </button>
            </div>
            {combinations?.map((section) => (
              <div key={section.id} className="row">

                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Combination Quantity One</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Quantity"
                    value={section.comb1_qty}
                    onChange={(e) => handleInputChange1(section.id, 'comb1_qty', e)}

                  />



                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb1_qty}
                {...register('comb1_qty', {
                  required: true,
                  onChange: (e) => handleInputChange('comb1_qty', e.target.value),
                })}
              /> */}
                  {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Quantity</span>}
                </Form.Group>
                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Combination Quantity Two</Form.Label>
                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb2_qty}
                {...register('comb2_qty', {
                  required: true,
                  onChange: (e) => handleInputChange('comb2_qty', e.target.value),
                })}
              /> */}

                  <Form.Control
                    type="text"
                    placeholder="Please enter Qunatity"
                    value={section.comb2_qty}

                    onChange={(e) => handleInputChange1(section.id, 'comb2_qty', e)}
                  />
                  {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Quantity</span>}
                </Form.Group>
                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Please enter Price"
                    value={section.comb_price}

                    onChange={(e) => handleInputChange1(section.id, 'comb_price', e)}
                  />


                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.price}
                {...register('price', {
                  required: true,
                  onChange: (e) => handleInputChange('price', e.target.value),
                })}
              /> */}
                  {errors.price && errors.price.type === "required" && <span className='err_msg'>Please Enter Price</span>}
                </Form.Group>
                {combinations.length > 1 && (
                  <>
                    <div className='col-12 text-end'>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleRemoveSection(section.id)}
                      >
                        Remove
                      </button>
                    </div>
                  </>
                )}


              </div>
            ))}



            <hr></hr>
          </>
        )
    }
  }
  function getUpdateData(type) {
    switch (type) {
      case '0':
      case '1':
        return (
          <>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id} disabled
                {...register("service_id", {
                  onChange: (e) => handleService(e),
                  required: true,
                })}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''} disabled
                {...register("sub_service_id", {
                  onChange: (e) => handleInputChange('sub_service_id', e.target.value),
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register1('name', {
                  required: false,
                  onChange: (e) => handleInputChange('name', e.target.value),
            
                })}
              />
              {errors1.name && errors1.name.type === "required" && <span className='err_msg'>Please Service Item Name</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" placeholder='Please enter price'
                value={formDataAdd.price || ''}
                {...register1('price', {
                  required: false,
                  onChange: (e) => handleInputChange('price', e.target.value),
                })}
              />
              {errors1.price && <span className='err_msg'>Please enter Price </span>}
            </Form.Group>
          </>
        )
      case '2':
        return (
          <>


            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id} disabled
                {...register("service_id", {
                  onChange: (e) => handleService(e),
                  required: true,
                })}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''}  disabled
                {...register("sub_service_id", {
                  onChange: (e) => handleInputChange('sub_service_id', e.target.value),
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Selected Sub Service</Form.Label>
              <Form.Select value={formDataAdd.selected_sub_service}
                {...register("selected_sub_service", {
                  onChange: (e) => handleInputChange('selected_sub_service', e.target.value),
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.selected_sub_service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register('name', {
                  required: true,
                  onChange: (e) => handleInputChange('name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Service Item Name</span>}
            </Form.Group>

          </>
        )
      case '3':
        return (
          <>
            <Form.Group className="col-12 pb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.name || ''}
                {...register1('name', {
                  required: false,
                  onChange: (e) => handleInputChange('name', e.target.value),
                })}
              />
              {errors1.name && errors1.name.type === "required" && <span className='err_msg'>Please Service Item Name</span>}
            </Form.Group>

            <Form.Group className="col-12 pb-3">
              <Form.Label>Extra Icon</Form.Label>
              <Form.Control type="file"
                {...register1('extra_icon', {
                  required: false,
                  // onChange: (e) => handleInputChange('extra_icon', e.target.files[0]),
                  onChange: (e) => setUpdatedExtra(e.target.files[0]),
                })}

              />
              {errors1.image && <span className='err_msg'>Please select image</span>}
            </Form.Group>
            <div className='col-12 text-center'>
              <Form.Label>Image Preview</Form.Label>
              <div className='p-3'>
                {updatedExtra == "" ?
                  <img src={`/assets/extras/${extraImg}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                  :
                  <img src={URL.createObjectURL(updatedExtra)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                }
              </div>
            </div>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" placeholder='Please enter price'
                value={formDataAdd.price || ''}
                {...register1('price', {
                  required: false,
                  onChange: (e) => handleInputChange('price', e.target.value),
                })}
              />
              {errors1.price && <span className='err_msg'>Please enter Price </span>}
            </Form.Group>
          </>
        )
      case '4':
        return (
          <>
            <Form.Group className=" col-12 pb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select value={formDataAdd.service_id} disabled
                {...register("service_id", {
                  onChange: (e) => handleService(e),
                  required: true,
                })}
              >
                <option value="">Select Service</option>
                {service?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.service && <span className='err_msg'>Please Select Service</span>}
            </Form.Group>

            <Form.Group className=" col-12 pb-3">
              <Form.Label>Sub Service Type</Form.Label>
              <Form.Select value={formDataAdd.sub_service_id || ''} disabled
                {...register("sub_service_id", {
                  onChange: (e) => handleInputChange('sub_service_id', e.target.value),
                  required: true,
                })}
              >
                <option value="">Select Sub Service</option>
                {subServices?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                })}
              </Form.Select>
              {errors.sub_service_id && <span className='err_msg'>Please Select Sub Service</span>}
            </Form.Group>

            <Form.Group className="col-6 pb-3" >
              <Form.Label>Combination Name One</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb1_name}
                {...register('comb1_name', {
                  required: true,
                  onChange: (e) => handleInputChange('comb1_name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Combination Name One</span>}
            </Form.Group>

            <Form.Group className="col-6 pb-3" >
              <Form.Label>Combination Name Two</Form.Label>
              <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb2_name}
                {...register('comb2_name', {
                  required: true,
                  onChange: (e) => handleInputChange('comb2_name', e.target.value),
                })}
              />
              {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Combination Name Two</span>}
            </Form.Group>

            <hr></hr>
            <div className='col-12 text-end'>
              <button className="add_btn btn btn-primary" type='button' onClick={handleAddSection}>
                Add
              </button>
            </div>
            {combinations?.map((section) => (
              <div key={section.id} className="row">

                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Combination Quantity One</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Quantity"
                    value={section.comb1_qty}
                    onChange={(e) => handleInputChange1(section.id, 'comb1_qty', e)}

                  />



                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb1_qty}
                {...register('comb1_qty', {
                  required: true,
                  onChange: (e) => handleInputChange('comb1_qty', e.target.value),
                })}
              /> */}
                  {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Quantity</span>}
                </Form.Group>
                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Combination Quantity Two</Form.Label>
                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.comb2_qty}
                {...register('comb2_qty', {
                  required: true,
                  onChange: (e) => handleInputChange('comb2_qty', e.target.value),
                })}
              /> */}

                  <Form.Control
                    type="text"
                    placeholder="Please enter Qunatity"
                    value={section.comb2_qty}

                    onChange={(e) => handleInputChange1(section.id, 'comb2_qty', e)}
                  />
                  {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Quantity</span>}
                </Form.Group>
                <Form.Group className="col-4 pb-3" >
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Please enter Price"
                    value={section.comb_price}

                    onChange={(e) => handleInputChange1(section.id, 'comb_price', e)}
                  />


                  {/* <Form.Control type="text" placeholder='Please enter name'
                value={formDataAdd.price}
                {...register('price', {
                  required: true,
                  onChange: (e) => handleInputChange('price', e.target.value),
                })}
              /> */}
                  {errors.price && errors.price.type === "required" && <span className='err_msg'>Please Enter Price</span>}
                </Form.Group>
                {combinations.length > 1 && (
                  <>
                    <div className='col-12 text-end'>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleRemoveSection(section.id)}
                      >
                        Remove
                      </button>
                    </div>
                  </>
                )}


              </div>
            ))}



            <hr></hr>
          </>
        )
    }
  }


  return (
    <>
      <Helmet>

        <title>Blue Cleaning Group | Sub Service Items</title>

      </Helmet>

      <div className={pending ? 'spinner_d' : 'd-none'}>
        <Spinner animation="border" className='mySpinner' />
      </div>
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
        <section className="wrapper">
          <div className="row">
            <div className="col-lg-12">
              <span className='p-3'>Sub Service Items</span>
              <section className="card m-3">
                <div className="addcareer p-2">
                  {localStorage.getItem("addAuth") == 0 ?
                    <Button className="Add_btn_" onClick={() => handleShow()}>
                      Add Sub Service Item
                    </Button>
                    : null}
                </div>
                <div className="main">
                  <DataTables data={data} columns={tableData.columns} title="Sub Service Items" />
                  {/* </DataTableExtensions> */}
                </div>
              </section>
            </div>

            {/* add item */}
            <Modal show={show} onHide={handleClose} size="lg">
              <Modal.Header closeButton className='adminpass'>
                <Modal.Title>Add Sub Service Item</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form className='row' onSubmit={handleSubmit(onSubmit)}>

                  <Form.Group className=" col-12 pb-3">
                    <Form.Label>Sub Service Item Type</Form.Label>
                    <Form.Select value={subServiceListType}

                      {...register('serviceType', {
                        required: true,
                        onChange: (e) => setSubServiceListType(e.target.value),
                      })}

                    // onChange={(e) => setSubServiceType(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="0">Quantity</option>
                      <option value="1">Yes/No</option>
                      <option value="2">Yes/No with Item</option>
                      <option value="3">Extra</option>
                      <option value="4">Combination</option>

                    </Form.Select>
                    {errors.serviceType && <span className='err_msg'>Please Select Service Type</span>}
                  </Form.Group>

                  {getItemData(subServiceListType)}


                  <div className="_add_float mt-2">
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      className="_close_btn"
                    >
                      Close
                    </Button>
                    <Button className="Add_btn_" type="submit">Submit</Button>
                  </div>
                </Form>
              </Modal.Body>

            </Modal>

            {/* update Item */}
            <Modal show={showUpdate} onHide={handleUpdateClose} size="lg">
              <Modal.Header closeButton className='adminpass'>
                <Modal.Title>Update Sub Service Item</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit1(onUpdate)} className='row' >

                  <Form.Group className=" col-12 pb-3">
                    <Form.Label>Sub Service Item Type</Form.Label>
                    <Form.Select value={updateSelectedType}
                      disabled
                      {...register1('serviceType', {
                        required: false,
                        onChange: (e) => setUpdateSelectedType(e.target.value),
                      })}

                    // onChange={(e) => setSubServiceType(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="0">Quantity</option>
                      <option value="1">Yes/No</option>
                      <option value="2">Yes/No with Item</option>
                      <option value="3">Extra</option>
                      <option value="4">Combination</option>

                    </Form.Select>
                    {errors1.serviceType && <span className='err_msg'>Please Select Service Type</span>}
                  </Form.Group>

                  {getUpdateData(updateSelectedType)}


                  <div className="_add_float mt-2">
                    <Button
                      variant="secondary"
                      onClick={handleUpdateClose}
                      className="_close_btn"
                    >
                      Close
                    </Button>
                    <Button className="Add_btn_" type="submit">Update</Button>
                  </div>
                </Form>
              </Modal.Body>

            </Modal>


            {/* delete */}
            <Modal show={showDelete} onHide={handleCloseDelete}>
              <Modal.Header closeButton className='adminpass'>
                <Modal.Title>Delete Sub Service List Item</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                  <h3>Are you sure to delete this Sub Service List Item</h3>
                </div>

                <div className="_add_float mt-2">
                  <Button
                    variant="secondary"
                    onClick={handleCloseDelete}
                    className="_close_btn"
                  >
                    Close
                  </Button>
                  <Button variant='danger' onClick={() => handleDelete()}>Delete</Button>
                </div>

              </Modal.Body>


            </Modal>

          </div>
        </section>
      </section>
    </>
  )
}

export default SubServiceItem;