import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DataTables from "../DataTable/DataTables";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

const Groups = () => {

    const [pending, setPending] = useState(true);
    const [data, setData] = useState([]);

    // add modal
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    // update model 
    const [updateShow, setUpdateShow] = useState(false);
    const handleUpdate = () => setUpdateShow(true);

    // defaul group name
    const [defaultGroup, setDefaultGroup] = useState('');

    // add
    const [groupName, setGroupName] = useState('');
    const [checkedData, setCheckedData] = useState([1, 1, 1]);

    // update groups 
    const [updateGroup, setUpdateGroup] = useState('');
    const [addAuth, setAddAuth] = useState();
    const [editAuth, setEditAuth] = useState();
    const [deleteAuth, setDeleteAuth] = useState();
    const [auth, setAuth] = useState([]);
    const [id, setId] = useState('');

    // delete
    const [showDelete, setShowDelete] = useState(false)
    const [deleteId, setDeleteId] = useState('');




    const getData = () => {
        axios.get('/allAuth').then((response) => {
            if (response.status === 200) {
                setData(response.data.data);
                setPending(false);
                console.log(response.data.data)
            }

        }).catch((err) => {
            console.log(err)
        })
    }


    useEffect(() => {
        getData();
    }, [])


    useEffect(() => {
        setDefaultGroup(data?.filter((data) => data.set == 'set').map((data) => data.name).toString())
    }, [data])



    const handleClose = () => {
        setShow(false);
        reset({
            name: ""
        })
    }

    const handleUpdateClose = () => {
        setUpdateShow(false);
        setId('');
        reset1({
            name: ""
        })

    }




    const handleAuth = (e) => {
        let updatedAuth = [...checkedData];

        if (e.target.id == "add" && e.target.checked) {
            updatedAuth[0] = 0
        } else if (e.target.id == "add" && !e.target.checked) {
            updatedAuth[0] = 1
        } else if (e.target.id == "edit" && e.target.checked) {
            updatedAuth[1] = 0
        } else if (e.target.id == "edit" && !e.target.checked) {
            updatedAuth[1] = 1
        } else if (e.target.id == "delete" && e.target.checked) {
            updatedAuth[2] = 0
        } else if (e.target.id == "delete" && !e.target.checked) {
            updatedAuth[2] = 1
        }

        let myData = updatedAuth;
        setCheckedData(myData);

        // updatedAuth[0] = 

    }







    // add
    const notify = () => toast.info("Group added Successfully", { position: "top-center" });
    const notifyUpdate = () => toast.info("Group Update Successfully", { position: "top-center" });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        setPending(true);
        axios.post('/addAuth', {
            name: groupName,
            auth: checkedData.toString()

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false);
                handleClose();
                window.location.reload(false);
            }

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }



    // update

    const handleAdd = (e) => {
        let updateAuth = [...auth];

        if (e.target.checked) {
            setAddAuth(0);
            updateAuth[0] = 0;
        } else {
            setAddAuth(1);
            updateAuth[0] = 1;
        }


        setAuth(updateAuth)
    }

    const handleEdit = (e) => {
        let updateAuth = [...auth];

        if (e.target.checked) {
            setEditAuth(0);
            updateAuth[1] = 0;
        } else {
            setEditAuth(1);
            updateAuth[1] = 1;
        }

        setAuth(updateAuth)

    }

    const handleDelete = (e) => {
        let updateAuth = [...auth];

        if (e.target.checked) {
            setDeleteAuth(0);
            updateAuth[2] = 0;
        } else {
            setDeleteAuth(1);
            updateAuth[2] = 1;
        }

        setAuth(updateAuth)

    }



    const handleProject = (id) => {
        setId(id);
        handleUpdate();
    }

    useEffect(() => {
        data?.filter((data) => data.id === id).map((data) => {
            // setId(data.id);
            let name = data.name
            let add = data.add_auth
            let edit = data.edit_auth
            let remove = data.delete_auth
            setUpdateGroup(name);
            setAddAuth(add);
            setEditAuth(edit);
            setDeleteAuth(remove);
            let newAuth = [add, edit, remove]
            setAuth(newAuth)
        })
    }, [id])



    const {
        register: register1,
        handleSubmit: handleSubmit1,
        reset: reset1,
        formState: { errors: errors1 },
    } = useForm();


    const ouUpdate = () => {
        setPending(true);
        axios.post('/updateAuth', {
            id: id,
            name: updateGroup,
            auth: auth.toString()

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notifyUpdate();
                setPending(false);
                handleUpdateClose();
                window.location.reload(false);
            }

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }




    // delete 
    const handleShowDelete = (id) => {
        setShowDelete(true);
        setDeleteId(id)
    };

    const handleCloseDelete = () => {
        setShowDelete(false);
        setDeleteId('');
    };

    const deleteSuccess = () =>
        toast.info("Group Deleted Successfully", { position: "top-center" });

    const handleDeleteSubAdmin = () => {

        axios.post("/deleteAuth",
            {
                id: deleteId
            },
            {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
            .then((response) => {
                if (response.status === 200) {
                    handleCloseDelete();
                    deleteSuccess();
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const tableData = {
        columns: [
            {
                name: "#",
                width: '80px',
                cell: (row, index) => index + 1,
            },
            {
                name: "Name",
                selector: "name"
                // width: '200px',

            },

            // {
            //     name: "Status",
            //     width: '250px',
            //     selector: row => <select className=" col-md-4 select_status"
            //         value={row.status}
            //         id={row.id}
            //     // onChange={(e) => handleStatus(e)}
            //     >
            //         <option value="0">Active</option>
            //         <option value="1">In-Active</option>

            //     </select>,
            //     center: true,

            // },

            {
                name: "Action",
                cell: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>
                        <Button className='_edit_icons'
                            onClick={() => handleProject(row.id)}
                        >
                            <FaEdit />
                        </Button>
                        {row.set != "" ?
                            null
                            : <Button className="_delete_btn_"
                                onClick={() => handleShowDelete(row.id)}
                            >
                                <FaTrashAlt />
                            </Button>}
                    </div>
                ),
                right: true,
            },
        ],
    };

    return (
        <>
          <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <ToastContainer />
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className="p-3">Groups</span>
                            <section className="card m-3">

                                <div className='d-flex justify-content-end align-items-center'>
                                    {/* <div className="selectoption">
                                        <strong >Status :-</strong>
                                        <select className="status filter"

                                        // onChange={(e) => filterByStatus(e.target.value)}
                                        >
                                            <option value="">All</option>
                                            <option value="0" selected>Active</option>
                                            <option value="1">In-Active</option>
                                        </select>
                                    </div> */}

                                    <div className="add_section p-2">
                                        {/* <Button className='_edit_btn'
                                        // onClick={() => handleManage()}
                                        >
                                            Manage User
                                        </Button> */}
                                        <Button className="Add_btn_"
                                            onClick={() => handleShow()}
                                        >
                                            Add Group
                                        </Button>

                                    </div>
                                </div>

                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} title="Sub Admins" />
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className="adminpass">
                    <Modal.Title>Add Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>

                        <Form.Group className="pb-4" >
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder='Please enter Group name'
                                value={groupName}
                                {...register('name', {
                                    required: true,
                                    onChange: (e) => setGroupName(e.target.value),
                                })}
                            />
                            {errors.name && errors.name.type === "required" && <span className='errMsg'>Please Enter Group Name</span>}
                        </Form.Group>

                        <Form.Label>Authentication</Form.Label>
                        <Form.Group className="manage_admin_">
                            <Form.Check
                                type="checkbox" id="add"
                                // checked={addAuth == 0 ? true : false}
                                onChange={(e) => handleAuth(e)}
                            // value={auth.add_permission}

                            /><span>Add</span>
                        </Form.Group>
                        <Form.Group className="manage_admin_">
                            <Form.Check
                                type="checkbox" id="edit"
                                // checked={editAuth == 0 ? true : false}
                                onChange={(e) => handleAuth(e)}

                            // value={auth.edit_permission}

                            /><span>Update/Edit</span>
                        </Form.Group>
                        <Form.Group className="manage_admin_">
                            <Form.Check
                                type="checkbox" id="delete"
                                // checked={deleteAuth == 0 ? true : false}
                                onChange={(e) => handleAuth(e)}
                            // value={auth.delete_permission}

                            /><span>Delete</span>
                        </Form.Group>
                        <Form.Group className="manage_admin_">
                            <Form.Check
                                type="checkbox" id="view" disabled checked
                            // checked={addAuth == 0 ? true : false}
                            // onChange={(e) => handleAuth(e)}
                            // value={auth.add_permission}

                            /><span>View</span>
                        </Form.Group>

                        <div className="_add_float mt-2">
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                className="_close_btn"
                            >
                                Close
                            </Button>
                            <Button className="Add_btn_" type="submit" >Submit</Button>
                        </div>
                    </Form>

                </Modal.Body>

            </Modal>

            <Modal show={updateShow} onHide={handleUpdateClose}>
                <Modal.Header closeButton className="adminpass">
                    <Modal.Title>Update Group Authentication</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit1(ouUpdate)}>

                        <Form.Group className="pb-4" >
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder='Please enter Group name'
                                value={updateGroup}
                                {...register1('name', {
                                    required: false,
                                    onChange: (e) => setUpdateGroup(e.target.value),
                                })}
                            />
                            {errors1.name && errors1.name.type === "required" && <span className='errMsg'>Please Enter Group Name</span>}
                        </Form.Group>

                        <Form.Group className="manage_admin_">

                            <Form.Check
                                type="checkbox"
                                checked={addAuth == 0 ? true : false}
                                onChange={(e) => handleAdd(e)}
                            // value={auth.add_permission}

                            /><span>Add</span>
                        </Form.Group>
                        <Form.Group className="manage_admin_">

                            <Form.Check
                                type="checkbox"
                                checked={editAuth == 0 ? true : false}
                                onChange={(e) => handleEdit(e)}

                            // value={auth.edit_permission}

                            /><span>Update/Edit</span>
                        </Form.Group>
                        <Form.Group className="manage_admin_">

                            <Form.Check
                                type="checkbox"
                                checked={deleteAuth == 0 ? true : false}
                                onChange={(e) => handleDelete(e)}


                            /><span>Delete</span>
                        </Form.Group>

                        <Form.Group className="manage_admin_">
                            <Form.Check
                                type="checkbox" id="view" disabled checked
                            // checked={addAuth == 0 ? true : false}
                            // onChange={(e) => handleAuth(e)}
                            // value={auth.add_permission}

                            /><span>View</span>
                        </Form.Group>

                        <div className="_add_float mt-2">
                            <Button
                                variant="secondary"
                                onClick={handleUpdateClose}
                                className="_close_btn"
                            >
                                Close
                            </Button>
                            <Button className="Add_btn_" type="submit" >Submit</Button>
                        </div>
                    </Form>

                </Modal.Body>

            </Modal>

            <Modal show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton className='adminpass'>
                    <Modal.Title>Delete Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-75' style={{ margin: 'auto' }}>
                        <h5>if you delete this group sub admins of this group are move to {defaultGroup}. </h5>
                        <h5>Are you sure to delete this Group</h5>
                    </div>

                    <div className="_add_float mt-2">
                        <Button
                            variant="secondary"
                            onClick={handleCloseDelete}
                            className="_close_btn"
                        >
                            Close
                        </Button>
                        <Button variant='danger' onClick={() => handleDeleteSubAdmin()}>Delete</Button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default Groups

