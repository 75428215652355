import React, { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import WhyHireUsImg from '../assets/Home Page Assets/WhyHireUs-min.png';
import OurFeaturesImg from '../assets/Home Page Assets/Component 126 2-min.png';
import JobFlexibility from '../assets/Home Page Assets/Job Flexibility-min.png';
import Accredited from '../assets/Home Page Assets/Accredited ISO 9001-min.png';
import Emergency from '../assets/Home Page Assets/Emergency Services-min.png';
import ProfessionalService from '../assets/Home Page Assets/Professional Services-min.png';
import CompetitiveRates from '../assets/Home Page Assets/Competitive Rates-min.png';
import Reliability from '../assets/Home Page Assets/Reliability-min.png';
import LeaseCleaning from '../assets/Home Page Assets/End of Lease Cleaning Canberra-min.png';
import ResidentialCleanig from '../assets/Home Page Assets/Residential Cleaning Canberra-min.png';
import CarpetCleaning from '../assets/Home Page Assets/Carpet Cleaning Canberra-min.png';
import OfficeCleanign from '../assets/Home Page Assets/Office Cleaning Canberra-min.png';
import SliderImg1 from '../assets/Home Page Assets/startUpSmart.png';
import SliderImg2 from '../assets/Home Page Assets/Component 128  3-min.png';
import SliderImg3 from '../assets/Home Page Assets/Component 129 3-min.png';
import SliderImg4 from '../assets/Home Page Assets/startUpSmart.png';
import Aos from 'aos';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { motion } from 'framer-motion';
import { fixedContainer, textVariants } from './motion/motion';


const WhyBlueCleaning = () => {

    useEffect(() => {
        Aos.init({
            duration: 1000,
        })
    }, [])


    return (
        <>
            <section className='why_blueCleaning-s'>
                <div className='why_blueCleaning'>
                    <motion.center
                        variants={fixedContainer}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        className="mb-3">
                        <motion.h5
                            variants={textVariants('up', 0.1)}
                            style={{ maxWidth: '316px' }}>Why Blue Cleaning Group</motion.h5>
                        <motion.h3
                            variants={textVariants('up', 0.2)}
                            className='title'>Leaders in the Sanitizing Industry</motion.h3>
                    </motion.center>
                    <Tabs
                        defaultActiveKey="Why Hire Us"
                        id="justify-tab-example"
                        className="tab_btn"
                        justify
                    >

                        <Tab eventKey="Why Hire Us" title="Why Hire Us?" >
                            <div className='tab_content row'>
                                <div
                                    className='left_div col-lg-4 col-md-4 col-sm-12 col-12'>
                                    <motion.div
                                        variants={textVariants('left', 0.1)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        className='left_content'>
                                        <img src={JobFlexibility} />
                                        <div className='left_des'>
                                            <span>Job Flexibility</span>
                                            <p>We can provide all cleaning solutions
                                                which can be tailored to suit your needs & requirements.
                                            </p>
                                        </div>
                                    </motion.div>
                                    <motion.div
                                        variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        className='left_content'>
                                        <img src={Accredited} />
                                        <div className='left_des'>
                                            <span>Accredited ISO 9001</span>
                                            <p>Proudly accredited with ISO 9001,
                                                ultimately this enable us to provide an excellent service.
                                            </p>
                                        </div>
                                    </motion.div>
                                    <motion.div
                                        variants={textVariants('left', 0.3)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        className='left_content'>
                                        <img src={Emergency} />
                                        <div className='left_des'>
                                            <span>Emergency Services</span>
                                            <p>We have a dedicated team that can be available 24/7. No matter the day and time.
                                            </p>
                                        </div>
                                    </motion.div>
                                </div>
                                <div className='center_imgDiv mt-3 col-lg-4 col-md-4 col-sm-12 col-12' >
                                    <motion.img
                                        variants={textVariants('up', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        src={WhyHireUsImg} />
                                </div>
                                <div className='left_div col-lg-4 col-md-4 col-sm-12 col-12' >
                                    <motion.div
                                        variants={textVariants('left', 0.1)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        className='left_content'>
                                        <img src={ProfessionalService} />
                                        <div className='left_des'>
                                            <span>Professional Services</span>
                                            <p>Our Cleaners are trained under Blue Cleaning's strict for quality & assurance.
                                            </p>
                                        </div>
                                    </motion.div>
                                    <motion.div
                                        variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        className='left_content'>
                                        <img src={CompetitiveRates} />
                                        <div className='left_des'>
                                            <span>Competitive Rates</span>
                                            <p>We have competitive prices with excellent results. Professional Cleaners at affordable prices.
                                            </p>
                                        </div>
                                    </motion.div>
                                    <motion.div
                                        variants={textVariants('left', 0.3)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        className='left_content'>
                                        <img src={Reliability} />
                                        <div className='left_des'>
                                            <span>Reliability</span>
                                            <p>Our clients are our business therefore Quality, reliability and punctuality are a MUST.
                                            </p>
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Our Features">
                            <div className='tab_content row' >
                                <div className='center_imgDiv col-lg-4 col-md-4 col-sm-12 col-12'>
                                    <motion.img
                                        variants={textVariants('up', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        src={OurFeaturesImg} />
                                </div>
                                <div className='left_div col-lg-4 col-md-4 col-sm-12 col-12'>
                                    <motion.div
                                        variants={textVariants('left', 0.1)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        className='left_content'>
                                        <img src={LeaseCleaning} />
                                        <div className='left_des'>
                                            <span>End of Lease Cleaning Canberra</span>
                                            <p>We can provide all cleaning solutions which can be tailored to suit your needs &requirements.
                                            </p>
                                        </div>
                                    </motion.div>
                                    <motion.div
                                        variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        className='left_content'>
                                        <img src={ResidentialCleanig} />
                                        <div className='left_des'>
                                            <span>Residential Cleaning Canberra</span>
                                            <p>Proudly accredited with ISO 9001, ultimately this enable us to provide an excellent service.
                                            </p>
                                        </div>
                                    </motion.div>
                                </div>

                                <div className='left_div col-lg-4 col-md-4 col-sm-12 col-12'>
                                    <motion.div
                                        variants={textVariants('left', 0.1)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        className='left_content'>
                                        <img src={CarpetCleaning} />
                                        <div className='left_des'>
                                            <span>Carpet Cleaning Canberra</span>
                                            <p>Our cleaners are trained under Blue Cleaning's strict guidance for quality & assurance.
                                            </p>
                                        </div>
                                    </motion.div>
                                    <motion.div
                                        variants={textVariants('left', 0.2)}
                                        initial="hidden"
                                        whileInView="show"
                                        viewport={{ once: true, amount: 0.1 }}
                                        className='left_content'>
                                        <img src={OfficeCleanign} />
                                        <div className='left_des'>
                                            <span>Office Cleaning Canberra</span>
                                            <p>We have competitive prices with excellent results. Professional Cleaners at affordable prices.
                                            </p>
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="longer-tab" title="Trusted By">
                            <div className='tab_content' data-aos="fade-right" data-aos-once="true">
                                <Swiper
                                    slidesPerView={4}
                                    spaceBetween={30}
                                    // slidesPerGroup={4}
                                    loop={false}
                                    loopFillGroupWithBlank={true}
                                    // navigation={true}
                                    // modules={[Navigation]}
                                    pagination={{
                                        dynamicBullets: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"

                                    breakpoints={{
                                        63: {

                                            slidesPerView: 1,
                                        },

                                        640: {

                                            slidesPerView: 1,
                                        },

                                        768: {

                                            slidesPerView: 2,
                                        },
                                        992: {

                                            slidesPerView: 4,
                                        },

                                    }}
                                >
                                    <SwiperSlide>
                                        <div className='SliderImgDiv' >
                                            <motion.img
                                                variants={textVariants('left', 0.1)}
                                                initial="hidden"
                                                whileInView="show"
                                                viewport={{ once: true, amount: 0.1 }}
                                                className='w-100' src={SliderImg1} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='SliderImgDiv' >
                                            <motion.img
                                                variants={textVariants('left', 0.2)}
                                                initial="hidden"
                                                whileInView="show"
                                                viewport={{ once: true, amount: 0.1 }}
                                                className='w-100' src={SliderImg2} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='SliderImgDiv' >
                                            <motion.img
                                                variants={textVariants('left', 0.3)}
                                                initial="hidden"
                                                whileInView="show"
                                                viewport={{ once: true, amount: 0.1 }}
                                                className='w-100' src={SliderImg3} />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='SliderImgDiv' >
                                            <motion.img
                                                variants={textVariants('left', 0.4)}
                                                initial="hidden"
                                                whileInView="show"
                                                viewport={{ once: true, amount: 0.1 }}
                                                className='w-100' src={SliderImg4} />
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </section>

        </>
    )
}

export default WhyBlueCleaning