import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import ProcessImg2 from '../assets/Home Page Assets/Component 10  3-min.png';
import ProcessImg3 from '../assets/Home Page Assets/galleryBg.png';
import Card from 'react-bootstrap/Card';
import HTMLRenderer from 'react-html-renderer';
import { NavLink } from 'react-router-dom';
import { FaRegUser, FaRegCalendarAlt } from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner';


const SingleBlog = () => {

  const { id } = useParams();


  const [banner, setBanner] = useState([]);
  const [blog, setBlog] = useState([])
  const bannerImg = banner.map((banner) => banner.image);
  const [blogTitle, setBlogTitle] = useState('');
  const [singleBlog, setSingleBlog] = useState([]);
  const [blogContent, setBlogContent] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [seoDes, setSeoDes] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [robot, setRobot] = useState('');
  const [seoScript, setSeoScript] = useState('');
  const [seoKeyword, setSeoKeyword] = useState('');
  const [canonical, setCanonical] = useState('');
  const [loadMore, setLoadMore] = useState(3);
  const [myspinner, setMyspinner] = useState(false);

  // singleBlog

  const getAllData = () => {

    axios.get('/getBanner/4'
    ).then((response) => {
      setBanner(response.data.data);

    });

    axios.get('/getBlog'
    ).then((response) => {
      setBlog(response.data.data);
      console.log(response.data.data)
      setMyspinner(false);

    });

    axios.get(`/getSingleBlog/${id}`).then((response) => {

      if (response.status === 200) {
        // setMySpinner(false);
        console.log(response.data.data);
        setSingleBlog(response.data.data);
        setMyspinner(false);
      }
    });


  }

  const handleSEO = () => {
    singleBlog?.map((seo) => {

      setRobot(seo.robot);
      setSeoTitle(seo.seo_title);
      setSeoKeyword(seo.seo_keyword)
      setSeoDes(seo.seo_description);
      setCanonical(seo.canonical)
      setSeoScript(seo.script);
      setBlogTitle(seo.title);
      setCreatedAt(seo.created_at);
      setBlogContent(seo.content);
    })
  }

  useEffect(() => {
    handleSEO();
  }, [singleBlog]);

  useEffect(() => {
    setMyspinner(true);
    window.scrollTo(0, 0);
    getAllData();
  }, [id]);




  return (
    <>

      <Helmet>
        <link rel="canonical" href={canonical} />
        <title>{seoTitle}</title>
        <meta name="description" content={seoDes} />
        <meta name="keywords" content={seoKeyword} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content="hweindia" />
        <meta property="og:url" content={canonical} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDes} />
        <meta name="robots" content={robot} />
        <script>{seoScript}</script>

      </Helmet>


      <div className={myspinner ? 'spinner_d' : 'd-none'}>
        <Spinner animation="border" className='mySpinner' />
      </div>
      <div className='fix_width' style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>

        <section>
          <div className='banner' style={{ backgroundImage: `url(/assets/banner/${bannerImg})` }}>
            <div className='container-fluid' style={{ position: 'relative' }}>
              <div className='content_div w-100' style={{ top: '120px' }}>

                <h3 style={{ color: '#fff' }}>{blogTitle}</h3>

              </div>
            </div>
          </div>
          <div className='container-fluid blog_section'>
            <div>
              <Card className='block_card'>
                <Card.Body className="pb-4">
                  {/* <Card.Img variant="top" src={`/assets/blog/${singleblog.image}`} width='30%' /> */}
                  <div className='card_content w-100'>
                    <div className='cardHeader justify-content-end'>
                      {/* <Card.Text ><FaRegUser className='mb-1' />Admin</Card.Text> */}
                      <Card.Text ><FaRegCalendarAlt className='mb-1 _single_date' />{new Date(createdAt).toLocaleDateString('en-En', { year: 'numeric', month: 'short', day: 'numeric' })}</Card.Text>
                    </div>
                    {/* <Card.Title>{singleblog.title}</Card.Title> */}
                    <Card.Text><HTMLRenderer html={blogContent} /></Card.Text>
                    <Card.Text>

                    </Card.Text>
                    {/* <NavLink to={`/blog/${singleblog.id}`}>Continue Reading</NavLink> */}
                  </div>


                </Card.Body>
              </Card>
            </div>
          </div>
          <div className='our_blog' style={{ paddingBottom: '4rem' }}>
            <center className="mb-4" style={{ paddingTop: '7rem' }}>
              <h5 style={{ justifyContent: 'center' }}>Our Blogs</h5>
              <h3 className='title'>Latest Blogs</h3>
            </center>
            <div className='bg_imgDiv'>
              <img className='img1' src={ProcessImg3} />
              <img className='img2' src={ProcessImg2} />
            </div>

            <div className='container-fluid blogs_ row'>
              {/* <div className='col-lg-4 col-md-6 col-sm-12 col-12'> */}
              {blog?.filter(data => data.title !== blogTitle).map((blog) => {
                return (
                  <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                    <Card>
                      <Card.Img variant="top" src={`/assets/blog/${blog.image}`} />
                      <Card.Body>
                        <div className='cardHeader'>
                          <Card.Text ><FaRegUser className='mb-2' />Admin</Card.Text>
                          <Card.Text><FaRegCalendarAlt className='mb-2' />{new Date(blog.created_at).toLocaleDateString('en-En', { year: 'numeric', month: 'short', day: 'numeric' })}</Card.Text>
                        </div>
                        <Card.Title>{blog.title.substring(0, 50)}...</Card.Title>
                        <Card.Text>
                          <HTMLRenderer html={`${blog.content.substring(0, 200)}...`} />
                          {/* {blog.content.substring(0, 200)} */}
                        </Card.Text>
                        <NavLink to={`/blog/${blog.id}/${blog.title.replace(/\s+/g, '-')}`} className='continue'>Continue Reading</NavLink>
                      </Card.Body>
                    </Card>
                  </div>
                )
              })}

            </div>
            <div className='text-center learnMore'>
              <div className={blog?.length > loadMore ? 'text-center learnMore' : 'd-none'}>
                <button onClick={() => setLoadMore(loadMore + 3)}>Load More</button>
              </div>

            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default SingleBlog;