import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';



const UpdateServiceAdmin = () => {

    const { id } = useParams();

    const [service, setService] = useState([]);
    const [serviceId, setServiceId] = useState('');
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [updatedImage, setUpdatedImage] = useState('');
    const [pending, setPending] = useState(true);
    const [bannerImage, setBannerImage] = useState('');
    const [bannerUpdate, setBannerUpdate] = useState('');
    const [bannerText, setBannerText] = useState('');
    const [icon, setIcon] = useState('');
    const [updateIcon, setUpdateIcon] = useState('');
    const [canonical, setCanonical] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoFollow, setSeoFollow] = useState('');

    const navigate = useNavigate();

    const getAllData = () => {

        axios.get(`/singleService/${id}`
        ).then((response) => {
            if (response.status === 200) {
                console.log(response.data.data)
                setService(response.data.data);
                setPending(false);
            }

        }).catch((err) => {
            console.log(err);
        });

    }


    const handleSEO = () => {
        service?.map((seo) => {
            setServiceId(seo.id);
            setName(seo.name);
            setImage(seo.image);
            setIcon(seo.icon);
            setBannerImage(seo.banner_image);
            setBannerText(seo.banner_text);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keywords)
            setSeoDescription(seo.seo_description);
            setCanonical(seo.canonical);
            setSeoScript(seo.seo_script);
            setSeoFollow(seo.seo_follow);

        })
    }

    useEffect(() => {
        handleSEO();
    }, [service]);


    useEffect(() => {
        window.scrollTo(0, 0);
        getAllData();
    }, [])


    const notify = () => toast.info("Service Updated SuccessFully", { position: "top-center" });

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        axios.post('/updateService', {
            id: serviceId,
            name: name,
            banner_text: bannerText,
            seo_title: seoTitle,
            canonical: canonical,
            seo_description: seoDescription,
            seo_keywords: seoKeyword,
            seo_follow: seoFollow,
            seo_script: seoScript,
            image: updatedImage,
            icon: updateIcon,
            banner_image: bannerUpdate,

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }

        ).then((response) => {
            if (response.status === 200) {
                notify();
                navigate('/admin/service')

            }
        })

    }

    return (
        <>
             <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className='p-3'>Update Service</span>
                            <div className='m-3'>
                                <Form className='row' onSubmit={handleSubmit(onSubmit)}>
                                    <div className='col-md-7'>
                                        <Form.Group className="col-md-7 pb-5" >
                                            <Form.Label>Service Name</Form.Label>
                                            <Form.Control type="text" placeholder='Please enter service name'
                                                value={name}
                                                {...register('name', {
                                                    required: false,
                                                    onChange: (e) => setName(e.target.value),
                                                })}
                                            />
                                            {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Service Name</span>}
                                        </Form.Group>
                                    </div>

                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Image</Form.Label>
                                        <Form.Control type="file"
                                            {...register('image', {
                                                required: false,
                                                onChange: (e) => setUpdatedImage(e.target.files[0]),
                                            })}

                                        />
                                        {errors.image && <span className='err_msg'>Please select image</span>}
                                    </Form.Group>
                                    <div className='col-lg-6 text-center'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>
                                            {updatedImage == "" ?
                                                <img src={`/assets/service/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                :
                                                <img src={URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                            }
                                        </div>
                                    </div>

                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Icon</Form.Label>
                                        <Form.Control type="file"
                                            {...register('icon', {
                                                required: false,
                                                onChange: (e) => setUpdateIcon(e.target.files[0]),
                                            })}

                                        />
                                        {errors.icon && <span className='err_msg'>Please select icon </span>}
                                    </Form.Group>
                                    <div className='col-lg-6 text-center'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>
                                            {updateIcon == "" ?
                                                <img className='bg-dark' src={`assets/subservice/${icon}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                :
                                                <img className='bg-dark' src={URL.createObjectURL(updateIcon)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                            }

                                        </div>
                                    </div>

                                    <Form.Group className="col-md-7 pb-5" >
                                        <Form.Label>Banner Text</Form.Label>
                                        <Form.Control type="text"
                                            value={bannerText}

                                            {...register('bannerText', {
                                                required: false,
                                                onChange: (e) => setBannerText(e.target.value),
                                            })}

                                        />
                                        {errors.bannerText && <span className='err_msg'>Please enter Banner Text </span>}
                                    </Form.Group>

                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Banner Image</Form.Label>
                                        <Form.Control type="file"
                                            {...register('bannerImage', {
                                                required: false,
                                                onChange: (e) => setBannerUpdate(e.target.files[0]),
                                            })}


                                        />
                                        {errors.bannerImage && <span className='err_msg'>Please select Banner Image </span>}
                                    </Form.Group>
                                    <div className='col-lg-6 text-center'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>
                                            {bannerUpdate == "" ?
                                                <img src={`assets/subservice/${bannerImage}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                :
                                                <img src={URL.createObjectURL(bannerUpdate)} style={{ maxWidth: "300px", maxHeight: "300px" }} />}
                                        </div>
                                    </div>

                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>Canonical</Form.Label>
                                        <Form.Control type="text"

                                            value={canonical}
                                            {...register('seoCanonical', {
                                                required: false,
                                                onChange: (e) => setCanonical(e.target.value),
                                            })}

                                        />

                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5" >
                                        <Form.Label>SEO Title</Form.Label>
                                        <Form.Control type="text"
                                            value={seoTitle}
                                            {...register('seoTitle', {
                                                required: false,
                                                onChange: (e) => setSeoTitle(e.target.value),
                                            })}

                                        />
                                        {errors.seoTitle && <span className='err_msg'>Please enter Seo Title </span>}
                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5" >
                                        <Form.Label>SEO Keywords</Form.Label>
                                        <Form.Control type="text"
                                            value={seoKeyword}
                                            {...register('seoSeoKeyword', {
                                                required: false,
                                                onChange: (e) => setSeoKeyword(e.target.value),
                                            })}
                                        // onChange={(e) => setSeoKeyword(e.target.value)}

                                        />
                                    </Form.Group>
                                    <Form.Group className=" col-md-6 pb-5">
                                        <Form.Label>SEO Follow</Form.Label>
                                        <Form.Select value={seoFollow} onChange={(e) => setSeoFollow(e.target.value)}>
                                            <option value="1">Index Follow</option>
                                            <option value="2">Index No-follow</option>
                                            <option value="3">No-index Follow</option>
                                            <option value="4">No-index No-follow</option>
                                        </Form.Select>

                                    </Form.Group>
                                    <Form.Group className="col-md-6 pb-5">
                                        <Form.Label>SEO Script</Form.Label>
                                        <Form.Control type="text"
                                            value={seoScript}
                                            onChange={(e) => setSeoScript(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group className="col-md-12 pb-5">
                                        <Form.Label>SEO Description</Form.Label>
                                        <Form.Control as="textarea" rows={3}
                                            value={seoDescription}
                                            onChange={(e) => setSeoDescription(e.target.value)}
                                        />
                                    </Form.Group>

                                    <div className='text-center'>
                                        <Button className='Add_btn_' type='submit'>
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </div>


                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default UpdateServiceAdmin