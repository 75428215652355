import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { BiMessageDetail } from 'react-icons/bi';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';
import axios from 'axios';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { motion } from 'framer-motion';
import { fixedBtn } from './motion/motion';


const GetAQuote = () => {



    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [service, setService] = useState([]);
    const [msg, setMsg] = useState('');


    const getData = () => {
        axios.get('/allsubservice').then((response) => {
            setService(response.data.data)
            console.log(response.data.data)
        })
    }


    useEffect(() => {
        getData();
    }, []);

    const handleClose = () => {
        setShow(false);
        reset({
            name: "",
            phone: "",
            email: "",
            message: "",

        });
    };
    const handleShow = () => setShow(true);


    const submitSuccess = () => toast.success("From Submit Successfully!",
        { position: "top-center", }
    );

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = () => {
        axios.post('/enquiry', { name: name, email: email, sub_service_id: serviceId, message: msg, phone: phone, }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                submitSuccess();
                setName('');
                setPhone('');
                setEmail('');
                resetSelectField();
                setMsg('');
                setServiceId('')
                handleClose();
            }

        })

    }

    const onSelect = (e) => {
        let subServiceId = e.map((id => id.id)).toString();
        setServiceId(subServiceId)
    }

    const onRemove = (e) => {
        let subServiceId = e.map((id => id.id)).toString();
        setServiceId(subServiceId)
    }

    const multiselectRef = useRef();

    const resetSelectField = () => {
        multiselectRef.current.resetSelectedValues();
    };


    return (
        <>
            {/* <OverlayTrigger
                key="left"
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-"left"`}>
                        Get A Free Quote
                    </Tooltip>
                }
            > */}
                <motion.Button
                    // variants={fixedBtn}
                    // initial="hidden"
                    // animate="show"
                    onClick={handleShow}
                    // viewport={{ once: false, amount: 0.7 }}
                    style={{ borderRadius: '0px', top: '220px', right: '-34px', transition: 'auto' }}
                    className='scrollTop show_btn QUICK_QUOTE' >
                    QUICK QUOTE
                    {/* <BiMessageDetail /> */}
                </motion.Button>
            {/* </OverlayTrigger> */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Get A Free Quote</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='contact_div'>
                        <Form className='row' onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter name" value={name}
                                    {...register('name', {
                                        required: true, maxLength: 30,
                                        onChange: (e) => setName(e.target.value),
                                    })}
                                />
                                {errors.name && errors.name.type === "required" && <span className='err_msg'>Please Enter Your Name</span>}
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="number" placeholder="Enter Phone Number" maxLength={13}
                                    {...register('phone', {
                                        required: true,
                                        maxLength: 15,
                                        minLength: 10,
                                        required: "Please Enter Your Phone Number",
                                        onChange: (e) => setPhone(e.target.value),
                                    })}
                                    value={phone}
                                />
                               {errors.phone && errors.phone.type === "maxLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                               {errors.phone && errors.phone.type === "minLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                               {errors.phone && errors.phone.type === "required" && <span className='err_msg'>Please Enter Your Phone Number</span>}
                            </Form.Group>
                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <Form.Label>Email Id</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={email}
                                    {...register('email', {
                                        required: true, maxLength: 30,
                                        onChange: (e) => setEmail(e.target.value),
                                    })}
                                />
                                {errors.email && errors.email.type === "required" && <span className='err_msg'>Please Enter email Address</span>}
                            </Form.Group>
                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <Form.Label>Cleaning Requirements</Form.Label>

                                <Multiselect
                                    className='multi_services'
                                    displayValue="name"
                                    hidePlaceholder={true}
                                    onKeyPressFn={function noRefCheck() { }}
                                    onRemove={onRemove}
                                    onSearch={function noRefCheck() { }}
                                    onSelect={onSelect}
                                    options={service}
                                    ref={multiselectRef}

                                />

                            </Form.Group>
                            <Form.Group className="col-12 mb-3">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Enter Message"
                                    value={msg}
                                    {...register('message', {
                                        required: true,
                                        onChange: (e) => setMsg(e.target.value)
                                    })}
                                />
                                {errors.message && errors.message.type === "required" && <span className='err_msg'>Please Enter Your Message</span>}
                            </Form.Group>
                            <div className=''>
                                <Button className='appointment_btn_yellow m-auto'
                                    type='submit'
                                ><span>Send Enquiry</span></Button>
                            </div>
                            {/* <img src={SendImg} style={{ width: '25px' }} /> */}

                        </Form>
                    </div>
                </Modal.Body>

            </Modal>


        </>
    )
}

export default GetAQuote