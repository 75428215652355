import React, { useEffect, useState } from 'react';
import axios from 'axios';
import WhyBlueCleaning from '../common/WhyBlueCleaning';
import { Button } from '@mui/material';
import bookAppoinment2 from '../assets/Home Page Assets/Book Appointment-min.png';
import { FaStar, FaStarHalf } from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import HTMLRenderer from 'react-html-renderer';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import Rating from '@mui/material/Rating';
import { motion } from 'framer-motion';
import { fixedContainer, textVariants } from '../common/motion/motion';
import AboutImage from '../assets/Home Page Assets/Blue Cleaning Group -proof-v4..jpg'


const About = () => {

  const [banner, setBanner] = useState([]);
  const bannerImg = banner.map((banner) => banner.image)
  const bannerContent = banner.map((banner) => banner.content);
  const [about, setAbout] = useState([]);
  const [myspinner, setMyspinner] = useState(false);
  const [seoContent, setSeoContent] = useState([]);
  const [seoDes, setSeoDes] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [robot, setRobot] = useState('');
  const [seoScript, setSeoScript] = useState('');
  const [seoKeyword, setSeoKeyword] = useState('');
  const [canonical, setCanonical] = useState('');

  const aboutContent = about.map((about) => about.content);

  const getAllData = () => {

    axios.get('/getSeo/2').then((response) => {
      setSeoContent(response.data.data);
      console.log(response.data.data);
    }).catch((err) => {
      console.log(err);
    })

    axios.get('/getBanner/2'
    ).then((response) => {
      if (response.status === 200) {
        setBanner(response.data.data);
        setMyspinner(false);
      }
    });

    axios.get('/getAbout'
    ).then((response) => {
      setAbout(response.data.data);
      console.log(response.data.data)
    });

  }

  const handleSEO = () => {
    seoContent?.map((seo) => {

      setRobot(seo.robot);
      setSeoTitle(seo.seo_title);
      setSeoKeyword(seo.seo_keyword)
      setSeoDes(seo.seo_description);
      setCanonical(seo.canonical)
      setSeoScript(seo.script);
    })
  }

  useEffect(() => {
    handleSEO();
  }, [seoContent]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllData();
    setMyspinner(true);
  }, []);


  useEffect(() => {
    Aos.init({
      duration: 1000,
    })
  }, []);

  return (
    <>

      <Helmet>
        <link rel="canonical" href={canonical} />
        <title>{seoTitle}</title>
        <meta name="description" content={seoDes} />
        <meta name="keywords" content={seoKeyword} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content="hweindia" />
        <meta property="og:url" content={canonical} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDes} />
        <meta name="robots" content={robot} />
        <script>{seoScript}</script>

      </Helmet>

      <div className={myspinner ? 'spinner_d' : 'd-none'}>
        <Spinner animation="border" className='mySpinner' />
      </div>
      <div className='fix_width' style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>

        <div className='container-fluid banner'>
          <div className='row' style={{ paddingTop: "3rem" }}>
            <div className='content_div col-lg-6 col-md-12 col-sm-12 col-12'>
              <motion.Button
                variants={textVariants('up', 0.1)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                className='strar-btn mb-3' >
                <a href="https://goo.gl/maps/zLHxYWCKEEj8oM8s7" target="_blank">
                  <div className='starDiv'>
                    <span>4.8</span>
                    <Rating
                      name="hover-feedback"
                      defaultValue={4.5}
                      precision={0.5}
                      readOnly

                    />
                  </div>
                  from 200 Client's reviews</a></motion.Button>
              <motion.h3
                variants={textVariants('up', 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
              >{bannerContent}</motion.h3>
              <motion.Button
                variants={textVariants('up', 0.3)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                className='btn appointment_btn_yellow' style={{ padding: '10px 20px', borderRadius: '0' }}><Link to='/contactus'>

                  Book Appointment</Link></motion.Button>
            </div>
            <div className='serviceImgDiv col-lg-6 col-md-12 col-sm-12 col-12' data-aos="fade-up" data-aos-once="true">
              <motion.img
                variants={textVariants('down', 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                src={`/assets/banner/${bannerImg}`} />
            </div>
          </div>

        </div>

        <section className='container company-s' style={{ marginTop: "3rem" }}>
          <div className='container-fluid our-company' data-aos="fade-up" data-aos-once="true">
            {/* <h5 style={{ maxWidth: '200px' }} >Our Company</h5> */}
            <div className='d-flex m-auto'>
              <motion.div
                variants={textVariants('up', 0.1)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                className='pb-4'>
                {/* <h3 className='title'>About Our Company</h3> */}
                {about.map((about) => {
                  return (
                    <HTMLRenderer html={about.content} />
                  )
                })}
              </motion.div>
            </div>
            <center>
              <motion.div
                variants={textVariants('up', 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                className='blog_button'>
                <Link
                  to='/contactus' style={{ textDecoration: 'none' }} >
                  <Button className='appoinment-btn btn'>Get A Free Quote</Button>
                </Link>
              </motion.div>
            </center>
          </div>
        </section>

        <section className='about_image-s pb-0'>
          <div className='w-100'>
            <motion.img
              variants={textVariants('up', 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              src={AboutImage} className='w-100' />
          </div>
        </section>

        <WhyBlueCleaning />

      </div>
    </>
  )
}

export default About