import React from 'react';
import Table from 'react-bootstrap/Table';
import { RiCalendar2Fill } from 'react-icons/ri';
import { AiOutlineClockCircle } from 'react-icons/ai'
import { TfiAngleRight } from 'react-icons/tfi';
import { Ri24HoursFill } from 'react-icons/ri';
import { motion, AnimatePresence } from 'framer-motion';
import { textVariants } from './motion/motion';


const BookingSummery = ({ countedData, updatedData, setTotal, setSubTotal, initialPrice, minHour, addHourPrice, gstPer }) => {

    let sum = initialPrice ? parseInt(initialPrice) : 0;
    {
        minHour ?
            updatedData?.map(hour => hour.hour).forEach(item => {
                sum += item * parseInt(addHourPrice)
                setSubTotal(sum);
            })
            : <></>
    }



    countedData?.map(item => item.count ? item.price * item.count : item.price * 1).forEach(item => {
        sum += item;
        setSubTotal(sum);
    })


    let addHour = updatedData?.map((data) => data.hour).toString();
    // console.log(addHour)
    let actualSum = (sum * parseInt(gstPer)) / 100 + sum;
    setTotal(actualSum);



    // let gst1 = actualSum - sum
    // Math.round(gst1);
    return (
        <>
            <div className='rightDiv card p-3' style={{ background: '#dff1ff' }}>
                <h3>Booking Summary</h3>

                <hr></hr>

                <div className='booking_table'>
                    <Table borderless>
                        <thead className='text-center'>
                            <tr>
                                <th colSpan={2}>Type</th>
                                <th colSpan={1}>Qty</th>
                                <th colSpan={1}>Price</th>
                            </tr>
                        </thead>
                        <tbody>


                            {countedData?.map((item, index) => {

                                return (
                                    <>
                                        <motion.tr
                                            variants={textVariants('left', index / 10)}
                                            initial="hidden"
                                            whileInView="show"

                                            viewport={{ once: true, amount: 0.1 }}
                                        >
                                            <td colSpan={2}><li style={{ listStyleType: 'disclosure-closed' }}><span style={{ fontWeight: 'normal' }}>{item.service}</span></li></td>
                                            <td className='text-center _uniq_c' colSpan={1}>{(item.combQty_1) && (item.combQty_2) ? item.combQty_1 + item.combQty_2 : item.count}</td>
                                            <td className='text-center _uniq_c' colSpan={1}>${!item.count ? item.price : item.price * item.count}</td>
                                        </motion.tr>
                                    </>

                                )
                            })}

                            {minHour ?
                                <>
                                    {updatedData.map((data, index) => {
                                        return (
                                            <>
                                                <motion.tr
                                                    variants={textVariants('left', index / 10)}
                                                    initial="hidden"
                                                    whileInView="show"
                                                    // animate={"show"}
                                                    exit={"hidden"}
                                                    viewport={{ once: true, amount: 0.1 }}
                                                    style={data.hour == '' ? { display: 'none' } : null}
                                                >
                                                    <td colSpan={2}><li style={{ listStyleType: 'disclosure-closed' }}><span style={{ fontWeight: 'normal' }}>Additional Hour</span></li></td>
                                                    <td className='text-center _uniq_c' colSpan={1}>{data.hour}</td>
                                                    <td className='text-center _uniq_c' colSpan={1}>{data.hour * parseInt(addHourPrice)}</td>
                                                </motion.tr>
                                            </>
                                        )
                                    })}
                                </>
                                : null}



                        </tbody>
                    </Table>

                    {updatedData?.map((data, index) => {
                        return (
                            <div className='date_time'>

                                <motion.div
                                    variants={textVariants('left', index / 10)}
                                    initial="hidden"
                                    whileInView="show"

                                    exit={"hidden"}
                                    viewport={{ once: true, amount: 0.1 }}
                                    className={data.startDate == '' ? 'd-none' : 'date_'}>
                                    <RiCalendar2Fill className='_calendar_icon' />
                                    <h4>{data.startDate}</h4>
                                </motion.div>
                                <motion.div
                                    variants={textVariants('left', index / 10)}
                                    initial="hidden"
                                    whileInView="show"

                                    exit={"hidden"}
                                    viewport={{ once: true, amount: 0.1 }}
                                    className={data.time == '' ? 'd-none' : 'time_'}>
                                    <AiOutlineClockCircle className='_calendar_icon' /><h4>{data.time}</h4>
                                </motion.div>

                                <motion.div
                                    variants={textVariants('left', index / 10)}
                                    initial="hidden"
                                    whileInView="show"

                                    exit={"hidden"}
                                    viewport={{ once: true, amount: 0.1 }}
                                    className={data.hour == '' ? 'd-none' : 'time_'}>
                                    <Ri24HoursFill className={minHour ? 'd-none' : 'd-block _calendar_icon'} /><h4 className={minHour ? 'd-none' : 'd-block'}>({data.hour} hours)</h4>
                                </motion.div>

                            </div>
                        )
                    })}


                </div>
                <hr></hr>
                {initialPrice > 0 &&
                    <div className='totalAmont pb-2'>
                        <h6 className='d-flex align-items-center' style={{ gap: "5px" }}>Initial Price <h6 className='m-0 ml-1' style={{ fontSize: '16px' }}>{minHour ? `(${minHour} Hour)` : ''}</h6></h6>
                        <h6>${initialPrice}</h6>
                    </div>}
                {minHour &&
                    <div className='totalAmont pb-2'>
                        <h6 className='d-flex align-items-center' style={{ gap: "5px" }}>Total Hours <h6 className='m-0 ml-1' style={{ fontSize: '16px' }}></h6></h6>
                        <h6>{minHour ? `${addHour != '' ? parseInt(addHour) + parseInt(minHour) : parseInt(minHour)} Hour` : ''}</h6>
                        {console.log(addHour, minHour)}
                    </div>}
                {/* sum */}
                <div className='totalAmont pb-2'>
                    <h6 className='d-flex align-items-center' style={{ gap: "5px" }}>Sub Total</h6>
                    <h6>${sum}</h6>
                </div>
                <div className='totalAmont pb-2'>
                    <h6 className='d-flex align-items-center' style={{ gap: "5px" }}>GST{`(${gstPer}%)`}</h6>
                    <h6>${(actualSum - sum).toFixed(2)}</h6>
                </div>
                <div className='totalAmont pb-2'>
                    <h6 className='d-flex align-items-center' style={{ gap: "5px" }}>Total Amount {gstPer > 0 ? <h6 className='m-0 ml-1' style={{ fontSize: '16px' }}> (Incl. GST)</h6> : null}</h6>
                    <h6>${actualSum}</h6>
                </div>
                <div className='footerContnt pb-2'>
                    <span>

                        <ul style={{ marginTop: '10px', marginLeft: '20px', listStyleType: 'disclosure-closed' }}>
                            <li>

                                Surcharge will be applied for properties with NO lift access $20 per Level.
                            </li>
                            <li>

                                Stain Removal will be additional cost. However we can not guarantee stains may
                                come off completely.
                            </li>
                            <li>

                                It is responsibility of the customer to give us much as
                                information to Blue Cleaning Team in order to treat stains successfully.
                            </li>
                        </ul>
                    </span>
                </div>


            </div>

        </>
    )
}

export default BookingSummery