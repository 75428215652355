import React, { useState, useEffect } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import axios from 'axios';


const CombinationCounter = ({ id, combId, handleCombCount, serviceComb1, serviceComb2, countedData }) => {

    let count1 = countedData?.filter(data => data.id == id).map(data => data.combQty_1).toString();
    let count2 = countedData?.filter(data => data.id == id).map(data => data.combQty_2).toString();
    let qty1Value = count1 ? parseInt(count1) : 1
    let qty2Value = count2 ? parseInt(count2) : 1
    const [qty1, setQty1] = useState(qty1Value);
    const [qty2, setQty2] = useState(qty2Value);
    const [prevPrice, setPrevPrice] = useState('');


    const getCombPrice = (qty_1, qty_2) => {
        axios.get(`/subServiceComb/${combId}/${qty_1}/${qty_2}`
        ).then((response) => {
            if (response.status === 200) {
                if (response.data.data.length > 0) {
                    let newPrice = parseInt(response.data.data[0].price);
                    setPrevPrice(newPrice);
                    handleCombCount(id, serviceComb1, serviceComb2, newPrice, 1, qty_1, qty_2);
                } else {
                    handleCombCount(id, serviceComb1, serviceComb2, prevPrice, 1, qty_1, qty_2);
                    setQty1(qty_1);
                    setQty2(qty_2)
                }
            }
        });
    }

    useEffect(() => {
        getCombPrice(qty1, qty2);
    }, [qty1, qty2])



    // const handleCombPrice = (qty_1, qty_2) => {

    // }
    // var i = qty1;
    // var j = 1
    // for (i; i <= 5; i++) {
    //     for (j; j <= i; j++) {
    //         console.log(i, j);
    //     }
    // }

    const incrementQty1 = () => {
        let newQty1Count = qty1 + 1;
        // handleCombPrice(newQty1Count, qty2)
        setQty1(newQty1Count)
    }

    const decrementQty1 = () => {
        let newQty1Count = qty1 > 1 ? qty1 - 1 : 1
        // handleCombPrice(newQty1Count, qty2)
        setQty2(1);
        setQty1(newQty1Count)
    }

    const incrementQty2 = () => {
        let newQty2Count = qty2 + 1;
        // handleCombPrice(qty1, newQty2Count)
        setQty2(newQty2Count)
    }

    const decrementQty2 = () => {
        let newQty2Count = qty2 > 1 ? qty2 - 1 : 1
        // handleCombPrice(qty1, qty2)
        setQty2(newQty2Count)
    }


    return (
        <>
            <li className={'col-lg-6 col-md-6 col-sm-12 col-12'}>
                <div className='btn_div _counter_btn d-flex'>
                    <Button onClick={qty1 == 1 ? null : decrementQty1} className={qty1 == 1 ? "disabled" : ""} ><FaMinus /></Button>
                    <span
                    // style={{height : '38px'}}
                    >{serviceComb1} {`(${qty1})`}</span>
                    <Button onClick={qty1 == 5 ? null : incrementQty1} className={qty1 == 5 ? "disabled" : ""} ><FaPlus /></Button>
                </div>
            </li>
            <li className={'col-lg-6 col-md-6 col-sm-12 col-12'}>
                <div className='btn_div _counter_btn d-flex'>
                    <Button onClick={(qty2 == 1) ? null : decrementQty2} className={(qty2 == 1) ? "disabled" : ""} ><FaMinus /></Button>
                    <span
                    // style={{height : '38px'}}
                    >{serviceComb2} {`(${qty2})`}</span>
                    <Button onClick={(qty2 == qty1) ? null : incrementQty2} className={(qty2 == qty1) ? "disabled" : ""} ><FaPlus /></Button>
                </div>
            </li>
        </>
    )
}

export default CombinationCounter