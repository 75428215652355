import React, { useRef, useEffect } from 'react';
import selectDateTimeImg from '../assets/Home Page Assets/Select a date and time-min.png';
import payAndbookImg from '../assets/Home Page Assets/Pay and confirm your booking-min.png';
import getJobDoneImg from '../assets/Home Page Assets/Get the job done right-min.png';
import { motion } from 'framer-motion';
import { fixedContainer, textVariants } from './motion/motion';



const OurProcess = () => {

  const vidRef = useRef();

  useEffect(() => { vidRef.current.play(); }, []);

  return (
    <>
      <section className='our_process-s' style={{ position: 'relative' }}>

        <video src="https://bcg.giwdevelopment.com/assets/video/howitworks1.mp4" autoPlay={true} preload="auto" ref={vidRef} autoBuffer autoLoop loop muted id="video_">

        </video>

        <div className='our_process' style={{ position: 'relative' }}>
          
          <motion.center
            variants={fixedContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            className="mb-4">
            <motion.h5
              variants={textVariants('up', 0.1)}
              style={{ maxWidth: '180px' }}>Our Process</motion.h5>
            <motion.h3
              variants={textVariants('up', 0.2)}
              className='title'>How It Works</motion.h3>
          </motion.center>
          <div className="our_process_imgDiv">
           
            <section className='container'>
              <center>
                <motion.div
                  variants={fixedContainer}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  class="line-in-middle">
                  <div className='row steps_div'>
                    <motion.div
                      variants={fixedContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      className='col-5'>
                      <motion.p
                        variants={textVariants('up', 0.1)}
                      >Blue Cleaning fits into your busy schedule.<br></br>
                        Simply choose the time and date for your clean & we'll do the rest!
                      </motion.p>
                    </motion.div>
                    <motion.div
                      variants={fixedContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      className='col-2'>
                      <motion.img
                        variants={textVariants('up', 0.1)}
                        src={selectDateTimeImg} />
                    </motion.div>
                    <motion.div
                      variants={fixedContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      className='col-5'>
                      <motion.h3
                        variants={textVariants('up', 0.1)}
                      >Select a date and time</motion.h3>
                    </motion.div>
                  </div>
                  <div className='row steps_div'>
                    <motion.div
                      variants={fixedContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      className='col-5'>
                      <motion.h3 variants={textVariants('up', 0.3)}>Pay and confirm your booking</motion.h3>
                    </motion.div>
                    <motion.div
                      variants={fixedContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      className='col-2'>
                      <motion.img variants={textVariants('up', 0.2)} src={payAndbookImg} />
                    </motion.div>
                    <motion.div
                      variants={fixedContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      className='col-5'>
                      <motion.p
                        variants={textVariants('up', 0.2)}>Blue Cleaning's easy on-demand booking platform means we can give you
                        an upfront price and confirm your service instantly.</motion.p>
                    </motion.div>
                  </div>
                  <div className='row steps_div'>
                    <motion.div
                      variants={fixedContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      className='col-5'>
                      <motion.p
                        variants={textVariants('up', 0.3)}>No more missed appointments, or calling your cleaner wondering
                        if they'll show up.</motion.p>
                    </motion.div>
                    <motion.div
                      variants={fixedContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      className='col-2'>
                      <motion.img variants={textVariants('up', 0.3)} src={getJobDoneImg} />
                    </motion.div>
                    <motion.div
                      variants={fixedContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      className='col-5'>
                      <motion.h3 variants={textVariants('up', 0.3)}>Get the job done right!</motion.h3>
                    </motion.div>
                  </div>
                </motion.div>
              </center>
            </section>
          </div>
        </div >

      </section >



    </>
  )
}

export default OurProcess