import React,{Suspense} from 'react';
import { Outlet } from 'react-router-dom';
// import Footer from './Footer/Footer';
import Header from './Header/Header';
import ScrollToTop from './common/ScrollToTop';
import GetAQuote from './common/GetAQuote';
const Footer = React.lazy(() => import("./Footer/Footer"));

const Frontend = () => {

    let auth = localStorage.getItem('auth1');

    

    return (
        <>

            <Header />
            <Outlet />
            <Suspense fallback={<div>Loading</div>}>
                <Footer />
            </Suspense>

            <GetAQuote />
            <ScrollToTop />

        </>

    )
}

export default Frontend