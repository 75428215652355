import React, { useState, useEffect, useRef, memo } from 'react';
import axios from 'axios';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import { Button, Col, Form } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import BookingSummery from './BookingSummery';
import { FaMinus, FaPlus, FaCheck } from 'react-icons/fa';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Spinner } from 'react-bootstrap';
import { useForm, useFormContext, FormProvider, Controller } from "react-hook-form";
import Counter from './staps/Counter';
import SingleService from './staps/SingleService';
import { ToastContainer, toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';
import Success from './staps/Success';
import ExtraServices from './staps/ExtraServices';
import Multiselect from 'multiselect-react-dropdown';
import Card from 'react-bootstrap/Card';
import paypal from '../assets/Assets/paypal.png';
import payLater from '../assets/Assets/payLater.png';
import { motion } from 'framer-motion';
import { textVariants } from './motion/motion';
import Paypal from './Paypal/Paypal';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import weekly from '../assets/Assets/week.png';
import AgedCare from '../assets/Assets/old-age.png';
import moveOut from '../assets/Assets/house.png';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { MdOutlineBedroomChild, MdOutlineBathtub, MdOutlineCleanHands } from 'react-icons/md';
import CombinationCounter from './staps/CombinationCounter';

// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";



const Steppers = ({ ids, extra, initialPrice, minPrice, minHour, addHourPrice, type, initialHourPrice, advanceType, advanceAmount, isRegularClean, isPropertyType, gstPer, specialService }) => {

    let actualPrice = minHour ? initialHourPrice : initialPrice

    const steps = ['Property Details', 'Booking Details', 'Personal Details'];
    const [activeStep, setActiveStep] = useState(0);
    const [homeDetails, setHomeDetails] = useState([]);
    const [countedData, setCountedData] = useState([]);

    // const [carpetData, setCarpetData] = useState([]);
    const [extras, setExtras] = useState([]);
    // const [endLimit, setEndLimit] = useState('');
    // const [endDate, setEndDate] = useState('');
    const [time, setTime] = useState('');
    const [hour, setHour] = useState(0);
    const [regularClean, setRegularClean] = useState('');
    const [confirmation, setConfirmation] = useState('Yes');
    const [spacialInstructions, setSpacialInstructions] = useState('');
    const [serviceDay, setServiceDay] = useState('');
    const [name, setName] = useState('');
    const [subServiceType, setSubServiceType] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [postcode, setPostcode] = useState('');
    const [message, setMessage] = useState('');
    const [total, setTotal] = useState(actualPrice);
    const [subTotal, setSubTotal] = useState(actualPrice);
    const [paymentMethod, setPaymentMethod] = useState(advanceType == 2 ? 1 : 1);
    const [bedrooms, setBedrooms] = useState('');
    const [bathrooms, setBathrooms] = useState('');
    const [handleDisable, setHandleDisable] = useState(false);
    const [profile, setProfile] = useState([]);
    const [transactionId, setTransactionId] = useState('');
    const [partialPay, setPartialPay] = useState(null);
    const [paypalAuth, setPaypalAuth] = useState('');
    const [myspinner, setMyspinner] = useState(false);
    const [propertyType, setPropertyType] = useState('');
    // const [additionalPrice, setAdditionalPrice] = useState(addHourPrice);
    const [subServiceData, setSubServiceData] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    const [allRoomData, setAllRoomData] = useState('');
    const [kitchenData, setKitchenData] = useState('');
    const [livingData, setLivingData] = useState('');
    const [laundryData, setLaundryData] = useState('');
    const [selectedRoom, setSelectedRoom] = useState([]);
    const [selectedKitchen, setSelectedKitchen] = useState([]);
    const [selectedLiving, setSelectedLiving] = useState([]);
    const [selectedLaundry, setSelectedLaundry] = useState([]);



    const [updatedData, setUpdatedData] = useState([
        {
            startDate: '',
            // endDate: '',
            time: '',
            hour: '',
            regularClean: '',
            confirmation: '',
            serviceDay: '',
            instruction: '',
        }
    ]);

    const [specificData, setSpecificData] = useState([
        {
            allRoom: '',
            kitchen: '',
            living: '',
            laundry: '',
        }
    ])


    let serviceListIds = countedData?.map(id => id.id).toString();
    let quantity = countedData?.map(count => count.count).toString();

    let bed_room = countedData?.map(count => count.combQty_1).toString();

    let bath_room = countedData?.map(count => count.combQty_2).toString();

    const navigate = useNavigate();

    const stepOneErr = () => toast.error(`Booking price must be greater than ${minPrice}`, { position: "top-center", });
    const stepTwoErr = () => toast.error("Please fill out all required fields.", { position: "top-center", });

    const methods = useForm();

    const getData = () => {
        axios.get(`/subServiceList/${ids}`
        ).then((response) => {
            setHomeDetails(response.data.data);
            console.log(response.data.data)

        });
        axios.get(`/getAllExtras`
        ).then((response) => {
            setExtras(response.data.data);
            console.log(response.data.data)
        });

        axios.get("/allSetting").then((response) => {
            let paypal_client_id = response.data.data[0].paypal_client_id
            setPaypalAuth(paypal_client_id);

        }).catch((err) => {
            console.log(err);
        })

    }

    useEffect(() => {
        getData();

    }, [ids]);

    // console.log(total*10/100)

    const handleType = () => {
        if (type == 0) {
            let serviceType = 'Fixed';
            setSubServiceType(serviceType);
        } else if (type == 1) {
            setSubServiceType('');
        } else if (type == 2) {
            let serviceType = 'Hourly';
            setSubServiceType(serviceType);
        } else if (type == 3) {
            if (minHour) {
                let serviceType = 'Hourly';
                setSubServiceType(serviceType);
            } else {
                let serviceType = 'Fixed';
                setSubServiceType(serviceType);
            }
        }

    }

    useEffect(() => {
        handleType();
    }, [type])

    const handleSelected = (id) => {
        setHandleDisable(true);
        axios.get(`/subServiceList/${id}`
        ).then((response) => {
            setSubServiceData(response.data.data);
            console.log(response.data.data);
            setHandleDisable(false);
        });

    }

    const getUser = () => {
        axios.post('/getUser', { email: localStorage.getItem("auth1") }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            setProfile(response.data.data);
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        profile?.map((profile) => {
            setName(profile.name);
            setEmail(profile.email);
            setPhone(profile.phone);
            setAddress(profile.address);
            setPostcode(profile.postcode);
        })

    }, [profile]);



    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView()

    const handleNext = () => {
        if (activeStep == 0) {
            if (minPrice > total) {
                stepOneErr();
                setActiveStep(activeStep)
            } else if ((minHour) && (allRoomData == '' && kitchenData == '' && livingData == '' && laundryData == '')) {
                stepTwoErr();
                setActiveStep(activeStep)
            } else {
                setActiveStep(activeStep + 1)
                executeScroll()
            }
        } else if (activeStep == 1 && minHour) {
            if (startDate == '' || time == '' || serviceDay == '') {
                stepTwoErr();
                setActiveStep(activeStep)

            } else if (localStorage.getItem("auth1")) {
                setActiveStep(activeStep + 1);
                executeScroll();
                getUser();
                // window.location.replace("/#steps")

            } else {
                setActiveStep(activeStep + 1);
                executeScroll();

            }

        } else if (activeStep == 1 && !minHour) {
            if (startDate == '' || time == '' || hour == 0 || serviceDay == '') {
                stepTwoErr();
                setActiveStep(activeStep)


            } else if (localStorage.getItem("auth1")) {
                setActiveStep(activeStep + 1);
                executeScroll();
                getUser();
                // window.location.replace("/#steps")

            } else {
                setActiveStep(activeStep + 1);
                executeScroll();
            }
        }

    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
        executeScroll();

    }


    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = () => {
        setMyspinner(true);
        axios.post('/booking', {
            name: name,
            email: email,
            address: address,
            phone: phone,
            user_id: localStorage.getItem('userId'),
            requirement: message,
            payment_type: paymentMethod,
            sub_service_id: ids,
            sub_service_list_id: serviceListIds,
            sub_service_type: subServiceType,
            quantity: quantity,
            prop_type: propertyType,
            total_price: total,
            coupon_id: 0,
            discount_price: 0,
            final_price: total,
            start_date: startDate,
            time: time,
            postcode: postcode,
            hour: minHour ? hour + parseInt(minHour) : hour,
            clean_schedule: regularClean,
            service_days: serviceDay,
            date_confirm: confirmation,
            special_instruction: spacialInstructions,
            total_bedroom: bedrooms,
            total_bathroom: bathrooms,
            gst: gstPer,
            gst_amount: total - subTotal,
            all_rooms_opt: allRoomData,
            kitchen_opt: kitchenData,
            living_opt: livingData,
            bathroom_opt: laundryData,
            total_bedroom: bed_room,
            total_bathroom: bath_room,
            // transaction_id: tId,
            // paid_amount: paidAmount,
            // transaction_id: tId,

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                setActiveStep(activeStep + 1);
                setMyspinner(false);
                executeScroll();

                navigate(`/success/${response.data.booking_id}`);
                // setTimeout(() => navigate('/'), 3000);
            }
        }).catch((err) => {
            console.log(err);
        })

    }


    const onPay = (paidAmount, tId) => {
        setMyspinner(true);
        axios.post('/booking', {

            name: name,
            email: email,
            address: address,
            phone: phone,
            user_id: localStorage.getItem('userId'),
            requirement: message,
            payment_type: paymentMethod,
            sub_service_id: ids,
            sub_service_list_id: serviceListIds,
            sub_service_type: subServiceType,
            quantity: quantity,
            total_price: total,
            coupon_id: 0,
            discount_price: 0,
            final_price: total,
            prop_type: propertyType,
            start_date: startDate,
            time: time,
            postcode: postcode,
            hour: minHour ? hour + parseInt(minHour) : hour,
            clean_schedule: regularClean,
            service_days: serviceDay,
            date_confirm: confirmation,
            special_instruction: spacialInstructions,
            total_bedroom: bedrooms,
            total_bathroom: bathrooms,
            transaction_id: tId,
            paid_amount: paidAmount,
            gst: gstPer,
            gst_amount: total - subTotal,
            all_rooms_opt: allRoomData,
            kitchen_opt: kitchenData,
            living_opt: livingData,
            bathroom_opt: laundryData,
            total_bedroom: bed_room,
            total_bathroom: bath_room,

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                setActiveStep(activeStep + 1);
                setMyspinner(false);
                executeScroll();
                navigate(`/success/${response.data.booking_id}`);
                // setTimeout(() => navigate('/'), 3000);
            }
        }).catch((err) => {
            console.log(err);
        })

    }

    // console.log(partialPay);


    // step One 

    const handleCount = (id, service, newPrice, value) => {
        var updateCount = [...countedData];

        let Index = updateCount.findIndex((obj) => obj.id == id);

        if (Index == -1 && value != 0) {
            updateCount = [...countedData, { id: id, service: service, price: newPrice, count: value }];
        } else {
            updateCount[Index].count = value;
        }

        let removeItem = updateCount.findIndex((obj) => obj.id == id && obj.count == 0);

        if (removeItem != -1) {
            updateCount.splice(removeItem, 1);
        }

        let newData = updateCount

        setCountedData(newData);

    }

    const handleCountCarpet = (id, service, newPrice, value) => {
        setHandleDisable(true);
        var updateCount = [...countedData];


        let Index = updateCount.findIndex((obj) => obj.id == id);

        if (Index == -1 && value != 0) {
            updateCount = [...countedData, { id: id, service: service, price: newPrice, count: value }];
        } else {
            updateCount[Index].count = value;
        }

        let removeItem = updateCount.findIndex((obj) => obj.id == id && obj.count == 0);

        if (removeItem != -1) {
            updateCount.splice(removeItem, 1);
        }

        let newData = updateCount
        setCountedData(newData);

        // let dummy = [{id:'',count:''}];
        // var disabledArr = [...dummy];

        // let IndexD = disabledArr.findIndex((obj) => obj.id == id);

        // if (IndexD == -1 && value != 0) {
        //     disabledArr = [...dummy, { id: id, service: service, price: newPrice, count: value }];
        // } else {
        //     disabledArr[IndexD].count = value;
        // }

        // let removeItemD = disabledArr.findIndex((obj) => obj.id == id && obj.count == 0);

        // if (removeItemD != -1) {
        //     disabledArr.splice(removeItemD, 1);
        // }

        // if(disabledArr.length){
        //     setHandleDisable(false)

        // } else{
        //     setHandleDisable(true)

        // }

    }

    const handleCombCount = (id, service1, service2, newPrice, value, combQty_1, combQty_2) => {
        console.log(newPrice)
        let serviceCount = `(${combQty_1}) ${service1} & (${combQty_2}) ${service2}`
        var updateCount = [...countedData];

        let Index = updateCount.findIndex((obj) => obj.id == id);

        if (Index == -1) {
            updateCount = [...countedData, { id: id, service: serviceCount, price: newPrice, count: value, combQty_1: combQty_1, combQty_2: combQty_2 }];
        } else {
            updateCount[Index].price = newPrice;
            updateCount[Index].service = serviceCount;
            updateCount[Index].combQty_1 = combQty_1;
            updateCount[Index].combQty_2 = combQty_2;

        }
        // let removeItem = updateCount.findIndex((obj) => obj.id == id);

        // if (removeItem != -1) {
        //     updateCount.splice(removeItem, 1);
        // }

        let newData = updateCount

        setCountedData(newData);
    }


    const notifyRemove = () => toast.error("Please first remove Items", { position: "top-center", });

    const handleService = (id, service, price) => {
        let updatedService = [...countedData];

        let Index = updatedService.findIndex((obj) => obj.id == id);
        if (Index == -1) {
            updatedService = [...countedData, { id: id, service: service, price: price, count: 1 }];
        }
        console.log(updatedService);
        setCountedData(updatedService);


    }

    const removeService = (id) => {
        let updatedService = [...countedData];
        let Index = updatedService.findIndex((obj) => obj.id == id);

        if (Index != -1) {
            updatedService.splice(Index, 1);
        }

        let newData = updatedService;
        console.log(newData);
        setCountedData(newData);

    }

    const handleChecked = (e) => {

        let id = e.target.id;
        let price = e.target.name;
        let service = e.target.title;

        let updatedService = [...countedData];

        let Index = updatedService.findIndex((obj) => obj.id == id);

        if (e.target.checked && Index == -1) {
            updatedService = [...countedData, { id: id, service: service, price: price, count: 1 }];

            setCountedData(updatedService);

        } else if (Index != -1) {
            updatedService.splice(Index, 1);

            let newData = updatedService;
            setCountedData(newData);
        }

    }

    const homeDetail = homeDetails?.map((home) => {

        // console.log(home)

        if (home.sub_service_list_type == 0) {
            return (
                <>

                    <li className={minHour ? 'd-none' : 'col-lg-6 col-md-6 col-sm-12 col-12'}>

                        {/* <div className='detail_content'>
                            <h4 className='m-0'>{home.name}</h4>
                        </div> */}

                        <Counter countedData={countedData} handleCount={handleCount} id={home.id} service={home.name} price={home.price} />

                    </li>
                    
                </>
            )
        }
        if (home.sub_service_list_type == 1) {
            return (
                <>
                    <hr></hr>
                    <SingleService handleService={handleService} removeService={removeService} id={home.id} service={home.name} price={home.price} countedData={countedData} />
                    <hr></hr>
                </>
            )
        }
        if (home.sub_service_list_type == 2) {
            return (
                <>
                    <li className='col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center' style={{ gap: '10px' }}>
                        <div className='detail_content'>
                            <h4 className='m-0 blue_h' ><MdOutlineCleanHands className='cool_icon_' />{home.name}</h4>
                        </div>
                        <div className='d-flex _select_radio'>
                            <label class="con1"><span>Yes</span>
                                <input type="radio" name="radio2"

                                    onChange={() => handleSelected(home.selected_sub_service)}
                                />
                                <span class="checkmark"><FaCheck className='checkedIcon' /></span>
                            </label>
                            <label class="con1"><span>No</span>
                                <input type="radio" name="radio2"
                                    disabled={handleDisable ? true : false}
                                    defaultChecked
                                    onChange={() => setSubServiceData([])}
                                />
                                <span class="checkmark"><FaCheck className='checkedIcon' /></span>
                            </label>
                        </div>
                    </li>
                    <hr></hr>
                </>
            )
        }
        if (home.sub_service_list_type == 4) {
            return (
                // <li className={'col-lg-6 col-md-6 col-sm-12 col-12'}>
                <CombinationCounter countedData={countedData} handleCombCount={handleCombCount} id={home.id} combId={home.comb_id} serviceComb1={home.comb1_name} serviceComb2={home.comb2_name} price={home.price} />
                // </li>
            )
        }
    })

    const extraServices = extras?.map((home) => {
        return (
            <>

                <ExtraServices id={home.id} name={home.price} title={home.name} image={home.extra_icon} handleChecked={handleChecked} countedData={countedData} />

            </>
        )
    })

    const subServiceDetails = subServiceData?.filter(data => data.sub_service_list_type == 0).map((home) => {

        // if (home.sub_service_list_type == 0) {
        return (
            <>
                <li className='col-lg-6 col-md-6 col-sm-12 col-12'>
                    {/* <div className='detail_content'>
                        <h4 className='m-0'>{home.name}</h4>
                        <span>(&#60;12sqm)</span>
                    </div> */}
                    <Counter countedData={countedData} handleCount={handleCountCarpet} id={home.id} service={home.name} price={home.price} />
                </li>
            </>
        )
        // }
        // if (home.sub_service_list_type == 1) {
        //     return (
        //         <>
        //             <SingleService handleService={handleService} removeService={removeService} id={home.id} service={home.name} price={home.price} countedData={countedData} />
        //         </>
        //     )
        // }

    })

    const options = [
        { value: 2, label: 'Monday', },
        { value: 3, label: 'Tuesday' },
        { value: 4, label: 'Wednesday' },
        { value: 5, label: 'Thursday' },
        { value: 6, label: 'Friday' },
        { value: 7, label: 'Saturday' },

    ]


    const allRooms = [
        { value: 'Dust and clean all accessible surfaces', label: 'Dust and clean all accessible surfaces' },
        { value: 'Vacuum and mop all accessible floors', label: 'Vacuum and mop all accessible floors' },
        { value: 'Empty visible garbage bins', label: 'Empty visible garbage bins' },
        { value: 'Mirrors cleaned and polished', label: 'Mirrors cleaned and polished' },
        { value: 'Clean door handles, light switches and power points', label: 'Clean door handles, light switches and power points' },
        { value: 'Clean and polish all glass-top surfaces', label: 'Clean and polish all glass-top surfaces' },
        { value: 'Cobwebs removed', label: 'Cobwebs removed' },
        { value: 'Dust and wipe skirting boards', label: 'Dust and wipe skirting boards' },
        { value: 'Dust accessible air vents', label: 'Dust accessible air vents' },
    ]

    const Kitchen = [
        { value: 'Clean all benches and splashbacks', label: 'Clean all benches and splashbacks' },
        { value: 'Clean stove, hotplates, rangehood and chute', label: 'Clean stove, hotplates, rangehood and chute' },
        { value: 'Clean oven exterior', label: 'Clean oven exterior' },
        { value: 'Clean oven interior', label: 'Clean oven interior' },
        { value: 'Clean and polish sink and taps', label: 'Clean and polish sink and taps' },
        { value: 'Clean exterior fridge, dishwasher/appliances', label: 'Clean exterior fridge, dishwasher/appliances' },
        { value: 'Clean exterior of all cupboards, pantry and drawers', label: 'Clean exterior of all cupboards, pantry and drawers' },
        { value: ' Clean interior of all cupboards, pantry and drawers', label: ' Clean interior of all cupboards, pantry and drawers' },
    ]

    const Living = [
        { value: 'Clean exterior of all cupboards and drawers', label: 'Clean exterior of all cupboards and drawers' },
        { value: 'lean interior of all cupboards and drawers', label: 'lean interior of all cupboards and drawers' },
        { value: 'Clean and polish all mirrors incl. wardrobe doors', label: 'Clean and polish all mirrors incl. wardrobe doors' },
    ]

    const Laundry = [
        { value: 'Clean and sanitise shower screens and tiles', label: 'Clean and sanitise shower screens and tiles' },
        { value: 'Clean and sanitise toilet', label: 'Clean and sanitise toilet' },
        { value: 'Clean and sanitise bath and sink', label: 'Clean and sanitise bath and sink' },
        { value: 'Clean and polish sink and taps', label: 'Clean and polish sink and taps' },
        { value: 'Plugholes clean and free of debris', label: 'Plugholes clean and free of debris' },
        { value: 'Clean exterior of all cupboards and drawers', label: 'Clean exterior of all cupboards and drawers' },
        { value: 'Clean interior of all cupboards and drawers', label: ' Clean interior of all cupboards and drawers' },
        { value: 'Clean clothes dryer filter', label: 'Clean clothes dryer filter' },
    ]


    const animatedComponents = makeAnimated();

    let today = new Date().toISOString('en-En', { year: 'numeric', month: 'numeric', day: 'numeric' }).substring(0, 10);
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1)
    let tomorrowLim = tomorrow.toISOString('en-En', { year: 'numeric', month: 'numeric', day: 'numeric' }).substring(0, 10);

    const [startDate, setStartDate] = useState('');

    const handleDate = (today) => {
        let updated = [...updatedData];

        setStartDate(today)

        // let tomorrow = new Date(today);
        // tomorrow.setDate(tomorrow.getDate() + 1)

        // let endData = tomorrow.toISOString('en-En', { year: 'numeric', month: 'numeric', day: 'numeric' }).substring(0, 10);

        // let End = endData;
        // setEndLimit(End)
        // setEndDate(End)

        updated[0].startDate = today;
        // updated[0].endDate = End;

        setUpdatedData(updated)


    }

    // const handleEndDate = (endDate) => {
    //     let updated = [...updatedData];

    //     updated[0].endDate = endDate;

    //     setEndDate(endDate)
    //     setUpdatedData(updated)


    // }

    const handleTime = (e) => {
        let updated = [...updatedData];

        updated[0].time = e;
        setTime(e);

    }

    const increment = () => {
        let newCount = hour + 1;
        let updated = [...updatedData];

        updated[0].hour = newCount
        setHour(newCount)
        setUpdatedData(updated)

    }

    const decrement = () => {
        let updated = [...updatedData];
        let newCount = hour > 0 ? hour - 1 : 0

        updated[0].hour = newCount
        setHour(newCount)
        setUpdatedData(updated)

    }


    const regularCleans = (e) => {
        let updated = [...updatedData];

        setRegularClean(e);
        updated[0].regularClean = e;
        setUpdatedData(updated)

    }

    // const handleChange = (e) => {
    //     let updated = [...updatedData];

    //     let selectedValues = e.map(e => e.value).toString();
    //     let selectValue = selectedValues;

    //     updated[0].serviceDay = selectValue;

    //     setServiceDay(selectValue)
    //     setUpdatedData(updated)

    // }

    const onSelect = (e) => {
        let updated = [...updatedData];


        let subServiceId = e.map((value => value.label)).toString();
        let selectValue = subServiceId;

        updated[0].serviceDay = selectValue;

        setServiceDay(selectValue)
        setUpdatedData(updated)
        setSelectedValues(e);
    }

    const onRemove = (e) => {
        let updated = [...updatedData];

        let subServiceId = e.map((value => value.label)).toString();
        let selectValue = subServiceId;

        updated[0].serviceDay = selectValue;

        setServiceDay(selectValue)
        setUpdatedData(updated)
        setSelectedValues(e);
    }


    // specific services
    const onSelectAllRooms = (e) => {
        let updated = [...specificData];


        let subServiceId = e.map((value => value.label)).toString();
        let selectValue = subServiceId;

        updated[0].allRoom = selectValue;

        setAllRoomData(selectValue)
        setSpecificData(updated)
        setSelectedRoom(e);
    }

    const onRemoveAllRooms = (e) => {
        let updated = [...specificData];

        let subServiceId = e.map((value => value.label)).toString();
        let selectValue = subServiceId;

        updated[0].allRoom = selectValue;

        setAllRoomData(selectValue)
        setSpecificData(updated)
        setSelectedRoom(e);
    }

    const onSelectKitchen = (e) => {
        let updated = [...specificData];


        let subServiceId = e.map((value => value.label)).toString();
        let selectValue = subServiceId;

        updated[0].kitchen = selectValue;

        setKitchenData(selectValue)
        setSpecificData(updated)
        setSelectedKitchen(e);
    }

    const onRemoveKitchen = (e) => {
        let updated = [...specificData];

        let subServiceId = e.map((value => value.label)).toString();
        let selectValue = subServiceId;

        updated[0].kitchen = selectValue;

        setKitchenData(selectValue)
        setSpecificData(updated)
        setSelectedKitchen(e);
    }

    const onSelectLiving = (e) => {
        let updated = [...specificData];


        let subServiceId = e.map((value => value.label)).toString();
        let selectValue = subServiceId;

        updated[0].living = selectValue;

        setLivingData(selectValue)
        setSpecificData(updated)
        setSelectedLiving(e);
    }

    const onRemoveLiving = (e) => {
        let updated = [...specificData];

        let subServiceId = e.map((value => value.label)).toString();
        let selectValue = subServiceId;

        updated[0].living = selectValue;

        setLivingData(selectValue)
        setSpecificData(updated)
        setSelectedLiving(e);
    }

    const onSelectLaundry = (e) => {
        let updated = [...specificData];


        let subServiceId = e.map((value => value.label)).toString();
        let selectValue = subServiceId;

        updated[0].laundry = selectValue;

        setLaundryData(selectValue)
        setSpecificData(updated)
        setSelectedLaundry(e);
    }

    const onRemoveLaundry = (e) => {
        let updated = [...specificData];

        let subServiceId = e.map((value => value.label)).toString();
        let selectValue = subServiceId;

        updated[0].laundry = selectValue;

        setLaundryData(selectValue)
        setSpecificData(updated)
        setSelectedLaundry(e);
    }


    const handleRooms = (e) => {
        let updated = [...specificData];
        if (e.target.checked) {
            updated[0].allRoom = [...specificData[0].allRoom, { id: e.target.id, value: e.target.value }];
        }
        else {
            const removeIndex = specificData.findIndex(item => item.id == e.target.id)
            updated[0].allRoom?.splice(removeIndex, 1);
        }
        let selectValue = updated[0].allRoom?.map((value => value.value)).toString();
        // updated[0].allRoom = selectValue;
        setAllRoomData(selectValue)
        setSpecificData(updated)
        // setSelectedRoom(e);

    }

    const handleKitchen = (e) => {
        let updated = [...specificData];
        if (e.target.checked) {
            updated[0].kitchen = [...specificData[0].kitchen, { id: e.target.id, value: e.target.value }];
        }
        else {
            const removeIndex = specificData.findIndex(item => item.id == e.target.id)
            updated[0].kitchen?.splice(removeIndex, 1);
        }
        let selectValue = updated[0].kitchen?.map((value => value.value)).toString();
        // updated[0].allRoom = selectValue;
        setKitchenData(selectValue)
        setSpecificData(updated)
        // setSelectedRoom(e);
    }

    const handleLiving = (e) => {
        let updated = [...specificData];
        if (e.target.checked) {
            updated[0].living = [...specificData[0].living, { id: e.target.id, value: e.target.value }];
        }
        else {
            const removeIndex = specificData.findIndex(item => item.id == e.target.id)
            updated[0].living?.splice(removeIndex, 1);
        }
        let selectValue = updated[0].living?.map((value => value.value)).toString();

        setLivingData(selectValue)
        setSpecificData(updated)

    }

    const handleLaundry = (e) => {
        let updated = [...specificData];
        if (e.target.checked) {
            updated[0].laundry = [...specificData[0].laundry, { id: e.target.id, value: e.target.value }];
        }
        else {
            const removeIndex = specificData.findIndex(item => item.id == e.target.id)
            updated[0].laundry?.splice(removeIndex, 1);
        }
        let selectValue = updated[0].laundry?.map((value => value.value)).toString();
        setLaundryData(selectValue)
        setSpecificData(updated)
    }
























    const handleConfirm = (e) => {
        let updated = [...updatedData];

        updated[0].confirmation = e;
        setConfirmation(e);
        setUpdatedData(updated)



    }

    const handleInstruction = (e) => {
        let updated = [...updatedData];

        updated[0].instruction = e;
        setSpacialInstructions(e);
        setUpdatedData(updated)


    }



    function getStepContent(step) {

        switch (step) {
            case 0:
                return (
                    <>
                        {/* <StapOne ids={ids} /> */}

                        <section>
                            <motion.div
                                variants={textVariants('left', 0.2)}
                                initial="hidden"
                                whileInView="show"
                                animate={"show"}
                                viewport={{ once: true, amount: 0.1 }}
                                className='firstStap mt-5'>
                                <h3

                                >Property Details</h3>
                                <hr></hr>
                                <div className='leftDiv' >
                                    {/* <div>
                                            <h4>Suburb or Postcode*</h4>
                                            <div className='input_div'>
                                                <input type='text' />
                                                <Button>Get Rates</Button>
                                            </div>
                                            <ul>
                                                <li>Start typing your suburb name and & select the correct match when the dropdown appears</li>
                                            </ul>
                                        </div> */}
                                    <div className='details_'>
                                        <div>
                                            <ul className='row m-0' style={{ listStyleType: 'none' }}>

                                                {minHour ?
                                                    <>
                                                        {isRegularClean == 1 ?
                                                            <>

                                                                <li className='col-12'>
                                                                    <div className={ids != 15 ? 'd-none' : 'd-block col-12'}>
                                                                        <h4 className='mb-2'>How often would you like your regular clean?</h4>
                                                                        <div className='d-flex _select_radio justify-content-between gap-3'>
                                                                            {/* <label class="con1"
                                                                                onClick={() => regularCleans('1')}
                                                                            > */}
                                                                            {/* <span>Weekly or Fortnightly</span> */}
                                                                            {/* <input type="radio" name="radio3" value={0}
                                                                                    checked={regularClean == "1" ? true : false}
                                                                                />
                                                                                <span class="checkmark"><FaCheck className="checkedIcon" /></span> */}
                                                                            <div className='extras_d'>
                                                                                <Form.Control type="radio" id={1} name="radio3" style={{ backgroundImage: `url(${paypal})`, display: "none" }}
                                                                                    onClick={() => regularCleans('1')}
                                                                                    checked={regularClean == "1" ? true : false}
                                                                                />
                                                                                <label htmlFor={1} ><img src={weekly} /><span>Weekly / <br></br> Fortnightly</span></label>
                                                                            </div>
                                                                            <div className='extras_d'>
                                                                                <Form.Control type="radio" id={2} name="radio3" style={{ backgroundImage: `url(${paypal})`, display: "none" }}
                                                                                    onClick={() => regularCleans('2')}
                                                                                    checked={regularClean == "2" ? true : false}
                                                                                />
                                                                                <label htmlFor={2} ><img src={AgedCare} /><span>Aged Care / <br></br> Disability</span></label>
                                                                            </div>
                                                                            <div className='extras_d'>

                                                                                <Form.Control type="radio" id={3} name="radio3" style={{ backgroundImage: `url(${paypal})`, display: "none" }}
                                                                                    onClick={() => regularCleans('3')}
                                                                                    checked={regularClean == "3" ? true : false}
                                                                                />
                                                                                <label htmlFor={3} ><img src={moveOut} /><span>Move Out / <br></br> Spring Clean</span></label>
                                                                            </div>




                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <hr></hr>
                                                            </>
                                                            : null}
                                                        {isPropertyType == 1 ?
                                                            <>
                                                                <li>
                                                                    <div className='d-block col-12'>
                                                                        <h4 className='mb-2'>Property Type?</h4>
                                                                        <div className='d-flex _select_radio'>
                                                                            <label class="con1"
                                                                                onClick={() => setPropertyType(1)}
                                                                            >
                                                                                <span>House</span>
                                                                                <input type="radio" name="radio4" value={1}
                                                                                    checked={propertyType === 1 ? true : false}
                                                                                />
                                                                                <span class="checkmark"><FaCheck className="checkedIcon" /></span>
                                                                            </label>
                                                                            <label class="con1"
                                                                                onClick={() => setPropertyType(2)}
                                                                            >
                                                                                <span>Apartment</span>
                                                                                <input type="radio" name="radio4"
                                                                                    value={2}
                                                                                    checked={propertyType === 2 ? true : false}
                                                                                />
                                                                                <span class="checkmark"><FaCheck className="checkedIcon" /></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <hr></hr>
                                                            </>
                                                            : null}
                                                        {/* <h4>How Many hours will your cleaner be required?</h4> */}
                                                        <li className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                            <div className='detail_content'>
                                                                <h4 className='m-0'><AiOutlineClockCircle className='_calendar_icon' /> How Many hours will your cleaner be required? </h4>
                                                            </div>
                                                            <div className='btn_div _counter_btn d-flex _hour_'>
                                                                <Button onClick={decrement} className={hour == 0 ? "disabled" : ""} style={{ width: '20%' }}><FaMinus /></Button>
                                                                <span style={{ width: '80%' }}>{hour + parseInt(minHour)}</span>
                                                                <Button onClick={increment} style={{ width: '20%' }}><FaPlus /></Button>
                                                            </div>

                                                        </li>
                                                        <hr></hr>
                                                        <li className='flex-column col-lg-6 col-md-6 col-sm-12 col-12'>
                                                            <span style={{ fontSize: '16px', fontWeight: 'normal' }}><MdOutlineBedroomChild className='_calendar_icon' /> How many Bedrooms ?</span>
                                                            <input type='number' placeholder='Please enter Bedrooms' min={0} max={20}
                                                                value={bedrooms} onChange={(e) => setBedrooms(e.target.value)}
                                                            />
                                                        </li>
                                                        <li className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                            <span style={{ fontSize: '16px', fontWeight: 'normal' }} className=""><MdOutlineBathtub className='_calendar_icon' /> How many Bathroom ?</span>
                                                            <input type='number' placeholder='Please enter Bathroom' min={0} max={20}
                                                                value={bathrooms} onChange={(e) => setBathrooms(e.target.value)}
                                                            />
                                                        </li>

                                                        <li>

                                                        </li>

                                                    </> : <>
                                                        {isPropertyType == 1 ?
                                                            <>
                                                                <li>
                                                                    <div className='d-block col-12'>
                                                                        <h4 className='mb-2'>Property Type?</h4>
                                                                        <div className='d-flex _select_radio'>
                                                                            <label class="con1"
                                                                                onClick={() => setPropertyType(1)}
                                                                            >
                                                                                <span>House</span>
                                                                                <input type="radio" name="radio4" value={1}
                                                                                    checked={propertyType === 1 ? true : false}
                                                                                />
                                                                                <span class="checkmark"><FaCheck className="checkedIcon" /></span>
                                                                            </label>
                                                                            <label class="con1"
                                                                                onClick={() => setPropertyType(2)}
                                                                            >
                                                                                <span>Apartment</span>
                                                                                <input type="radio" name="radio4"
                                                                                    value={2}
                                                                                    checked={propertyType === 2 ? true : false}
                                                                                />
                                                                                <span class="checkmark"><FaCheck className="checkedIcon" /></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <hr></hr>
                                                            </>
                                                            : null}
                                                    </>
                                                }

                                                {homeDetail}

                                                {subServiceDetails}



                                                {specialService ? <>
                                                    <h4>Please select what do you need in your hourly package</h4>
                                                    <div className={minHour ? 'col-lg-3 col-md-6 col-sm-12 col-12' : 'col-lg-3 col-md-6 col-sm-12 col-12'}>
                                                        <h4 className='s_content_h'>All Rooms</h4>
                                                        {allRooms?.map((room, index) => {
                                                            return (
                                                                <Form.Check key={index}// prettier-ignore
                                                                    type="checkbox"
                                                                    label={room.label}
                                                                    value={room.value}
                                                                    className='pb-3'
                                                                    id={`allRooms${index}`}
                                                                    onChange={(e) => handleRooms(e)}
                                                                />
                                                            )
                                                        })}

                                                        {/* <Multiselect
                                                            className='select_multi'
                                                            displayValue="label"
                                                            hidePlaceholder={true}
                                                            selectedValues={selectedRoom}
                                                            onKeyPressFn={function noRefCheck() { }}
                                                            onRemove={onRemoveAllRooms}
                                                            onSearch={function noRefCheck() { }}
                                                            onSelect={onSelectAllRooms}
                                                            closeOnSelect={true}
                                                            options={allRooms}

                                                        /> */}

                                                    </div>
                                                    <div className={minHour ? 'col-lg-3 col-md-6 col-sm-12 col-12' : 'col-lg-3 col-md-6 col-sm-12 col-12'}>
                                                        <h4 className='s_content_h'>Kitchen</h4>
                                                        {/* <Multiselect
                                                            className='select_multi'
                                                            displayValue="label"
                                                            hidePlaceholder={true}
                                                            selectedValues={selectedKitchen}
                                                            onKeyPressFn={function noRefCheck() { }}
                                                            onRemove={onRemoveKitchen}
                                                            onSearch={function noRefCheck() { }}
                                                            onSelect={onSelectKitchen}
                                                            closeOnSelect={true}
                                                            options={Kitchen}

                                                        /> */}


                                                        {Kitchen?.map((room, index) => {
                                                            return (
                                                                <Form.Check key={index}// prettier-ignore
                                                                    type="checkbox"
                                                                    label={room.label}
                                                                    value={room.value}
                                                                    className='pb-3'
                                                                    id={`Kitchen${index}`}
                                                                    onChange={(e) => handleKitchen(e)}
                                                                />
                                                            )
                                                        })}

                                                    </div>
                                                    <div className={minHour ? 'col-lg-3 col-md-6 col-sm-12 col-12' : 'col-lg-3 col-md-6 col-sm-12 col-12'}>
                                                        <h4 className='s_content_h'>Living, Bedrooms and Hallways</h4>
                                                        {/* <Multiselect
                                                            className='select_multi'
                                                            displayValue="label"
                                                            hidePlaceholder={true}
                                                            selectedValues={selectedLiving}
                                                            onKeyPressFn={function noRefCheck() { }}
                                                            onRemove={onRemoveLiving}
                                                            onSearch={function noRefCheck() { }}
                                                            onSelect={onSelectLiving}
                                                            closeOnSelect={true}
                                                            options={Living}

                                                        /> */}

                                                        {Living?.map((room, index) => {
                                                            return (
                                                                <Form.Check key={index}// prettier-ignore
                                                                    type="checkbox"
                                                                    label={room.label}
                                                                    value={room.value}
                                                                    className='pb-3'
                                                                    id={`Living${index}`}
                                                                    onChange={(e) => handleLiving(e)}
                                                                />
                                                            )
                                                        })}

                                                    </div>
                                                    <div className={minHour ? 'col-lg-3 col-md-6 col-sm-12 col-12' : 'col-lg-3 col-md-6 col-sm-12 col-12'}>
                                                        <h4 className='s_content_h'>Bathroom & Laundry</h4>
                                                        {/* <Multiselect
                                                            className='select_multi'
                                                            displayValue="label"
                                                            hidePlaceholder={true}
                                                            selectedValues={selectedLaundry}
                                                            onKeyPressFn={function noRefCheck() { }}
                                                            onRemove={onRemoveLaundry}
                                                            onSearch={function noRefCheck() { }}
                                                            onSelect={onSelectLaundry}
                                                            closeOnSelect={true}
                                                            options={Laundry}

                                                        /> */}

                                                        {Laundry?.map((room, index) => {
                                                            return (
                                                                <Form.Check key={index}// prettier-ignore
                                                                    type="checkbox"
                                                                    label={room.label}
                                                                    value={room.value}
                                                                    className='pb-3'
                                                                    id={`Laundry${index}`}
                                                                    onChange={(e) => handleLaundry(e)}
                                                                />
                                                            )
                                                        })}


                                                    </div>



                                                </> : null}




                                                <div className={extra == 1 ? 'd-block' : 'd-none'}>
                                                    <div>
                                                        <h3>Extras</h3>
                                                        <hr></hr>
                                                    </div>
                                                    <div className='extraItems'>
                                                        {extraServices}
                                                        {/* {extraSubServices} */}
                                                    </div>
                                                </div>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </motion.div>
                        </section>

                    </>
                );

            case 1:
                return (
                    <>
                        {/* <StapTwo /> */}

                        <motion.section
                            variants={textVariants('left', 0.2)}
                            initial="hidden"
                            whileInView="show"
                            animate={"show"}
                            viewport={{ once: true, amount: 0.1 }}
                        >
                            <motion.div
                                variants={textVariants('left', 0.2)}
                                initial="hidden"
                                whileInView="show"
                                animate={"show"}
                                viewport={{ once: true, amount: 0.1 }}
                                className='secondStap mt-5'>
                                <h3>Booking Details</h3>
                                <hr></hr>
                                <div className='leftDiv' >
                                    <div className='row m-0' style={{ rowGap: '20px' }}>
                                        {/* <div className='col-6'> */}
                                        {/* <h4 className='pb-0'>What date would you like your services?</h4> */}
                                        {/* <div className='d-flex justify-content-between'> */}
                                        <div className={minHour ? 'col-lg-4 col-md-6 col-sm-12 col-12' : 'col-lg-4 col-md-6 col-sm-12 col-12'} id="_date">
                                            <h4>Service Date</h4>
                                            <input type='date' min={tomorrowLim}
                                                value={startDate}
                                                onChange={(e) => handleDate(e.target.value)} />

                                        </div>


                                        <div className={minHour ? 'col-lg-4 col-md-6 col-sm-12 col-12' : 'col-lg-4 col-md-6 col-sm-12 col-12'}>
                                            <h4>Service Time?</h4>
                                            <input type='time' className='_timeset_' value={time}
                                                onChange={(e) => handleTime(e.target.value)}
                                            />
                                        </div>

                                        <div className={minHour ? 'd-none' : 'd-block col-lg-4 col-md-6 col-sm-12 col-12'}>
                                            <h4>How many hours?</h4>

                                            {/* <Counter  /> */}

                                            <div className='btn_div _counter_btn d-flex'>
                                                <Button onClick={decrement} className={hour == 0 ? "disabled" : ""} style={{ width: '20%' }}><FaMinus /></Button>
                                                <span style={{ width: '80%', padding: "10px" }}>{hour}</span>
                                                <Button style={{ width: '20%' }} onClick={increment} ><FaPlus /></Button>
                                            </div>

                                        </div>


                                        <div className={minHour ? 'col-lg-4 col-md-6 col-sm-12 col-12' : 'col-lg-4 col-md-6 col-sm-12 col-12'}>
                                            <h4>Service Days?</h4>
                                            {/* <Select
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    // defaultValue={options[]}
                                                    isMulti
                                                    options={options}
                                                    onChange={(e) => handleChange(e)}
                                                /> */}
                                            {/* multiSelect */}
                                            <Multiselect
                                                className='select_multi'
                                                displayValue="label"
                                                hidePlaceholder={true}
                                                selectedValues={selectedValues}
                                                onKeyPressFn={function noRefCheck() { }}
                                                onRemove={onRemove}
                                                onSearch={function noRefCheck() { }}
                                                onSelect={onSelect}
                                                closeOnSelect={true}
                                                options={options}

                                            />



                                            {/* <span style={{ fontSize: '12px' }}>* Weeked rates apply </span> */}
                                        </div>

                                        {/* <div className={ids != 15 ? 'd-none' : 'd-block col-6'}>
                                            <h4>How often would you like your regular clean?</h4>
                                            <div className='d-flex w-50 _select_radio'>
                                                <label class="con1"
                                                    onClick={() => regularCleans('Weekly')}
                                                >
                                                    <span>Weekly or Fortnightly</span>
                                                    <input type="radio" name="radio3" value={0}
                                                        checked={regularClean == "Weekly" ? true : false}
                                                    />
                                                    <span class="checkmark"><FaCheck className="checkedIcon" /></span>
                                                </label>
                                                <label class="con1"
                                                    onClick={() => regularCleans('Aged')}
                                                >
                                                    <span>Aged Care / Disability</span>
                                                    <input type="radio" name="radio3"
                                                        value={1}
                                                        checked={regularClean == "Aged" ? true : false}
                                                    />
                                                    <span class="checkmark"><FaCheck className="checkedIcon" /></span>
                                                </label>
                                                <label class="con1"
                                                    onClick={() => regularCleans('Spring')}
                                                >
                                                    <span>Move Out / Spring Clean</span>
                                                    <input type="radio" name="radio3"
                                                        value={2}
                                                        checked={regularClean == "Spring" ? true : false}
                                                    />
                                                    <span class="checkmark"><FaCheck className="checkedIcon" /></span>
                                                </label>
                                            </div>
                                        </div> */}

                                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                            <h4>Are you flexible with the date?</h4>
                                            <div className='d-flex _select_radio'>
                                                <label class="con2"
                                                    onClick={() => handleConfirm('Yes')}
                                                >
                                                    <span>Yes</span>
                                                    <input type="radio" name="radio1"

                                                        checked={confirmation == 'Yes' ? true : false}
                                                    />
                                                    <span class="checkmark1"><FaCheck className="checkedIcon" /></span>
                                                </label>
                                                <label class="con2"
                                                    onClick={() => handleConfirm('No')}
                                                >
                                                    <span>No</span>
                                                    <input type="radio" name="radio1"
                                                        checked={confirmation == 'No' ? true : false}
                                                    />
                                                    <span class="checkmark1"><FaCheck className="checkedIcon" /></span>
                                                </label>
                                            </div>

                                        </div>
                                        <div className='col-12'>
                                            <h4>Special Instructions</h4>
                                            <textarea placeholder='Your Message Here' rows='2'
                                                value={spacialInstructions}
                                                onChange={(e) => handleInstruction(e.target.value)}
                                            />

                                        </div>

                                    </div>
                                </div>
                            </motion.div>
                        </motion.section>

                    </>
                );
            case 2:
                return (
                    <>
                        {/* <StapThree /> */}

                        <section>
                            <motion.div
                                variants={textVariants('left', 0.2)}
                                initial="hidden"
                                whileInView="show"
                                animate={"show"}
                                viewport={{ once: true, amount: 0.1 }}
                                className='thirdStap mt-5'>
                                <h3>Personal Details</h3>
                                <hr></hr>
                                <div className='leftDiv' >
                                    <div className='row m-0'>
                                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12  mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter name"
                                                value={name}
                                                {...register("Name", {
                                                    required: localStorage.getItem("auth1") ? false : true, maxLength: 30,
                                                    onChange: (e) => setName(e.target.value),
                                                })}
                                            />

                                            {errors.Name && <p className='err_msg'>Please Enter Your Name</p>}
                                        </Form.Group>
                                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                            <Form.Label>Email Id</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email" disabled={localStorage.getItem("auth1") ? true : false}
                                                value={email}
                                                {...register("Email", {
                                                    required: localStorage.getItem("auth1") ? false : true, maxLength: 30,
                                                    onChange: (e) => setEmail(e.target.value)

                                                })}
                                            />
                                            {errors.Email && <p className='err_msg'>Please Enter Your Email</p>}
                                        </Form.Group>
                                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Phone Number"
                                                value={phone}
                                                {...register("Phone", {
                                                    required: localStorage.getItem("auth1") ? false : true, maxLength: 15, minLength: 10,
                                                    onChange: (e) => setPhone(e.target.value)

                                                })}
                                            />
                                            {errors.phone && errors.phone.type === "maxLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                                            {errors.phone && errors.phone.type === "minLength" && <span className='err_msg'>Please Enter Valid Phone Number</span>}
                                            {errors.phone && errors.phone.type === "required" && <span className='err_msg'>Please Enter Your Phone Number</span>}
                                        </Form.Group>
                                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12  mb-3">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address"
                                                value={address}
                                                {...register("Address", {
                                                    required: localStorage.getItem("auth1") && address != "" ? false : true, maxLength: 50,
                                                    onChange: (e) => setAddress(e.target.value)

                                                })}
                                            />
                                            {errors.Address && <p className='err_msg'>Please Enter Your Address</p>}
                                        </Form.Group>

                                        <Form.Group className='col-lg-6 col-md-6 col-sm-12 col-12 mb-3' >
                                            <Form.Label>Postcode</Form.Label>
                                            <Form.Control type="number" placeholder="Please Enter Your Postcode" value={postcode}
                                                {...register('postcode', {
                                                    required: localStorage.getItem("auth1") && postcode != "" ? false : true, min: 1000, max: 999999,
                                                    onChange: (e) => setPostcode(e.target.value),
                                                })}
                                            />
                                            {errors.postcode && errors.postcode.type === "required" && <span className='err_msg'>Please Enter Your Postcode</span>}
                                            {errors.postcode && errors.postcode.type === "min" && <span className='err_msg'>Please Enter Valid Postcode</span>}
                                            {errors.postcode && errors.postcode.type === "max" && <span className='err_msg'>Please Enter Valid Postcode</span>}
                                        </Form.Group>

                                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                            <Form.Label>Requirements</Form.Label>
                                            <Form.Control as="textarea" rows={1} placeholder="Enter Message"
                                                value={message}
                                                {...register("Message", {
                                                    required: false,
                                                    onChange: (e) => setMessage(e.target.value)

                                                })}
                                            />
                                            {errors.Message && <p className='err_msg'>Please Enter Message</p>}
                                        </Form.Group>
                                        <Form.Group className="col-12 mb-3">
                                            <Form.Label>Payment Method</Form.Label>
                                            <div className='d-flex w-75 _select_radio justify-content-between'>

                                                {/* <label class="con2">
                                                    <input type="radio" name="payment" value={0} disabled={name != "" && email != "" && phone != "" && postcode != "" && address != "" ? false : true}
                                                        //    checked={advanceType == 2 ? true : false}
                                                        {...register("payment", {
                                                            required: true,
                                                            onChange: (e) => setPaymentMethod(e.target.value),
                                                            // checked: advanceType == 2 ? true : false

                                                        })}
                                                    />
                                                    <span class="checkmark1" style={{ top: '25%' }}><FaCheck className="checkedIcon" /></span>
                                                    <Card>
                                                        <Card.Img variant="top" src={paypal} style={{ width: '150px' }} />
                                                    </Card>
                                                </label> */}

                                                {/* {advanceType == 2 ? null : */}
                                                < label class="con2" >
                                                    <input type="radio" name="payment" value={1} checked
                                                        {...register("payment", {
                                                            required: advanceType == 2 ? false : false,
                                                            onChange: (e) => setPaymentMethod(e.target.value)

                                                        })}
                                                    />
                                                    <span class="checkmark1" style={{ top: '25%' }}><FaCheck className="checkedIcon" /></span>
                                                    <Card>
                                                        <Card.Img variant="top" src={payLater} style={{ width: '150px' }} />
                                                    </Card>
                                                </label>

                                                {/* } */}

                                            </div>
                                            {errors.payment && <p className='err_msg'>Please select payment type</p>}

                                        </Form.Group>

                                        {/* {paymentMethod == 0 && advanceType == 2 ?
                                            <Form.Group className="col-12 mb-3">
                                                <div className='d-flex w-75 _select_radio' style={{ gap: '20px' }}>

                                                    <label class="con2 m-0 d-flex">
                                                        <input type="radio" name="payment-t"
                                                            onChange={(e) => setPartialPay(false)}
                                                        />
                                                        <span class="checkmark1" style={{ top: '25%' }}><FaCheck className="checkedIcon" /></span>
                                                        <span>Full Payment {`($${total})`}</span>
                                                    </label>

                                                    <label class="con2 m-0 d-flex">
                                                        <input type="radio" name="payment-t"
                                                            onChange={(e) => setPartialPay(true)}
                                                        />
                                                        <span class="checkmark1" style={{ top: '25%' }}><FaCheck className="checkedIcon" /></span>
                                                        <span>Advance Payment {`($${advanceAmount})`}</span>
                                                    </label>

                                                </div>
                                            </Form.Group> : null} */}


                                        {/* 
                                        {paymentMethod == 0 && advanceType == 2 ?
                                            <>
                                                <div className='w-50'>
                                                    <Paypal amount={advanceAmount} />

                                                </div>
                                                <div className='w-50'>
                                                    <Paypal amount={total} />
                                                </div>

                                            </>

                                            : paymentMethod == 0 && advanceType != 2 ?

                                                <Paypal amount={total} /> : null} */}



                                    </div>
                                    {/* </Form> */}
                                </div>

                            </motion.div>
                        </section >

                    </>
                );
            case 3:
                return (
                    <>
                        <Success />
                    </>
                )
            default:
                return (<>
                    {/* <SweetAlert
                        success
                        title="Booking Successfully"
                        timeout={2000}
                        onConfirm={setTimeout(() => navigate('/'), 3000)}
                        confirmBtnCssClass='d-none'

                    >

                    </SweetAlert> */}


                </>)

        }
    }


    return (
        <>
            <ToastContainer />

            <div className={myspinner ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>


            <div className='content_' ref={myRef}>

                <div className='row mt-4' style={{ rowGap: '20px' }}>
                    <div className={activeStep == 3 ? 'col-md-12 mt-5' : 'col-lg-8'} >
                        <Stepper activeStep={activeStep} className='myProgress m-auto'>
                            {steps.map((label, index) => (
                                <Step key={label} className="mySteps">
                                    <StepLabel className={`myLabel_${index}`}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <FormProvider {...methods}>
                            <motion.Form
                                variants={textVariants('left', 0.2)}
                                initial="hidden"
                                whileInView="show"
                                viewport={{ once: true, amount: 0.1 }}
                                onSubmit={handleSubmit(onSubmit)}>

                                {getStepContent(activeStep)}

                                <div className={activeStep == 3 ? 'd-none' : 'stepButton'}>
                                    <Button onClick={handleBack} disabled={activeStep == 0 ? true : false}
                                        className={activeStep == 0 ? 'd-none' : 'd-block prev'}
                                    >Previous</Button>


                                    {activeStep == 2 ? null : <Button onClick={handleNext} className={activeStep == 2 ? 'd-none' : 'd-block'}>Next Step</Button>}
                                    {/* paymentMethod == 1 &&  */}
                                    {activeStep == 2 ? <Button type='submit' className={activeStep == 2 ? 'd-block' : 'd-none'}>Book Now</Button> : null}

                                    {/* {activeStep == 2 && paymentMethod == 0 && advanceType != 2 ? <Paypal amount={total} paypalAuth={paypalAuth} setTransactionId={setTransactionId} onSubmit={onPay} /> : null}

                                    {activeStep == 2 && name != "" && email != "" && phone != "" && postcode != "" && address != "" && partialPay === true ? <Paypal amount={advanceAmount} paypalAuth={paypalAuth} setTransactionId={setTransactionId} onSubmit={onPay} /> : null}

                                    {activeStep == 2 && name != "" && email != "" && phone != "" && postcode != "" && address != "" && partialPay === false ? <Paypal amount={total} paypalAuth={paypalAuth} setTransactionId={setTransactionId} onSubmit={onPay} /> : null} */}


                                </div>

                            </motion.Form>
                        </FormProvider>

                    </div>
                    <div className={activeStep == 3 ? 'd-none' : 'col-lg-4'}>
                        <BookingSummery countedData={countedData} updatedData={updatedData} setTotal={setTotal} setSubTotal={setSubTotal} initialPrice={actualPrice} minHour={minHour}
                            addHourPrice={addHourPrice} gstPer={gstPer}
                        />
                    </div>
                </div>

            </div>
        </>
    )
}

export default memo(Steppers)