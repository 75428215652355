export const MenuList = [
    {
        title: "Home",
        url: "/"
    },
    {
        title: "Services",
        url: "/services",
    },
    {
        title: "About",
        url: "/about"
    },
    {
        title: "Gallery",
        url: "/gallery"
    },
    {
        title: "Contact Us",
        url: "/contactus"
    },

]

export const FooterNavLinks = [
    {
        title: "Home",
        url: "/"
    },
    {
        title: "Quote",
        url: "/contactus"
    },
    {
        title: "About",
        url: "/about"
    },
    {
        title: "Gallery",
        url: "/gallery"
    },
    {
        title: "Blog",
        url: "/blog"
    },
    {
        title: "Contact Us",
        url: "/contactus"
    },
   
]

