import React, { useState } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

const Counter = ({ id, handleCount, service, price, countedData }) => {

  let count = countedData?.filter(data => data.id == id).map(data => data.count).toString();
  let countValue = count ? parseInt(count) : 0

  const [counts, setCounts] = useState(countValue);


  const increment = () => {
    let newCount = counts + 1;
    let newPrice = parseInt(price)

    handleCount(id, service, newPrice, newCount)
    setCounts(newCount)
  }

  const decrement = () => {
    let newCount = counts > 0 ? counts - 1 : 0
    let newPrice = parseInt(price)

    handleCount(id, service, newPrice, newCount)
    setCounts(newCount)
  }


  return (
    <>


      <div className='btn_div _counter_btn d-flex'>
        <Button onClick={decrement} className={counts == 0 ? "disabled" : ""} ><FaMinus /></Button>
        <span
        // style={{height : '38px'}}
        >{service} {`(${counts})`}</span>
        <Button onClick={increment} ><FaPlus /></Button>
      </div>


      {/* <ButtonGroup aria-label="Basic example">
        <Button onClick={decrement} className={counts == 0 ? "disabled" : ""} ><FaMinus /></Button>
        <Button disabled>{counts}</Button>
        <Button onClick={increment} ><FaPlus /></Button>
      </ButtonGroup> */}
    </>
  )
}

export default Counter