import React from 'react';
import DataTable from "react-data-table-component";
import Spinner from 'react-bootstrap/Spinner';
import NoData from '../assets/Assets/NoDataFound.jpg';

const BookingData = ({ data, columns, title, pending }) => {
  return (
    <>

      <DataTable
        columns={columns}
        data={data}
        // noHeader
        defaultSortField="id"
        defaultSortAsc={false}
        pagination
        highlightOnHover
        // dense
        title={title}
        responsive
        progressPending={pending}
        progressComponent={<Spinner animation="border" className={'mySpinner'} />}
        noDataComponent= <img src={NoData} width={500} height={500} />

      />

    </>
  )
}

export default BookingData