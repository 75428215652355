import React, { useEffect, useState } from 'react';
import WhyBlueCleaning from '../common/WhyBlueCleaning';

import ResidencialProcess from './ResidencialProcess';

import axios from 'axios';
import CommanProcess from './CommanProcess';

import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import HTMLRenderer from 'react-html-renderer';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';
import Tabs from 'react-bootstrap/Tabs';
import Fixed from '../assets/Assets/worker.png';
import FixedW from '../assets/Assets/workerW.png';
import Fixed1 from '../assets/Assets/house1.png';
import FixedW1 from '../assets/Assets/houseW.png';
import Fixed2 from '../assets/Assets/building.png';
import FixedW2 from '../assets/Assets/buildingW.png';
import Hourly from '../assets/Assets/hourly.png';
import HourlyW from '../assets/Assets/hourlyW.png';
import Aos from 'aos';
import Rating from '@mui/material/Rating';
import { TfiAngleRight } from 'react-icons/tfi';
import { motion } from 'framer-motion';
import { fixedContainer, btnVariants, textVariants } from '../common/motion/motion';
import { BsCheckCircleFill } from 'react-icons/bs'
// included
import Laundry from '../assets/Assets/laundry.png';
import Kitchen from '../assets/Assets/kitchen.png';
import Rooms from '../assets/Assets/rooms.png';
import Hallways from '../assets/Assets/hallways.png';
import { call } from '../Header/Header';
import { Helmet } from 'react-helmet';
import OurProcess from '../common/OurProcess';
import { Skeleton } from '@mui/material';
const SubServices = () => {

    const { sid, name, id } = useParams();
    const [subId, setSubId] = useState('');
    const [subService, setSubService] = useState([]);
    const [subNewService, setNewSubService] = useState([]);
    const [activeTab, setActiveTab] = useState('residential-cleaning');
    // const [advanceAmount, setAdvanceAmount] = useState([]);
    // const [amountType, setAmountType] = useState('');
    // const [amounts, setAmounts] = useState('');
    const [continueReading, setContinueReading] = useState(false);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    // const [regularClean, setRegularClean] = useState('');
    const [canonical, setCanonical] = useState('');
    const [myspinner, setMyspinner] = useState(false);


    const getAllData = () => {
        axios.get(`/singlesubser/${sid}/${id}`
        ).then((response) => {
            setSubId(response.data.data[0].id)
            console.log(response.data.data);
        });



        // axios.get(`/getpaymentservice/${id}`
        // ).then((response) => {
        //     setAdvanceAmount(response.data.data);
        //     console.log(response.data.data)
        // });
    }

    useEffect(() => {
        if (subId != '') {
            axios.get(`/getSubService/${subId}`
            ).then((response) => {
                console.log(response.data.data);
                setSubService(response.data.data);
                setNewSubService(response.data.data);
                setMyspinner(false);
            });
        }
    }, [subId])


    const handleSEO = () => {
        subService?.map((seo) => {
            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
        })
    }

    useEffect(() => {
        handleSEO();
        if (subId) {
            let activeTabValue = subService[0]?.combination_ser.filter(data => data.id == subId).map(data => data.service_name).toString().replace(/\s+/g, "-").toLowerCase()
            console.log(activeTabValue);
            setActiveTab(activeTabValue);
        }

    }, [sid, subNewService]);


    const handleTabChange = (tab) => {
        setActiveTab(tab)
        let subID = subService[0]?.combination_ser.filter(data => data.service_name.toString().replace(/\s+/g, "-").toLowerCase() == tab).map(data => data.id).toString()
        axios.get(`/getSubService/${subID}`
        ).then((response) => {
            console.log(response.data.data);
            setSubService(response.data.data);
            setMyspinner(false);

        });
        // navigate(`/services/${tab}/${sid}/${id}`);
        // if (tab == "booking") {
        //     setActiveTab("booking")
        //     navigate("/services/2/Residential-Cleaning/10/Carpet-Cleaning");
        // } else if (tab == "enquiry") {
        //     setActiveTab("enquiry")
        //     navigate("/services/3/Commercial-Cleaning/22/Carpet-Cleaning");
        // }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        getAllData();
        setMyspinner(true);
        Aos.init({
            duration: 1000,
        })
    }, [sid, id]);

    // useEffect(() => {
    //     advanceAmount?.map((data) => {
    //         let at = data.advance
    //         let a = data.advance_amount
    //         setAmountType(at);
    //         setAmounts(a);
    //     })
    // }, [advanceAmount])

    // advanceType={amountType} advanceAmount={amounts}


    let serviceName = '';
    let serviceType = subService?.map((service) => {
        serviceName = service.name;
        let id_ = service.combination_ser.filter(data => data.service_name.toString().replace(/\s+/g, "-").toLowerCase() == activeTab).map(data => data.id).toString()
        if (service.type == 0) {
            return <ResidencialProcess ids={id_} extra={service.extras} initialPrice={service.initial_price} minPrice={service.min_price} type={service.type} regularClean={service.regular_service} propertyType={service.prop_typeask} gst={service.gst} />
        }
        if (service.type == 1) {
            return <CommanProcess service={subService} />
        }
        if (service.type == 2) {
            return <ResidencialProcess ids={id_} extra={service.extras} initialHourPrice={service.min_hour_price} minHour={service.min_hour} addHourPrice={service.adtnl_hour_price} minPrice={service.min_price} type={service.type} regularClean={service.regular_service} propertyType={service.prop_typeask} gst={service.gst} specialService={subId == 14 ? true : false} />
        }
        if (service.type == 3) {
            return <>
                <Tabs
                    defaultActiveKey="fixed"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                >
                    <Tab eventKey="fixed" title={<><img src={Fixed} className="f_" width="50px" /><img src={FixedW} className="f_w" width="50px" />Fixed Package</>} >
                        <ResidencialProcess ids={id_} extra={service.extras} initialPrice={service.initial_price} minPrice={service.min_price} type={service.type} regularClean={service.regular_service} propertyType={service.prop_typeask} gst={service.gst} />
                    </Tab>
                    <Tab eventKey="hourly" title={<><img src={Hourly} width="50px" className="h_" /><img src={HourlyW} className="h_w" width="50px" />Hourly Package</>}>
                        <ResidencialProcess ids={id_} extra={service.extras} initialHourPrice={service.min_hour_price} minHour={service.min_hour} minPrice={service.min_price} addHourPrice={service.adtnl_hour_price} type={service.type} regularClean={service.regular_service} propertyType={service.prop_typeask} gst={service.gst} specialService={true} />
                    </Tab>

                </Tabs>
            </>
        }

    })

    return (
        <>

            <Helmet>
                <link rel="canonical" href={canonical} />
                <title>{seoTitle}</title>
                <meta name="description" content={seoDes} />
                <meta name="keywords" content={seoKeyword} />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content="hweindia" />
                <meta property="og:url" content={canonical} />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDes} />
                <meta name="robots" content={robot} />
                <script>{seoScript}</script>

            </Helmet>

            <div className='fix_width'>
                {myspinner ?
                    <>
                        <div className='row p-5'>
                            <div className='col-6'>
                                <Skeleton />
                                <Skeleton width="60%" />
                                <Skeleton width="40%" />
                            </div>
                            <div className='col-6'>
                                <Skeleton
                                    variant="rectangular"
                                    height="60vh"
                                    animation="wave"
                                />
                            </div>
                        </div>

                        <div className='row p-5'>
                            <Skeleton width="40%" />
                            <Skeleton width="60%" />
                            <Skeleton />
                        </div>
                    </>
                    :
                    subService?.map((service) => {

                        return (
                            <>
                                <section>
                                    <div style={{ position: 'relative' }}>
                                        {subId == 23 ?
                                            <video autoPlay loop muted id="video_">
                                                <source src="https://bcg.giwdevelopment.com/assets/video/DISINFECTING_CLEANING.mp4" type="video/mp4">
                                                </source>
                                            </video>

                                            : null}

                                        {subId == 15 ?
                                            <div className='banner'>
                                                <img src={`/assets/subservice/${service.banner_image}`} className="full_bg" />
                                                <div className="container-fluid" style={{ position: "absolute", top: '0' }}>
                                                    <div className='row' style={{ padding: '3rem 0rem' }}>
                                                        <div className='content_div col-lg-6 col-md-12 col-sm-12 col-12'>
                                                            <motion.Button
                                                                variants={textVariants('up', 0.1)}
                                                                initial="hidden"
                                                                whileInView="show"
                                                                viewport={{ once: true, amount: 0.1 }}
                                                                className='strar-btn mb-3' >
                                                                <a href="https://goo.gl/maps/zLHxYWCKEEj8oM8s7" target="_blank">
                                                                    <div className='starDiv'>
                                                                        <span>4.8</span>
                                                                        <Rating
                                                                            name="hover-feedback"
                                                                            defaultValue={4.5}
                                                                            precision={0.5}
                                                                            readOnly

                                                                        />
                                                                    </div>
                                                                    from 200 Client's reviews</a></motion.Button>
                                                            <motion.h3
                                                                variants={textVariants('up', 0.2)}
                                                                initial="hidden"
                                                                whileInView="show"
                                                                viewport={{ once: true, amount: 0.1 }}
                                                            >{service.banner_text}</motion.h3>

                                                            <motion.Button
                                                                variants={textVariants('up', 0.3)}
                                                                initial="hidden"
                                                                whileInView="show"
                                                                viewport={{ once: true, amount: 0.1 }}
                                                                className='call-btn'><a href={`tel:${call}`}>CALL US NOW</a></motion.Button>

                                                        </div>
                                                        {/* <div className='serviceImgDiv col-lg-6 col-md-12 col-sm-12 col-12'>
                                                    <motion.img
                                                        variants={textVariants('down', 0.2)}
                                                        initial="hidden"
                                                        whileInView="show"
                                                        viewport={{ once: true, amount: 0.1 }}
                                                        className='w-100' src={`/assets/subservice/${service.banner_image}`} />
                                                </div> */}
                                                    </div>

                                                </div>

                                            </div>
                                            :

                                            <div className='banner container-fluid'>
                                                <div className='row' style={{ padding: '3rem 0rem' }}>
                                                    <div className='content_div col-lg-6 col-md-12 col-sm-12 col-12'>
                                                        <motion.Button
                                                            variants={textVariants('up', 0.1)}
                                                            initial="hidden"
                                                            whileInView="show"
                                                            viewport={{ once: true, amount: 0.1 }}
                                                            className='strar-btn mb-3' >
                                                            <a href="https://goo.gl/maps/zLHxYWCKEEj8oM8s7" target="_blank">
                                                                <div className='starDiv'>
                                                                    <span>4.8</span>
                                                                    <Rating
                                                                        name="hover-feedback"
                                                                        defaultValue={4.5}
                                                                        precision={0.5}
                                                                        readOnly

                                                                    />
                                                                </div>
                                                                from 200 Client's reviews</a></motion.Button>
                                                        <motion.h3
                                                            variants={textVariants('up', 0.2)}
                                                            initial="hidden"
                                                            whileInView="show"
                                                            viewport={{ once: true, amount: 0.1 }}
                                                        >{service.banner_text}</motion.h3>

                                                        <motion.Button
                                                            variants={textVariants('up', 0.3)}
                                                            initial="hidden"
                                                            whileInView="show"
                                                            viewport={{ once: true, amount: 0.1 }}
                                                            className='call-btn'><a href={`tel:${call}`}>CALL US NOW</a></motion.Button>

                                                    </div>
                                                    <div className='serviceImgDiv col-lg-6 col-md-12 col-sm-12 col-12'>
                                                        <motion.img
                                                            variants={textVariants('down', 0.2)}
                                                            initial="hidden"
                                                            whileInView="show"
                                                            viewport={{ once: true, amount: 0.1 }}
                                                            className='w-100' src={`/assets/subservice/${service.banner_image}`} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {/* tab section */}
                                    {service.combination_ser.length > 1 ? <div className='service_tabs_s mt-5'>
                                        <Tabs
                                            activeKey={activeTab}
                                            id="fill-tab-example"
                                            name={'m'}
                                            className="service_tabs mb-3"
                                            fill
                                            onSelect={handleTabChange}
                                        >
                                            {service.combination_ser.map((data) => {
                                                return (
                                                    <Tab eventKey={data.service_name.replace(/\s+/g, "-").toLowerCase()} title={data.service_id == 1 ? (<><img src={Fixed} className="f_" width="50px" /><img src={FixedW} className="f_w" width="50px" />{data.service_name}</>) : data.service_id == 2 ? (<><img src={Fixed1} className="f_" width="50px" /><img src={FixedW1} className="f_w" width="50px" />{data.service_name}</>) : data.service_id == 3 ? (<><img src={Fixed2} className="f_" width="50px" /><img src={FixedW2} className="f_w" width="50px" />{data.service_name}</>) :null }>
                                                        <>
                                                            <div style={{ backgroundColor: '#dff1ff' }} className="continue_reading-d" >
                                                                <section className='company-s'>
                                                                    <div className='our_company_div pt-0'>
                                                                        <div className='container-fluid our-company'>

                                                                            <div className='d-flex _heading m-auto'>
                                                                                <motion.div
                                                                                    variants={textVariants('up', 0.1)}
                                                                                    initial="hidden"
                                                                                    whileInView="show"
                                                                                    viewport={{ once: true, amount: 0.1 }}
                                                                                    style={{ lineHeight: '2rem' }}
                                                                                    className='editor_d' >
                                                                                    {subId == 1 ? null :
                                                                                        <h3 className='title'>{service.name}</h3>}
                                                                                    <HTMLRenderer html={service.content} />
                                                                                </motion.div>

                                                                            </div>
                                                                            <div className={continueReading ? 'd-none' : 'd-none continue_reading d-block pt-4'} >
                                                                                <div class="blog_button">
                                                                                    <motion.Button
                                                                                        variants={textVariants('up', 0.2)}
                                                                                        initial="hidden"
                                                                                        whileInView="show"
                                                                                        viewport={{ once: true, amount: 0.1 }}
                                                                                        className='appoinment-btn' onClick={() => setContinueReading(true)}>CONTINUE READING</motion.Button>
                                                                                </div>
                                                                            </div>
                                                                            {subId == 1 ?
                                                                                <div className='end_tenency row m-0 pt-5' style={{ lineHeight: '2rem', rowGap: '20px' }}>
                                                                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                                                                                        <div>
                                                                                            <img src={Rooms} className="" />
                                                                                            <h3>All Rooms</h3>
                                                                                            <ul className='list-unstyled'>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Dust and clean all accessible surfaces
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Vacuum and mop all accessible floors
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Empty visible garbage bins
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Mirrors cleaned and polished
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean door handles, light switches and power points
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean and polish all glass-top surfaces
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Cobwebs removed
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Dust and wipe skirting boards
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Dust accessible air vents
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                                                                                        <div>
                                                                                            <img src={Kitchen} className="" />
                                                                                            <h3>Kitchen</h3>
                                                                                            <ul className='list-unstyled'>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean all benches and splashbacks
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean stove, hotplates, rangehood and chute
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean oven exterior
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean oven interior
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean and polish sink and taps
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean exterior fridge, dishwasher/appliances
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean exterior of all cupboards, pantry and drawers
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean interior of all cupboards, pantry and drawers
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                                                                                        <div>
                                                                                            <img src={Hallways} className="" />
                                                                                            <h3>Living, Bedrooms and Hallways</h3>
                                                                                            <ul className='list-unstyled'>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean exterior of all cupboards and drawers
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean interior of all cupboards and drawers
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean and polish all mirrors incl. wardrobe doors
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                                                                                        <div>
                                                                                            <img src={Laundry} className="" />
                                                                                            <h3>Bathroom & Laundry</h3>
                                                                                            <ul className='list-unstyled'>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean and sanitise shower screens and tiles
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean and sanitise toilet
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean and sanitise bath and sink
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean and polish sink and taps
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Plugholes clean and free of debris
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean exterior of all cupboards and drawers
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean interior of all cupboards and drawers
                                                                                                </li>
                                                                                                <li style={{ paddingLeft: '24px' }}>
                                                                                                    <BsCheckCircleFill /> Clean clothes dryer filter
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> : null}
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                            {subId == 1 || subId == 15 ? null :
                                                                <WhyBlueCleaning />
                                                            }

                                                            <div className={myspinner ? 'spinner_d' : 'd-none'}>
                                                                <Spinner animation="border" className='mySpinner' />
                                                            </div>

                                                            <div className='fix_width' style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>
                                                                <section className='our_profationals'>
                                                                    <center className="mb-3">
                                                                        <motion.h5
                                                                            variants={textVariants('up', 0.1)}
                                                                            initial="hidden"
                                                                            whileInView="show"
                                                                            viewport={{ once: true, amount: 0.1 }}
                                                                            style={{ justifyContent: 'center' }}>Book Our Professionals</motion.h5>
                                                                        <motion.h3
                                                                            variants={textVariants('up', 0.2)}
                                                                            initial="hidden"
                                                                            whileInView="show"
                                                                            viewport={{ once: true, amount: 0.1 }}
                                                                            className='title'>{serviceName}</motion.h3>
                                                                    </center>

                                                                    {serviceType}
                                                                </section>

                                                                {subId == 1 ? <OurProcess /> : null}
                                                                {subId == 15 ?
                                                                    <WhyBlueCleaning /> : null
                                                                }

                                                            </div>
                                                        </>
                                                    </Tab>
                                                )
                                            })}
                                        </Tabs>
                                    </div> : <>
                                        <div style={{ backgroundColor: '#dff1ff' }} className="continue_reading-d" >
                                            <section className='company-s'>
                                                <div className='our_company_div pt-0'>
                                                    <div className='container-fluid our-company'>

                                                        <div className='d-flex _heading m-auto'>
                                                            <motion.div
                                                                variants={textVariants('up', 0.1)}
                                                                initial="hidden"
                                                                whileInView="show"
                                                                viewport={{ once: true, amount: 0.1 }}
                                                                style={{ lineHeight: '2rem' }}
                                                                className='editor_d' >
                                                                {subId == 1 ? null :
                                                                    <h3 className='title'>{service.name}</h3>}
                                                                <HTMLRenderer html={service.content} />
                                                            </motion.div>

                                                        </div>
                                                        <div className={continueReading ? 'd-none' : 'd-none continue_reading d-block pt-4'} >
                                                            <div class="blog_button">
                                                                <motion.Button
                                                                    variants={textVariants('up', 0.2)}
                                                                    initial="hidden"
                                                                    whileInView="show"
                                                                    viewport={{ once: true, amount: 0.1 }}
                                                                    className='appoinment-btn' onClick={() => setContinueReading(true)}>CONTINUE READING</motion.Button>
                                                            </div>
                                                        </div>
                                                        {subId == 1 ?
                                                            <div className='end_tenency row m-0 pt-5' style={{ lineHeight: '2rem', rowGap: '20px' }}>
                                                                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                                                                    <div>
                                                                        <img src={Rooms} className="" />
                                                                        <h3>All Rooms</h3>
                                                                        <ul className='list-unstyled'>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Dust and clean all accessible surfaces
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Vacuum and mop all accessible floors
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Empty visible garbage bins
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Mirrors cleaned and polished
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean door handles, light switches and power points
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean and polish all glass-top surfaces
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Cobwebs removed
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Dust and wipe skirting boards
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Dust accessible air vents
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                                                                    <div>
                                                                        <img src={Kitchen} className="" />
                                                                        <h3>Kitchen</h3>
                                                                        <ul className='list-unstyled'>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean all benches and splashbacks
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean stove, hotplates, rangehood and chute
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean oven exterior
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean oven interior
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean and polish sink and taps
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean exterior fridge, dishwasher/appliances
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean exterior of all cupboards, pantry and drawers
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean interior of all cupboards, pantry and drawers
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                                                                    <div>
                                                                        <img src={Hallways} className="" />
                                                                        <h3>Living, Bedrooms and Hallways</h3>
                                                                        <ul className='list-unstyled'>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean exterior of all cupboards and drawers
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean interior of all cupboards and drawers
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean and polish all mirrors incl. wardrobe doors
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-3 col-md-6 col-sm-12 col-12'>
                                                                    <div>
                                                                        <img src={Laundry} className="" />
                                                                        <h3>Bathroom & Laundry</h3>
                                                                        <ul className='list-unstyled'>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean and sanitise shower screens and tiles
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean and sanitise toilet
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean and sanitise bath and sink
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean and polish sink and taps
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Plugholes clean and free of debris
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean exterior of all cupboards and drawers
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean interior of all cupboards and drawers
                                                                            </li>
                                                                            <li style={{ paddingLeft: '24px' }}>
                                                                                <BsCheckCircleFill /> Clean clothes dryer filter
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div> : null}
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                        {subId == 1 || subId == 15 ? null :
                                            <WhyBlueCleaning />
                                        }

                                        <div className={myspinner ? 'spinner_d' : 'd-none'}>
                                            <Spinner animation="border" className='mySpinner' />
                                        </div>

                                        <div className='fix_width' style={myspinner ? { opacity: '0.5' } : { opacity: '1' }}>
                                            <section className='our_profationals'>
                                                <center className="mb-3">
                                                    <motion.h5
                                                        variants={textVariants('up', 0.1)}
                                                        initial="hidden"
                                                        whileInView="show"
                                                        viewport={{ once: true, amount: 0.1 }}
                                                        style={{ justifyContent: 'center' }}>Book Our Professionals</motion.h5>
                                                    <motion.h3
                                                        variants={textVariants('up', 0.2)}
                                                        initial="hidden"
                                                        whileInView="show"
                                                        viewport={{ once: true, amount: 0.1 }}
                                                        className='title'>{serviceName}</motion.h3>
                                                </center>

                                                {serviceType}
                                            </section>

                                            {subId == 1 ? <OurProcess /> : null}
                                            {subId == 15 ?
                                                <WhyBlueCleaning /> : null
                                            }

                                        </div>
                                    </>}

                                </section >
                            </>
                        )
                    })
                }
            </div >
        </>
    )
}

export default SubServices