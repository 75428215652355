import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { HiRefresh } from 'react-icons/hi';
import { FaEye, FaShare } from 'react-icons/fa';
import axios from 'axios';
import { Button, Dropdown } from 'react-bootstrap';
import DataTables from '../DataTable/DataTables';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from "react-toastify";

const UpcomingBookings = () => {

    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [pending, setPending] = useState(true);

    const getData = () => {
        axios.get('/getBookingAddress').then((response) => {
            setData(response.data.data);
            setNewData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        setData(newData?.filter(data => data.booking_status == 1));

    }, [newData]);

    const tableData = {
        columns: [
            {
                name: '#',
                width: '80px',
                cell: (row, index) => index + 1
            },

            {
                name: "Booking Id",
                selector: "booking_id",
                // width: '200px',
                sortable: true
            },
             {
                name: "Service Date",
                selector: "start_date",
                // width: '200px',
                sortable: true
            },

            {
                name: "Address",
                selector: "address",

                sortable: true
            },

            {
                name: "Booking Status",
                width: '250px',
                selector: row => <span style={{color: '#146FB7', fontWeight: 'bold'}}>{row.booking_status == 1 ? "Confirm" : null}</span>,
                center: true,

            },
            {
                name: "Action",
                cell: row => <Link to={`/admin/bookings/bookingsummary/${row.book_id}`}><Button className='_view_icons' ><FaEye /></Button></Link>,
                right: true
            },

        ],

    };



    return (
        <>
            <Helmet>

                <title>Blue Cleaning Group | Upcoming Bookings</title>

            </Helmet>

            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>

            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Upcoming Booking</span>
                            <section className="card m-3">

                                {/* <div className="selectoption mt-4">
                                    <strong >Status :-</strong>
                                    <select className="status filter"

                                        onChange={(e) => filterByStatus(e.target.value)}
                                    >
                                        <option value="">All</option>
                                        <option value="0" selected>Pending</option>
                                        <option value="1">Confirmed</option>
                                        <option value="2">Completed</option>
                                        <option value="3" >Cancelled</option>
                                    </select>
                                </div> */}
                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} title="Upcoming Bookings" />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>


        </>
    )
}

export default UpcomingBookings