import React,{useState} from 'react';
import { Button } from 'react-bootstrap';
import {TfiAngleDoubleUp} from 'react-icons/tfi'



const ScrollToTop = () => {

    const [showScrollBtn, setShowScrollBtn] = useState(false);

    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300){
        setShowScrollBtn(true)
      } 
      else if (scrolled <= 300){
        setShowScrollBtn(false)
      }
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <Button style={{borderRadius: '0px', bottom: "40px"}} className={showScrollBtn ? 'scrollTop show_btn' : 'scrollTop'}
        onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}
        
      >
        <TfiAngleDoubleUp />
        
      </Button>
    )
}

export default ScrollToTop