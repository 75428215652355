import React, { memo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaAngleDown } from 'react-icons/fa';


const MenuItems = ({ submenu, serviceId, handleClick, serviceName }) => {

    const navigate = useNavigate();

    const handleNavigate = (id, name) => {
        navigate(`/services/${name.replace(/\s+/g, '-').toLowerCase()}/${serviceId}/${id}`);
        handleClick();
    }
    // /${serviceName.replace(/\s+/g, '-')}
    return (
        <>
            {submenu?.map((service) => {
                return (

                    <li key={service.id} onClick={() => handleNavigate(service.subservice_comb_id, service.name)} style={{ cursor: 'pointer' }}>
                        <NavLink className='product_links' to={`/services/${service.name.replace(/\s+/g, '-').toLowerCase()}/${serviceId}/${service.subservice_comb_id}`}

                        >{service.name}</NavLink>
                    </li>


                )
            })}
        </>
    )
}

export default MenuItems

