import React from 'react'
import { FaStar } from 'react-icons/fa';
const ClientReview = ({star}) => {
 let stars = []  

    for(var i=1; i<=star; i++){
        stars.push(
            <FaStar/>
        )
    }
  
  return (
   <>{stars.map((icon) => {
    return(icon);
   })}</>
  )
}

export default ClientReview
