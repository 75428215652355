import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { HiRefresh } from 'react-icons/hi';
import { FaEye, FaShare } from 'react-icons/fa';
import axios from 'axios';
import { Button, Dropdown } from 'react-bootstrap';
import DataTables from '../DataTable/DataTables';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";


const AdminBookingSummary = () => {

    const { id } = useParams();
    const [data, setData] = useState([]);
    const [bookingData, setBookingData] = useState([]);
    const [show, setShow] = useState(false);
    const [pending, setPending] = useState(true);
    // payment
    // const [pay_id, setPay_id] = useState('');
    const [pay_status, setPay_status] = useState('');
    // booking
    const [showBooking, setShowBooking] = useState(false);
    // const [bookingId, setBookingId] = useState('');
    const [bookingStatus, setBookingStatus] = useState('');
    // cancel
    const [showCancel, setShowCancel] = useState(false);
    const [extraAmount, setExtraAmount] = useState('');

    // const [cancelId, setCancelId] = useState('');

    const getData = () => {
        // axios.get(`/getBooking/97`).then((response) => {
        //     setData(response.data.data);
        //     setPending(false);
        //     console.log(response.data.data)
        // })
        axios.get(`/singleBooking/${id}`).then((response) => {
            setBookingData(response.data.data);
            setPending(false);

        })
    }

    useEffect(() => {
        getData();
    }, []);

    const handleClose = () => {
        // setPay_id('');
        setPay_status('');
        setShow(false);

    };
    const handleShow = (e) => {
        // setPay_id(e.target.id);
        setPay_status(e.target.value);
        setShow(true);

    }


    const getRefund = () => {
        axios.get(`https://api-m.sandbox.paypal.com/v2/payments/refunds/1AE97472WH687784N`, {
            rel: 'self',
            method: 'GET'
        }).then((response) => {
            console.log(response);
        }).catch((err) => {
            console.log(err);
        })
    }

    const statusSuccess = () =>
        toast.info("Payment Status Update Successfully", { position: "top-center" });

    const handlePayment = () => {
        // let id = pay_id;
        let status = pay_status;

        axios.post('/paymentStatus', {
            id: id,
            payment_status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                handleClose();
                statusSuccess();
                window.location.reload(false);
            }
        }).catch((err) => {
            console.log(err);
        })
    }


    const handleCloseCancel = () => {
        setShowCancel(false)
    }

    const handleShowBooking = (e) => {

        // let id = e.target.id;
        let status = e.target.value;
        if (status == 3) {
            setShowCancel(true);
            // setCancelId(id);

        } else {
            setShowBooking(true);
            // setBookingId(id);
            setBookingStatus(status);
        }

    }

    const handleBookingClose = () => {
        setShowBooking(false);
        // setBookingId('');
        setBookingStatus('');
    }

    const statusSuccessBooking = () =>
        toast.info("Booking Status Update Successfully", { position: "top-center" });

    // bookingStatus
    const handleStatus = () => {
        // let id = id;
        let status = bookingStatus;
        axios.post('/bookingStatus', {
            id: id,
            booking_status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                statusSuccessBooking();
                handleBookingClose();
                window.location.reload(false);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const cancelSuccess = () =>
        toast.info("Booking Canceled Successfully", { position: "top-center" });


    const addExtraSuccess = () =>
        toast.info("Extra Amount Added Successfully", { position: "top-center" });
    // booking cancel 
    const handleCancel = () => {
        axios.post('/bookingCancel', {
            id: id,
            cancel_by: localStorage.getItem("adminType") == 0 ? 1 : 2
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                handleCloseCancel();
                cancelSuccess();
                setTimeout(function () {
                    window.location.reload(1);
                }, 1000);
            }

        }).catch(err => {
            console.log(err)
        })
    }


    const addExtraAmount = () => {
        axios.post('/extraAmount', {
            id: id,
            extra_amount: extraAmount
            // cancel_by: localStorage.getItem("adminType") == 0 ? 1 : 2
        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {

                addExtraSuccess();
                setTimeout(function () {
                    window.location.reload(1);
                }, 1000);
            }

        }).catch(err => {
            console.log(err)
        })
    }



    // bookingStatus
    // const handleStatus = () => {


    // }


    return (
        <>
            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <ToastContainer />
                <section className="wrapper booking_d">
                    <div className="">
                        <span className='p-3'>Booking Details</span>
                        <div className="booking_info">
                            {bookingData?.map((Booking) => {
                                return (
                                    <>
                                        <section className="card innercard m-3">
                                            <div className='card-body'>
                                                <h5>Personal Details</h5>
                                                <Table borderless >

                                                    <tbody>
                                                        {/* user_id */}
                                                        <tr>
                                                            <td className='w-50'>
                                                                <div className=''>
                                                                    <strong>User Id</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.user_id}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='w-50'>
                                                                <div className=''>
                                                                    <strong>Name</strong>
                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.name}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Email</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.email}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Phone</strong>
                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.phone}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Address</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.address}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Postcode</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.postcode}
                                                            </td>
                                                        </tr>
                                                        {/* postcode */}
                                                    </tbody>

                                                </Table>
                                            </div>
                                        </section>
                                        <section className="card innercard m-3">
                                            <div className='card-body'>
                                                <h5>Booking Details</h5>
                                                <Table borderless >
                                                    {/* {bookingData?.map((Booking) => {
                                                        return ( */}
                                                    <tbody>
                                                        <tr>
                                                            <td className='w-50'>
                                                                <div className=''>
                                                                    <strong>Booking Id</strong>
                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.booking_id}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Booking Status</strong>
                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                <select className=" col-md-4 select_status" disabled={Booking.booking_status == 2 || Booking.booking_status == 3 || Booking.cancel_status == 2 || localStorage.getItem("editAuth") == 1 ? true : false}
                                                                    value={Booking.booking_status}
                                                                    id={Booking.book_id}
                                                                    onChange={(e) => handleShowBooking(e)}
                                                                >
                                                                    <option value="0" disabled={Booking.booking_status >= 0 ? true : false}>Pending</option>
                                                                    <option value="1" disabled={Booking.booking_status >= 1 ? true : false}>Confirm</option>
                                                                    <option value="2" disabled={Booking.booking_status == 2 ? true : false}>Completed</option>
                                                                    <option value="3" disabled={Booking.booking_status == 3 ? true : false}>Cancelled</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    {/* )
                                                    })} */}
                                                </Table>
                                            </div>
                                        </section>
                                        <section className="card innercard m-3">
                                            <div className='card-body'>
                                                <h5>Service Details</h5>
                                                <Table borderless >
                                                    {/* {bookingData?.map((Booking) => {
                                                        return ( */}
                                                    <tbody>
                                                        <tr>
                                                            <td className='w-50'>
                                                                <div className=''>
                                                                    <strong>Service Name</strong>
                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.sub_service_id}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Service Type</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.sub_service_type}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Services</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.sub_service_lists}
                                                            </td>
                                                        </tr>
                                                        {Booking.all_rooms_opt ?
                                                            <tr>
                                                                <td>
                                                                    <div className=''>
                                                                        <strong>Rooms Options</strong>

                                                                    </div>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    {Booking.all_rooms_opt}
                                                                </td>
                                                            </tr> : null}

                                                        {Booking.kitchen_opt ?
                                                            <tr>
                                                                <td>
                                                                    <div className=''>
                                                                        <strong>Kitchen Options</strong>

                                                                    </div>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    {Booking.kitchen_opt}
                                                                </td>
                                                            </tr> : null}

                                                        {Booking.living_opt ?
                                                            <tr>
                                                                <td>
                                                                    <div className=''>
                                                                        <strong>Living Options</strong>

                                                                    </div>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    {Booking.living_opt}
                                                                </td>
                                                            </tr> : null}

                                                        {Booking.bathroom_opt ?
                                                            <tr>
                                                                <td>
                                                                    <div className=''>
                                                                        <strong>Laundry Options</strong>

                                                                    </div>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    {Booking.bathroom_opt}
                                                                </td>
                                                            </tr> : null}

                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Start Date</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.start_date}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Time</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.time}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Hour</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.hour}
                                                            </td>
                                                        </tr>
                                                        {Booking.clean_schedule == 0 ? null : <>
                                                            <tr>
                                                                <td>
                                                                    <div className=''>
                                                                        <strong>Regular Cleaning</strong>

                                                                    </div>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    {Booking.clean_schedule == 1 ? "Weekly or Fortnightly" : Booking.clean_schedule == 2 ? "Aged Care / Disability" : Booking.clean_schedule == 3 ? "Move Out / Spring Clea" : "No"}
                                                                </td>
                                                            </tr>
                                                        </>}
                                                        {Booking.prop_type ?
                                                            <tr>
                                                                <td>
                                                                    <div className=''>
                                                                        <strong>Property Type</strong>
                                                                    </div>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    {Booking.prop_type == 1 ? "Home" : Booking.prop_type == 2 ? "apartment" : "-"}
                                                                </td>
                                                            </tr> : null}
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Service Days</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.service_days}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Flexible With Date</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.date_confirm}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Special Instruction</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.special_instruction}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Requirement</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.requirement}
                                                            </td>
                                                        </tr>

                                                        {Booking.sub_service_type == "Hourly" ?
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        <div className=''>
                                                                            <strong>Bedroom Information</strong>

                                                                        </div>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        {Booking.total_bedroom}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className=''>
                                                                            <strong>Bathroom Information</strong>

                                                                        </div>
                                                                    </td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        {Booking.total_bathroom}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            : null}


                                                    </tbody>
                                                    {/* )
                                                    })} */}
                                                </Table>
                                            </div>
                                        </section>
                                        <section className="card innercard m-3">
                                            <div className='card-body'>
                                                <h5>Payment Details</h5>
                                                <Table borderless >

                                                    <tbody>

                                                        <tr>
                                                            <td className='w-50'>
                                                                <div className=''>
                                                                    <strong>Sub Total Price</strong>
                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                ${Booking.total_price - Booking.gst_amount}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='w-50'>
                                                                <div className=''>
                                                                    <strong>GST {Booking.gst}%</strong>
                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                ${Booking.gst_amount}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='w-50'>
                                                                <div className=''>
                                                                    <strong>Total Price(Initial)</strong>
                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                ${Booking.total_price}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='w-50'>
                                                                <div className=''>
                                                                    <strong>Extra Amount</strong>
                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                ${Booking.extra_amount == "" ? 0 : Booking.extra_amount}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='w-50'>
                                                                <div className=''>
                                                                    <strong>Final Price</strong>
                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                ${Booking.final_price - Booking.paid_amount}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Payment Type</strong>

                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {Booking.payment_type}
                                                            </td>
                                                        </tr>
                                                        {Booking.payment_type == "Paypal" ?
                                                            <tr>
                                                                <td>
                                                                    <div className=''>
                                                                        <strong>Transaction Id</strong>

                                                                    </div>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    {Booking.transaction_id}
                                                                </td>
                                                            </tr> : null}
                                                        <tr>
                                                            <td>
                                                                <div className=''>
                                                                    <strong>Payment Status</strong>
                                                                </div>
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                <select className=" col-md-4 select_status" disabled={Booking.payment_status == 1 || localStorage.getItem("editAuth") == 1 ? true : false}
                                                                    value={Booking.payment_status}
                                                                    id={Booking.id}
                                                                    onChange={(e) => handleShow(e)}
                                                                >
                                                                    <option value="0" disabled>Pending</option>
                                                                    <option value="1" >Completed</option>

                                                                </select>
                                                            </td>
                                                        </tr>

                                                        {Booking.showExtra === "hide" || Booking.booking_status == 2 || Booking.booking_status == 3 || Booking.cancel_status == 2 ? null :
                                                            <tr>
                                                                <td> < div className='d-flex justify-content-between align-items-center'>
                                                                    <label className=''><strong>Extra Amount($)</strong></label>
                                                                    <input type='number' style={{ width: '80px' }} value={extraAmount} className='' onChange={(e) => setExtraAmount(e.target.value)} />

                                                                </div></td>
                                                                <td>:</td>
                                                                <td> <Button className='' onClick={addExtraAmount}>Add</Button></td>
                                                            </tr>
                                                        }




                                                    </tbody>

                                                </Table>


                                            </div>
                                        </section >
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </section>
            </section >

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className='adminpass'>
                    <Modal.Title>Payment Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                        <h3>Are you sure Do You want to Completed payment?</h3>
                    </div>

                    <div className="_model_btn mt-2">
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            className="_close_btn"
                        >
                            No
                        </Button>
                        <Button className='Add_btn_'
                            onClick={() => handlePayment()}
                        >Yes</Button>
                    </div>
                </Modal.Body>

            </Modal>

            <Modal show={showBooking} onHide={handleBookingClose}>
                <Modal.Header closeButton className='adminpass'>
                    <Modal.Title>Booking Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                        <h3>Are you sure to {bookingStatus == 2 ? "Complete" : "confirm"} this Booking?</h3>
                    </div>

                    <div className="_model_btn mt-2">
                        <Button
                            variant="secondary"
                            onClick={handleBookingClose}
                            className="_close_btn"
                        >
                            No
                        </Button>
                        <Button variant='danger' onClick={() => handleStatus()}>Yes</Button>
                    </div>
                </Modal.Body>

            </Modal>


            <Modal show={showCancel} onHide={handleCloseCancel}>
                <Modal.Header closeButton className='adminpass'>
                    <Modal.Title>Booking Cancel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-75' style={{ margin: 'auto', height: '100px' }}>
                        <h3>Are you sure Do You want to Cancel this Booking?</h3>
                    </div>

                    <div className="_model_btn mt-2">
                        <Button
                            variant="secondary"
                            onClick={handleCloseCancel}
                            className="_close_btn"
                        >
                            No
                        </Button>
                        <Button variant='danger' onClick={() => handleCancel()}>Yes</Button>
                    </div>
                </Modal.Body>

            </Modal>



        </>
    )
}

export default AdminBookingSummary;