import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { HiRefresh } from 'react-icons/hi';
import { FaShare } from 'react-icons/fa';
import axios from 'axios';
import { Button, Dropdown } from 'react-bootstrap';
import DataTables from '../DataTable/DataTables';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";


const Enquiries = () => {

    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [pending, setPending] = useState(true);
    const [showReply, setShowReply] = useState(false);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subServiceId, setSuServiceId] = useState([]);
    const [reply, setReply] = useState('');


    const getData = () => {
        axios.get('/getEnquiry').then((response) => {
            setData(response.data.data);
            setNewData(response.data.data)
            setPending(false);
            console.log(response.data.data)
        })
    }

    useEffect(() => {
        getData();
    }, [])


    useEffect(() => {
        setData(newData?.filter(data => data.status == 0));

    }, [newData]);


    const handleReply = () => {
        setShowReply(false);

    }


    const statusSuccess = () =>
        toast.info("Status Update Successfully", { position: "top-center" });


    const notify = () => toast.info("Enquiry Reply SuccessFully", { position: "top-center" });

    const handleStatus = (e) => {
        let id = e.target.id;
        let status = e.target.value;
        axios.post('/enquiryStatus', {
            id: id,
            status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                statusSuccess();
                window.location.reload(false);

            }
        }).catch((err) => {

            console.log(err);
        })
    }

    const handleReplyShow = (id) => {
        setShowReply(true);
        data?.filter((data) => data.id === id).map((data) => {
            setName(data.name);
            setId(data.id);
            setEmail(data.email);
            setSuServiceId(data.sub_service_id);
            console.log(data)
        })

    }


    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.status == status));
        }

    }



    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = () => {

        axios.post('/enquiryReply', {
            id: id,
            name: name,
            email: email,
            sub_service_id: subServiceId,
            replies: reply

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notify();
                handleReply();
                reset({
                    reply: ""
                })
            }
        })


    }

    const tableData = {
        columns: [
            {
                name: '#',
                // width: '50px',
                cell: (row, index) => index + 1
            },
            {
                name: "Name",
                selector: "name",
                // width: '200px',
                sortable: true
            },
            {
                name: "Email",
                selector: "email",
                // width: '200px',
                sortable: true
            },
            {
                name: "Address",
                selector: "address",
                // width: '200px',
                sortable: true,
                // cell: d => <span>{d.genres.join(", ")}</span>
            },
            {
                name: "Phone",
                selector: "phone",
                // width: '200px',
                sortable: true
            },
            {
                name: "Sub Service Id",
                selector: "sub_service_id",
                // width: '100px',
                sortable: true
            },
            {
                name: "Message",
                selector: "message",
                // width: '100px',
                sortable: true
            },
            {
                name: "Status",
                width: '250px',
                selector: row => <select className=" col-md-4 select_status" disabled={localStorage.getItem("editAuth") == 0 ? false : true}
                    value={row.status}
                    id={row.id}
                    onChange={(e) => handleStatus(e)}
                >
                    <option value="0">Pending</option>
                    <option value="1">Completed</option>

                </select>,
                center: true,



            },
            {
                name: "Action",
                selector: row =>
                    <>{localStorage.getItem("editAuth") == 0 ?
                        <Button className='_view_icons' onClick={() => handleReplyShow(row.id)} ><FaShare /></Button>
                        : null} </>
                ,
                right: true

            }



        ],

    };


    return (
        <>

            <Helmet>

                <title>Blue Cleaning Group | Enquiries</title>

            </Helmet>


            <div className={pending ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div>
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Enquiry</span>
                            <section className="card m-3">

                                <div className="selectoption mt-4">
                                    <strong >Status :-</strong>
                                    <select className="status filter"

                                        onChange={(e) => filterByStatus(e.target.value)}
                                    >
                                        <option value="">All</option>
                                        <option value="0" selected>Pending</option>
                                        <option value="1">Completed</option>
                                    </select>
                                </div>
                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} title="Enquiry" />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>

                    </div>
                </section>
            </section>


            <Modal show={showReply} onHide={handleReply}>
                <Modal.Header closeButton>
                    <Modal.Title>Reply Enquiry</Modal.Title>

                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name"
                                disabled
                                value={name}
                                {...register("name", {
                                    required: false,
                                    // onChange: (e) => setName(e.target.value)
                                })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email"
                                disabled
                                value={email}
                                {...register("email", {
                                    required: false,
                                    // onChange: (e) => setEmail(e.target.value)
                                })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Sub Service Ids</Form.Label>
                            <Form.Control type="text" placeholder="Enter Product Name"
                                disabled
                                value={subServiceId}
                                {...register("service", {
                                    required: false,
                                    // onChange: (e) => setSuServiceId(e.target.value)
                                })}

                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Reply</Form.Label>
                            <Form.Control rows={5} as="textarea" placeholder="Type Your Message"
                                value={reply}
                                {...register("reply", {
                                    required: true,
                                    onChange: (e) => setReply(e.target.value)
                                })}
                            />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleReply}>
                            Close
                        </Button>
                        <Button className='Add_btn_' type='submit'>
                            Reply
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


        </>
    )
}

export default Enquiries